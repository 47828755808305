import ExpandLessIcon from "@mui/icons-material/ExpandMore";
import PeopleIcon from "@mui/icons-material/PeopleAltOutlined";
import RssFeedIcon from "@mui/icons-material/RssFeed";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  Box,
  Button,
  Divider,
  Drawer,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Checkbox from "@mui/material/Checkbox";
import Collapse from "@mui/material/Collapse";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Text from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  bowserJournalSearchCard,
  getDetailsOnSelectiongVolume,
  getVolumeOrIssueDate,
} from "../../api/browseJournalSearch";
import CardItem from "../../components/card";
import Pagination from "../../components/pagination";
import { onSearch as search } from "../../screens/Search/onSearch";
import {
  getCardData,
  updateBrowserPage,
  updateBrowserRow,
  updateJournalLockDisplay,
  updateLoadingState,
  updateTocPageSearch,
} from "../../store/slice/browseJournal";
import { Colors, dataTypes } from "../../utils/constants";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import CloseIcon from "@mui/icons-material/Close";
import StarIcon from "@mui/icons-material/Star";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import Alert from "@mui/material/Alert";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Snackbar from "@mui/material/Snackbar";
import axios from "axios";
import { MathJax } from "better-react-mathjax";
import { checkMultipleArticleExistence } from "../../api/MyLibrary/MyFolders.api";
import { basicSearchAuthorEmail } from "../../api/Search/BasicSearch.api";
import {
  browserAutoSuggestion,
  browserJournalDetailSearch,
  browserJournalGetVolume,
  getListOfJournal,
  journalLock,
  markFav,
} from "../../api/browseJournal";
import api from "../../api/endpoints";
import {
  LinkResolverType,
  getBulkLinkRecords,
} from "../../api/linkResolverApi";
import { usagelogApi } from "../../api/usageReportApi";
import RetractedIcon from "../../assets/img/Retracted.svg";
import FindInLibraryIcon from "../../assets/img/findinlib.svg";
import IdIcon from "../../assets/img/id.png";
import { AccessLockJournal } from "../../components/browserJournalblocks/browserJournalblocks";
import NoResultModal from "../../components/modals/NoResultFound";
import RSSAlert from "../../components/resultsPopups/RSSAlert";
import SJRModal from "../../components/rightDrawer/SJRModal";
import JournalViewDetails from "../../components/rightDrawer/journalViewDetails";
import RightDrawer from "../../components/rightDrawer/rightDrawer";
import Spinner from "../../components/spinner";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  updatedStoredSelectedIssue,
  updateVolumeOfTOC,
} from "../../store/slice/browserFilterSliceDetails";
import { clearAllFilters } from "../../store/slice/filterSlice";
import {
  updateMarkedArticleCount,
  updateMarkedArticleId,
  updateMarkedResult,
  updateSavedArticles,
} from "../../store/slice/searchSlice";
import { SaverArticlesType } from "../../types/interface";
import { notify } from "../../utils/Notify";
import {
  getParamsByName,
  goToDown,
  goToTop,
  isStringOfArray,
  showIsProfileToast,
  sortAuthor,
  sortAuthorDetail,
  toTitleCase,
} from "../../utils/helper";
import highlightKeyword, {
  keywordHighlight,
} from "../../utils/highlightKeyword";
import metaData from "../../utils/metadata";
import AccessLock from "../basicSearchScreen/AccessLock";
import objectToQueryString from "./objToQuery";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import SearchIcon from "@mui/icons-material/Search";
import { Clear, FilterAlt } from "@mui/icons-material";
import BrowseIssues from "./browseIssue";
interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const month = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

interface P {
  items: string[];
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      fontFamily: "Helvetica Neue",
    },
  },
};

const BrowseJournalSearch: React.FC = () => {
  const navigate = useNavigate();
  // const markedArticlesid = useSelector(
  //   (state: any) => state.browseJournalSearch.markedArticleId
  // );
  const markedArticlesid = useAppSelector(
    (state) => state.searchReducer.markedArticleId
  );
  const loginDetails: any = useSelector((state: any) => state.login);
  const alertUpdate: any = useSelector(
    (state: any) => state.browseJournalSearch.browserResult
  );

  const selectedFilters = useAppSelector((state) => state.browseFilterSlice);
  const [noresultModal, setNoResultModal] = useState(false);
  const dataType = useAppSelector((state) => state?.searchReducer.dataType);
  const location = useLocation();
  const [details, setDetails] = useState(location?.state?.item);
  const [lock, setLock] = useState(location?.state?.lock);
  const basicSearch = location?.state;
  const searchParams = new URLSearchParams(location.search);
  const resourceId = searchParams.get("resourceId");
  const alerts = location?.state?.alerts;

  const [journalHistory, setJournalHistory] = React.useState([]);
  const [markedData, setMarkdeData] =
    React.useState<string[]>(markedArticlesid);
  const [favorites, setFavorites] = React.useState(alerts?.favorite_set);
  const [notification, setNotification_set] = React.useState(
    alerts?.notification_set
  );
  const resourceMasterIdParam = getParamsByName("resourcemaster_id");

  const [expanded, setExpanded] = React.useState(false);
  const getDataList = useAppSelector(
    (state) => state.browseJournalSearch?.getCardDataDetails?.docs
  );
  const tocGetDataList = useAppSelector(
    (state) => state.browseJournalSearch?.getCardDataDetails?.highlighting
  );

  const profileStatus = sessionStorage.getItem("isProfileUser");

  const getDataCount = useAppSelector(
    (state) => state.browseJournalSearch?.getCardDataDetails
  );

  const isMobile = useMediaQuery("(max-width: 900px)");

  // details?.year_from[0]
  let storedVol;
  const selectedVolume = localStorage.getItem("selectedVolume");
  if (selectedVolume) {
    storedVol = JSON.parse(selectedVolume) ?? "";
  }
  const [cite, setCite] = useState(false);
  const [autoSearch, setAutoSearch] = useState<any>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [valueOne, setValueOne] = React.useState<any>(storedVol);
  const [valueText, setvalueText] = React.useState("");
  const [volume, setVolume] = React.useState<any>();
  const [keywords, setKeywords] = React.useState<string[]>([]);
  const [selectedListTags, setselectedListTags] = React.useState<String[]>([]);
  const [query, setQuery] = React.useState<any>("");
  const [page, setPage] = React.useState(1);
  const [filters, setFilters] = React.useState<any>("");
  const [YeardropDownList, setYearDropDownList] = React.useState<any[]>([]);
  const [volumeDropDownList, setVolumeDropDownList] = React.useState<any[]>(
    basicSearch?.item?.issuenumberfrom
      ? [
          {
            issuenumberfrom: basicSearch?.item?.issuenumberfrom,
            issuenumberto: basicSearch?.item?.issuenumberto,
            monthfrom: basicSearch?.item?.monthfrom,
            volumefrom: basicSearch?.item?.volumefrom,
            datefrom: basicSearch?.item?.datefrom,
            yearfrom: basicSearch?.item?.yearfrom,
            resourceissue_id: basicSearch?.item?.resourceissue_id,
          },
        ]
      : []
  );

  const searchReducer: any = useSelector(
    (state: any) => state.browseJournalSearch.getCardDataDetails
  );

  const searchItem: any = useSelector(
    (state: any) => state.browseJournalSearch.tocSearchCard
  );

  const rows: any = useSelector(
    (state: any) => state.browseJournalSearch?.rows
  );
  const currentPage: any = useSelector(
    (state: any) => state.browseJournalSearch?.page
  );
  let count = Math.ceil(searchReducer?.hits / rows);
  const isLoading = useAppSelector(
    (state) => state.browseJournalSearch.loading
  );
  const [message, setMessage] = useState({ messages: "", open: false });

  const [markResult, setMarkResult] = React.useState(false);
  const [selectedTab, setSelectedTab] = React.useState("all");
  const [dialogueData, setDialogueData] = React.useState({});
  const [top, setTop] = useState(false);
  const [viewDetails, setViewDetails] = useState(false);
  const [gridDisplay, setGridDisplay] = useState<boolean>(false);
  const [gridId, setGridId] = useState("");
  const [gridData, setGridData] = useState([]);
  const [authorEmailData, setAuthorEmailData] = useState([]);
  const [value, setValue] = React.useState<any>(-1);
  const userSubjects = useSelector((state: any) => state.customer);
  const dispatch = useAppDispatch();
  const currentYear: any = new Date().getFullYear().toString();

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  const [FilterDrawer, setFilterDrawer] = useState(false);

  const profileData1 = useAppSelector(
    (state) => state.customer.customerDetails
  );

  const is_ONOSCustomer = useSelector(
    (state: any) => state.customer.customerDetails?.is_enable_onos
  );

  const profileData = useAppSelector((state) => state.login);

  const contextId =
    selectedFilters.collectionsValueJournal === "J-GateCollection"
      ? 1
      : selectedFilters.collectionsValueJournal === "Consortia Collections"
      ? 2
      : selectedFilters.collectionsValueJournal === "My Library Collections"
      ? 3
      : selectedFilters.collectionsValueJournal ===
        "MyPersonalLibraryCollection"
      ? 4
      : selectedFilters.collectionsValueJournal === "ONOS Collections"
      ? 5
      : selectedFilters.collectionsValueJournal === "Library OPAC"
      ? 6
      : selectedFilters.collectionsValueJournal === "Open Access Collections"
      ? 7
      : null;

  const logTOCUsageData = (usageActionID: any, details?: any) => {
    const userId = sessionStorage.getItem("user_id");
    const informaticscustomer_Id = sessionStorage.getItem(
      "informaticscustomer_id"
    );
    const articleArticle_Id = details?.article_id ? details?.article_id : null;
    const articleResourcemaster_id = details?.resourcemaster_id
      ? details?.resourcemaster_id
      : null;
    const articleResourceissue_id = details?.resourceissue_id
      ? details?.resourceissue_id
      : null;
    const publisherId = details?.publisher_id ? details?.publisher_id[0] : null;
    const user_ipv4_address =
      sessionStorage.getItem("user_ipv4_address") || null;

    const userMasterId = profileData.user_id
      ? profileData.user_id
      : userId
      ? userId
      : null;
    const informaticscustomerId = profileData.informaticscustomer_id
      ? profileData.informaticscustomer_id
      : informaticscustomer_Id
      ? informaticscustomer_Id
      : null;
    const consortiamasterId =
      profileData1 && profileData1.consortiamaster_id
        ? profileData1.consortiamaster_id
        : null;
    const sessionKey = profileData.session_key ? profileData.session_key : null;
    const isArticleONOS = details?.is_onos_resource;
    const isONOS =
      is_ONOSCustomer &&
      (selectedFilters.collectionsValueJournal === "ONOS Collections" ||
        isArticleONOS)
        ? 1
        : 0;

    usagelogApi(
      userMasterId,
      informaticscustomerId,
      consortiamasterId,
      usageActionID,
      contextId,
      null,
      articleArticle_Id,
      articleResourceissue_id,
      publisherId,
      articleResourcemaster_id,
      // profileData.ip_v4,
      user_ipv4_address,
      null,
      sessionKey,
      1,
      null,
      isONOS
    );
  };

  const markedResult = useAppSelector(
    (state) => state.searchReducer.markedResult
  );

  let searchResults: any = useAppSelector(
    (state: any) => state.browseJournalSearch.getCardDataDetails
  );
  const previousLocation = useRef(location);

  const volumeDetailsTOC = useAppSelector(
    (state) => state.browserDetailsSlice?.storeSelectedVolume
  );

  const isSelecetdIssue = useAppSelector(
    (state) => state.browserDetailsSlice?.isSelectedIssue
  );
  let session_key = sessionStorage.getItem("session_key");
  let userId = sessionStorage.getItem("user_id");
  const informaticscustomer_id = sessionStorage.getItem(
    "informaticscustomer_id"
  );
  const selector = useAppSelector((state) => state.login);

  const [linkToDisplay, setLinkToDisplay] = useState<LinkResolverType[] | null>(
    null
  );
  const customerId = useAppSelector(
    (state) => state.customer.customerDetails?.informaticscustomer_id
  );

  const consortia_virtualid = useAppSelector(
    (state) => state.customer.customerDetails?.consortia_virtualid
  );

  const consortiamaster_id = useAppSelector(
    (state) => state.customer.customerDetails?.consortiamaster_id
  );

  const isEnableONOS = useAppSelector(
    (state) => state.customer.customerDetails?.is_enable_onos
  );

  const ref = useRef<HTMLDivElement>();

  // Get Access lock details if user directly opens the browse journal screen
  async function getAccessLockDetails() {
    let id = resourceId;
    if (!id) return;
    let params = {
      customer_id:
        customerId || sessionStorage.getItem("informaticscustomer_id"),
      resource_id: [id],
      access_type: 1,
    };

    let journalLockRes = await journalLock("?" + objectToQueryString(params));
    setLock(journalLockRes?.data?.data[0]);

    dispatch(updateJournalLockDisplay(journalLockRes));
  }

  async function getResourceData() {
    let id = resourceId;
    if (!id) return;
    let params = {
      profileuserid: userId,
      sessionid: session_key,
      facet_fields:
        "publisher_name,primary_publisher_country,subjects_name_l3,resource_source_index,filter_metrix,resource_name_initial",

      fq__active_flag: true,
      fq__resourcemaster_id: id,
    };

    const response = await getListOfJournal(
      "resourcecore?" + objectToQueryString(params)
    );
    setDetails(response?.data.data?.docs[0]);
  }

  useEffect(() => {
    const debounce = setTimeout(() => {
      resourceId && getJournalDetails();
    }, 100);

    return () => {
      clearTimeout(debounce);
    };
  }, [resourceId]);

  const getJournalDetails = async () => {
    let apiJournalData: any = {
      fq__resourcemaster_id: resourceId,
      fl: "resource_name_tk,publisher_name_tk,publisher_name,peer_reviewed,is_openaccess,openaccess_type,sjr_score,sjr_hindex,naas_value,pissn,eissn,aboutresource,content_start,content_end,author_url",
    };

    const response = await getListOfJournal(
      "resourcecore?" + objectToQueryString(apiJournalData)
    );

    setDetails(response?.data.data?.docs[0]);
  };

  const handleChange = async (event: SelectChangeEvent) => {
    setFilters("");
    setValue(event.target.value);
    setValueOne("");
    dispatch(updateVolumeOfTOC(""));
    dispatch(updatedStoredSelectedIssue(false));

    // getValueFunction(event.target.value);
    // setJournalResults();
    let response = await browserJournalGetVolume(
      query + `&fq__yearfrom=${event.target.value}&page=1`
    );

    if (currentYear === event.target.value) {
      logTOCUsageData(90, details);
    } else {
      logTOCUsageData(87, details);
    }

    if (response) {
      const sortedVolumes = sortVolumesFunction(response?.data?.data?.docs);
      setVolumeDropDownList(sortedVolumes);
    }
  };
  // useEffect(() => {
  //   const getDropdown = async () => {
  //     let response = await browserJournalGetVolume(
  //       query + `&fq__yearfrom=${value}&page=1`
  //     );

  //     if (currentYear === value) {
  //       logTOCUsageData(90, details);
  //     } else {
  //       logTOCUsageData(87, details);
  //     }

  //     if (response) {
  //       setVolumeDropDownList(response?.data.data.docs);
  //       // dispatch(updateVolumeOfTOC(response?.data?.data?.docs[0]));
  //     }
  //   };
  //   if(value !== -1) getDropdown()
  //   // navigate(`/BrowseJournalTableSearch/BrowseJournalSearch?year=${value}`);
  // }, [value]);
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setMessage({
      messages: "",
      open: false,
    });
  };

  const handleCite = async () => {
    setCite(!cite);
  };
  function handlePageChange(p: any) {
    // setFilters("");

    dispatch(updateBrowserPage(p));

    if (volumeDetailsTOC?.resourceissue_id) {
      setFilters(`&fq__resourceissue_id=${volumeDetailsTOC?.resourceissue_id}`);
    }
    // setPage(p);
    // isMounted.current = true;
    goToTop();
  }

  function getRowsPerPage(value: number) {
    // isMounted.current = true;
    dispatch(updateBrowserRow(value));
    // setRow(value);
  }

  const handleDawer = async (data: any) => {
    setTop(!top);
    const authorData = await basicSearchAuthorEmail(data?.article_id);
    setAuthorEmailData(authorData);
    setDialogueData(data);
    logTOCUsageData(30, data);
  };
  const handleViewDrawer = (data: any) => {
    setViewDetails(!viewDetails);
  };

  const searchTerms = useAppSelector(
    (state) => state.browseJournalSearch.loading
  );

  const getVolumeArticle = async (index: any) => {
    let response = await getDetailsOnSelectiongVolume(
      volume[index].resourceissue_id
    );
    dispatch(getCardData(response?.data.data.docs));
  };

  function areObjectsEqual(obj1: any, obj2: any): boolean {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      if (obj1[key] !== obj2[key]) {
        return false;
      }
    }

    return true;
  }

  const handleChangeOne = async (event: any) => {
    setFilterDrawer(false);
    dispatch(updateBrowserPage(1));
    setValueOne(event.target.value);
    localStorage.setItem("selectedVolume", JSON.stringify(event.target.value));
    dispatch(updateVolumeOfTOC(event.target.value));
    dispatch(updatedStoredSelectedIssue(true));
    // @ts-ignore
    // setFilters(`&fq__resourceissue_id=${event.target.value?.resourceissue_id}`);

    let newQuery = "";
    dispatch(updateLoadingState(true));
    let id;
    if (resourceMasterIdParam) {
      id = resourceMasterIdParam;
    } else if (resourceId) {
      id = resourceId;
    } else {
      id = details.resourcemaster_id;
    }
    let subjects = "";
    let dataTypes = "";
    if (userSubjects?.customerDetails?.subject?.length > 0) {
      userSubjects?.customerDetails?.subject?.map(
        (element: any, index: any) => {
          subjects += `${element.datsubjectmasterlevel2_id} OR `;
        }
      );
      subjects = `(${subjects.slice(0, -3)})`;
    }

    if (userSubjects?.customerDetails?.datatypes?.length > 0) {
      userSubjects?.customerDetails?.datatypes?.map(
        (element: any, index: any) => {
          dataTypes += `${element.dat_resourcetype_id} OR `;
        }
      );
      dataTypes = `(${dataTypes.slice(0, -3)})`;
    }
    let titleKeywords = "";

    if (searchItem.length !== 0) {
      searchItem?.map(
        (element: any) =>
          (titleKeywords += `"${element?.replace(
            /^\"+|\“+|\"+|\”$/g,
            ""
          )}" AND `)
      );
    }

    titleKeywords = `(${titleKeywords.slice(0, -5)})`;
    setselectedListTags([...searchItem]);
    let apiJournalData = {
      profileuserid: userId,
      sessionid: session_key,
      customerid: informaticscustomer_id,
      // consortiamaster_id: userSubjects?.customerDetails?.consortiamaster_id,
      datsearchtype_id: 1,
      datproductcontrolitem_id: 4,
      datsearch_action_type_id: 1,
      fq__resourcemaster_id: id,
      // sort: "dateofpublication desc",
      sort: "dateofpublication desc, volumefrom desc, issuenumberfrom desc",
      // @ts-ignore
      // fq__resourceissue_id: event.target.value?.resourceissue_id,
    };

    if (userSubjects?.customerDetails?.consortiamaster_id) {
      Object.assign(apiJournalData, {
        consortiamaster_id: userSubjects?.customerDetails?.consortiamaster_id,
      });
    }

    if (dataTypes.length > 2)
      Object.assign(apiJournalData, { fq__resource_type: dataTypes });
    Object.assign(apiJournalData, { datproductcontrolitem_id: 55 });
    Object.assign(apiJournalData, { datsearchtype_id: 4 });
    Object.assign(apiJournalData, { datsearch_action_type_id: 4 });
    if (searchItem.length != 0) {
      Object.assign(apiJournalData, { titleKeywordsAbs: titleKeywords });
    }
    // Removed for DF-1006
    //
    // if (basicSearch?.from === "basicSearch") {
    //   Object.assign(apiJournalData, {
    //     fq__resourceissue_id: basicSearch?.item?.resourceissue_id,
    //   });
    // }
    if (selectedTab == "full-text")
      Object.assign(apiJournalData, { fulltext: true });
    // Object.assign(apiJournalData, { page: currentPage });
    Object.assign(apiJournalData, { page: 1 });
    // Here row 500 is added to get the volume and issue number
    Object.assign(apiJournalData, { rows: 500 });

    setQuery("?" + objectToQueryString(apiJournalData));
    newQuery = "?" + objectToQueryString({ ...apiJournalData, page: 1 });
    // Here row is assigned to rows to get journal details search
    Object.assign(apiJournalData, { rows: rows });
    Object.assign(apiJournalData, { fq__resource_type: "(1 OR 2 )" });
    Object.assign(apiJournalData, {
      json_facet: JSON.stringify({
        yearfrom: {
          type: "terms",
          field: "yearfrom",
          limit: 100,
          sort: "index desc",
        },
        monthfrom: { type: "terms", field: "monthfrom", limit: 100 },
        volumefrom: { type: "terms", field: "volumefrom", limit: 100 },
        issuenumberfrom: {
          type: "terms",
          field: "issuenumberfrom",
          limit: 100,
        },
        resource_status: {
          type: "terms",
          field: "resource_status",
          limit: 100,
        },
        primary_publisher_country: {
          type: "terms",
          field: "primary_publisher_country",
          limit: 100,
        },
        publisher_name: { type: "terms", field: "publisher_name", limit: 100 },
        journal_name: { type: "terms", field: "journal_name", limit: 100 },
        filter_metrix: { type: "terms", field: "filter_metrix", limit: 100 },
      }),
    });

    // if the user only has the consortia access the consortia_filter query should append in all query
    const isOnlyConsortiaAccess = profileData1?.product_type === "7";
    if (isOnlyConsortiaAccess) {
      Object.assign(apiJournalData, {
        fq__acl_group: profileData1.consortia_filter,
      });
    }

    if (selectedFilters.collectionsValueJournal === "My Library Collections") {
      Object.assign(apiJournalData, {
        fq__acl_group: profileData1?.my_library_filter,
      });
    }

    if (selectedFilters.collectionsValueJournal === "Consortia Collections") {
      Object.assign(apiJournalData, {
        fq__acl_group: profileData1?.consortia_filter,
      });
    }

    let response = await browserJournalDetailSearch(
      (basicSearch?.from === "basicSearch" ||
        basicSearch?.from === "abstractFullScreen") &&
        !filters?.includes("fq__resourceissue_id") &&
        (value == -1 || value == undefined) &&
        !getDataList?.length
        ? "?" +
            objectToQueryString(apiJournalData) +
            filters +
            `&fq__resourceissue_id=${basicSearch?.item?.resourceissue_id}`
        : (basicSearch?.from === "basicSearch" ||
            basicSearch?.from === "abstractFullScreen") &&
          getDataList?.length &&
          volumeDetailsTOC !== ""
        ? "?" +
          objectToQueryString(apiJournalData) +
          filters +
          // @ts-ignore
          `&fq__resourceissue_id=${event.target.value?.resourceissue_id}`
        : "?" +
          objectToQueryString(apiJournalData) +
          filters +
          "&" +
          `fq__resourceissue_id=${event.target.value?.resourceissue_id}`,
      dispatch
    );
    const articleData = new FormData();

    let articleId: any = [];
    if (response?.data?.data?.docs?.length > 0) {
      response?.data.data.docs?.map((element: any) => {
        articleId = articleId.concat(element?.article_id);
      });
    }
    if (response?.data?.data?.docs?.length == 0) {
      setNoResultModal(true);
    }

    articleData.append("profile_id", selector.user_id);
    articleData.append("article_ids", articleId.toString());

    const checkArticleExistance = await checkMultipleArticleExistence(
      articleData
    );

    // dispatch(updateLoadingState(false));

    // fetch links details
    await fetchLinkDetails(response?.data?.data?.docs);

    let years: any[] = [];
    // dispatch(updateLoadingState(true));

    let newResponse: any = {};
    if (
      basicSearch?.from === "basicSearch" ||
      basicSearch?.from === "abstractFullScreen" ||
      filters?.includes("fq__resourceissue_id") ||
      !objectToQueryString(apiJournalData)?.includes("fq__resourceissue_id")
    ) {
      newResponse = await axios.get(
        api.baseApi +
          `/api/indexing/solr/articlecore2${
            "?" + objectToQueryString(apiJournalData) + filters
          }&fq__resourceissue_id=${event.target.value?.resourceissue_id}`
      );
    }

    newResponse?.data
      ? newResponse?.data?.data?.jsonfacets?.yearfrom?.buckets?.map(
          (element: any) => (years = [...years, element.val.toString()])
        )
      : response?.data?.data?.jsonfacets?.yearfrom?.buckets?.map(
          (element: any) => (years = [...years, element.val.toString()])
        );
    // year dropdown

    dispatch(updateLoadingState(false));

    const filter_is_saved =
      Array.isArray(checkArticleExistance) &&
      checkArticleExistance.filter(
        (x: SaverArticlesType) => x.is_saved !== false
      );
    dispatch(updateSavedArticles(filter_is_saved));

    dispatch(getCardData(response?.data?.data));

    // getVolumeArticle(event.target.value);
    // console.log("volumefiltersOne", filters);
    const yearData = currentYear === value;

    if (
      areObjectsEqual(volumeDropDownList[0], event.target.value) === true &&
      yearData === true
    ) {
      logTOCUsageData(90, details);
    } else {
      logTOCUsageData(87, details);
    }
  };

  const getResponse = async () => {
    let response = await bowserJournalSearchCard(details);

    dispatch(getCardData(response));
  };
  async function closeNoResultModal() {
    // setvalueText("");
    // setKeywords([]);
    // dispatch(updateTocPageSearch([]));
    // dispatch(clearAllFilters());
    // setNoResultModal(false);

    const currentSearchItems = [...searchItem];
    if (currentSearchItems.length > 0) {
      // Remove only the last added search key
      const updatedSearchItems = currentSearchItems.slice(0, -1);
      dispatch(updateTocPageSearch(updatedSearchItems));
    }
    if (valueText === "" && keywords.length == 0) {
      navigate(-1);
    }
    setvalueText("");
    setKeywords([]);
    dispatch(clearAllFilters());
    setNoResultModal(false);
  }
  const getVolme = async () => {
    const data = await getVolumeOrIssueDate(
      details.year_from[0],
      details.resourcemaster_id
    );
    setVolume(data?.data.data.docs);
  };
  useEffect(() => {
    const debounce = setTimeout(() => {
      // getResponse();
      setJournalResults();
      isArticleBookmarked();
      // getVolme();
    }, 100);

    return () => {
      clearTimeout(debounce);
    };
  }, [filters, keywords, selectedTab, rows, currentPage]);

  // useEffect(() => {
  //   if (details?.volumefrom) {
  //     setFilters(`&fq__resourceissue_id=${details?.volumefrom}`);
  //   }
  // }, []);

  // useEffect(() => {
  //   if (resourceMasterIdParam)
  //      getResourceDetails();
  //   window.scrollTo({ top: 0, left: 0, behavior: "auto" });
  //   updateBrowseJournalSearch(true);
  //   getAccessLockDetails();
  //   getResourceData();
  // }, []);
  // useEffect(() => {
  //   // if (value != -1 && value!==undefined) getVolumeDetailsOnMount();
  // }, [value]);

  // useEffect(() => {
  //   setJournalResults();
  //   getResourceDetails();
  // }, [details]);

  const getVolumeDetailsOnMount = async () => {
    // let response = await browserJournalDetailSearch(
    //   query +
    //     `&json_facet={"yearfrom":{"type":"terms","field":"yearfrom","limit":100,"sort":"index desc"},"monthfrom":{"type":"terms","field":"monthfrom","limit":100},"volumefrom":{"type":"terms","field":"volumefrom","limit":100},"issuenumberfrom":{"type":"terms","field":"issuenumberfrom","limit":100},"resource_status":{"type":"terms","field":"resource_status","limit":100},"primary_publisher_country":{"type":"terms","field":"primary_publisher_country","limit":100},"publisher_name":{"type":"terms","field":"publisher_name","limit":100},"journal_name":{"type":"terms","field":"journal_name","limit":100},"filter_metrix":{"type":"terms","field":"filter_metrix","limit":100}}` +
    //     `&fq__yearfrom=${value}`
    // );

    let response = await browserJournalGetVolume(
      query + `&fq__yearfrom=${value}&page=1`
    );

    if (response && !volumeDropDownList?.length) {
      setVolumeDropDownList(sortVolumesFunction(response?.data?.data?.docs));
    }
  };
  const markedArticleCount = useAppSelector(
    (state) => state.searchReducer.markedArticleCount
  );

  const handleSubmit = async () => {
    // const response = await getbrowserJournalOnSearch(
    //   details,
    //   valueText,
    //   resourceMasterIdParam
    // );
    // dispatch(getCardData(response));
    // if (details) {
    //   const data = await getVolumeOrIssueDate(
    //     details.year_from[0],
    //     details.resourcemaster_id
    //   );
    //   setVolume(data?.data.data.docs);
    // }
    // usageReport("87");
    //logTOCUsageData(87, details);

    if (valueText == "") {
      setMessage({
        messages: "Try for some appropriate keywords / terms",
        open: true,
      });
      return;
    }
    if (basicSearch?.from !== "basicSearch") {
      logTOCUsageData(18, details);
    }
    dispatch(updateBrowserPage(1));
    // dispatch(updateTocPageSearch([valueText]));
    // setKeywords([valueText]);

    const currentSearchItems = [...searchItem];
    if (!currentSearchItems.includes(valueText)) {
      const updatedKeywords = [...currentSearchItems, valueText];

      dispatch(updateTocPageSearch(updatedKeywords));
      setKeywords(updatedKeywords);
      setvalueText("");
    }
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  async function getResourceDetails() {
    if (resourceMasterIdParam) {
      let obj = { resourcemaster_id: resourceMasterIdParam };
      let response = await bowserJournalSearchCard(obj);
      dispatch(getCardData(response));
    }
  }

  const setJournalResults = async () => {
    let newQuery = "";
    dispatch(updateLoadingState(true));
    let id;
    if (resourceMasterIdParam) {
      id = resourceMasterIdParam;
    } else if (resourceId) {
      id = resourceId;
    } else {
      id = details.resourcemaster_id;
    }
    let subjects = "";
    let dataTypes = "";
    if (userSubjects?.customerDetails?.subject?.length > 0) {
      userSubjects?.customerDetails?.subject?.map(
        (element: any, index: any) => {
          subjects += `${element.datsubjectmasterlevel2_id} OR `;
        }
      );
      subjects = `(${subjects.slice(0, -3)})`;
    }

    if (userSubjects?.customerDetails?.datatypes?.length > 0) {
      userSubjects?.customerDetails?.datatypes?.map(
        (element: any, index: any) => {
          dataTypes += `${element.dat_resourcetype_id} OR `;
        }
      );
      dataTypes = `(${dataTypes.slice(0, -3)})`;
    }
    let titleKeywords = "";

    if (searchItem.length !== 0) {
      searchItem?.map(
        (element: any) =>
          (titleKeywords += `"${element?.replace(
            /^\"+|\“+|\"+|\”$/g,
            ""
          )}" AND `)
      );
    }

    titleKeywords = `(${titleKeywords.slice(0, -5)})`;
    setselectedListTags([...searchItem]);
    let apiJournalData = {
      profileuserid: userId,
      sessionid: session_key,
      customerid: informaticscustomer_id,
      // consortiamaster_id: userSubjects?.customerDetails?.consortiamaster_id,
      datsearchtype_id: 1,
      datproductcontrolitem_id: 4,
      datsearch_action_type_id: 1,
      fq__resourcemaster_id: id,
      // sort: "dateofpublication desc",
      sort: "dateofpublication desc, volumefrom desc, issuenumberfrom desc",
    };

    if (userSubjects?.customerDetails?.consortiamaster_id) {
      Object.assign(apiJournalData, {
        consortiamaster_id: userSubjects?.customerDetails?.consortiamaster_id,
      });
    }

    if (
      subjects.length > 2 &&
      selectedFilters.collectionsValueJournal !== "My Library Collections" &&
      selectedFilters.collectionsValueJournal !== "Consortia Collections"
    )
      Object.assign(apiJournalData, { fq__subjects_id_l2: subjects });
    if (dataTypes.length > 2)
      Object.assign(apiJournalData, { fq__resource_type: dataTypes });
    Object.assign(apiJournalData, { datproductcontrolitem_id: 55 });
    Object.assign(apiJournalData, { datsearchtype_id: 4 });
    Object.assign(apiJournalData, { datsearch_action_type_id: 4 });
    if (searchItem.length != 0) {
      Object.assign(apiJournalData, { titleKeywordsAbs: titleKeywords });
    }
    // Removed for DF-1006
    //
    // if (basicSearch?.from === "basicSearch") {
    //   Object.assign(apiJournalData, {
    //     fq__resourceissue_id: basicSearch?.item?.resourceissue_id,
    //   });
    // }
    if (selectedTab == "full-text")
      Object.assign(apiJournalData, { fulltext: true });
    Object.assign(apiJournalData, { page: currentPage });
    // Here row 500 is added to get the volume and issue number
    Object.assign(apiJournalData, { rows: 500 });

    setQuery("?" + objectToQueryString(apiJournalData));
    newQuery = "?" + objectToQueryString({ ...apiJournalData, page: 1 });
    // Here row is assigned to rows to get journal details search
    Object.assign(apiJournalData, { rows: rows });
    Object.assign(apiJournalData, { fq__resource_type: "(1 OR 2 )" });
    Object.assign(apiJournalData, {
      json_facet: JSON.stringify({
        yearfrom: {
          type: "terms",
          field: "yearfrom",
          limit: 100,
          sort: "index desc",
        },
        monthfrom: { type: "terms", field: "monthfrom", limit: 100 },
        volumefrom: { type: "terms", field: "volumefrom", limit: 100 },
        issuenumberfrom: {
          type: "terms",
          field: "issuenumberfrom",
          limit: 100,
        },
        resource_status: {
          type: "terms",
          field: "resource_status",
          limit: 100,
        },
        primary_publisher_country: {
          type: "terms",
          field: "primary_publisher_country",
          limit: 100,
        },
        publisher_name: { type: "terms", field: "publisher_name", limit: 100 },
        journal_name: { type: "terms", field: "journal_name", limit: 100 },
        filter_metrix: { type: "terms", field: "filter_metrix", limit: 100 },
      }),
    });

    // if the user only has the consortia access the consortia_filter query should append in all query
    const isOnlyConsortiaAccess = profileData1?.product_type === "7";
    if (isOnlyConsortiaAccess) {
      Object.assign(apiJournalData, {
        fq__acl_group: profileData1.consortia_filter,
      });
    }

    if (selectedFilters.collectionsValueJournal === "My Library Collections") {
      Object.assign(apiJournalData, {
        fq__acl_group: profileData1?.my_library_filter,
      });
    }

    if (selectedFilters.collectionsValueJournal === "Consortia Collections") {
      Object.assign(apiJournalData, {
        fq__acl_group: profileData1?.consortia_filter,
      });
    }

    let response = await browserJournalDetailSearch(
      (basicSearch?.from === "basicSearch" ||
        basicSearch?.from === "abstractFullScreen") &&
        !filters?.includes("fq__resourceissue_id") &&
        (value == -1 || value == undefined) &&
        !getDataList?.length
        ? "?" +
            objectToQueryString(apiJournalData) +
            filters +
            `&fq__resourceissue_id=${basicSearch?.item?.resourceissue_id}`
        : (basicSearch?.from === "basicSearch" ||
            basicSearch?.from === "abstractFullScreen") &&
          getDataList?.length &&
          volumeDetailsTOC !== ""
        ? "?" +
          objectToQueryString(apiJournalData) +
          filters +
          `&fq__resourceissue_id=${
            isSelecetdIssue && volumeDetailsTOC?.resourceissue_id
              ? volumeDetailsTOC?.resourceissue_id
              : basicSearch?.item?.resourceissue_id
          }`
        : // : "?" + objectToQueryString(apiJournalData) + filters + "",
          "?" +
          objectToQueryString(apiJournalData) +
          filters +
          (isSelecetdIssue && volumeDetailsTOC?.resourceissue_id
            ? `&fq__resourceissue_id=${volumeDetailsTOC?.resourceissue_id}`
            : ""),
      dispatch
    );

    const articleData = new FormData();

    let articleId: any = [];
    if (response?.data?.data?.docs?.length > 0) {
      response?.data.data.docs?.map((element: any) => {
        articleId = articleId.concat(element?.article_id);
      });
    }
    if (response?.data?.data?.docs?.length == 0) {
      setNoResultModal(true);
    }

    articleData.append("profile_id", selector.user_id);
    articleData.append("article_ids", articleId.toString());

    const checkArticleExistance = await checkMultipleArticleExistence(
      articleData
    );

    let years: any[] = [];
    dispatch(getCardData(response?.data?.data));
    // dispatch(updateLoadingState(false));

    // fetch links details
    await fetchLinkDetails(response?.data?.data?.docs);
    // dispatch(updateLoadingState(true));

    //alert("test");
    let newResponse: any = {};
    if (
      basicSearch?.from === "basicSearch" ||
      basicSearch?.from === "abstractFullScreen" ||
      filters?.includes("fq__resourceissue_id") ||
      !objectToQueryString(apiJournalData)?.includes("fq__resourceissue_id")
    ) {
      //alert("test-2");
      newResponse = await axios.get(
        api.baseApi +
          `/api/indexing/solr/articlecore2${
            "?" + objectToQueryString(apiJournalData) + filters
          }&fq__resourceissue_id=*`
      );
    }

    newResponse?.data
      ? newResponse?.data?.data?.jsonfacets?.yearfrom?.buckets?.map(
          (element: any) => (years = [...years, element.val.toString()])
        )
      : response?.data?.data?.jsonfacets?.yearfrom?.buckets?.map(
          (element: any) => (years = [...years, element.val.toString()])
        );
    // year dropdown
    setYearDropDownList(years);
    if (!value) {
      setValue(years[0]);
      alert("test");

      // Fetching volume and prefilling data
      let volumeRes = await browserJournalGetVolume(
        newQuery + `&fq__yearfrom=${years[0]}`
      );
      let firstVolData = volumeRes?.data?.data?.docs[0];
      setValueOne(firstVolData);
      dispatch(updateVolumeOfTOC(firstVolData));
      setVolumeDropDownList(sortVolumesFunction(volumeRes?.data?.data?.docs));
    }
    if (
      basicSearch?.from === "basicSearch" ||
      (basicSearch?.from === "abstractFullScreen" &&
        (value == -1 || value == undefined) &&
        !getDataList?.length)
    ) {
      let response = await browserJournalGetVolume(
        newQuery +
          `&fq__yearfrom=${
            isSelecetdIssue && getDataList.length
              ? getDataList[0]?.yearfrom
              : basicSearch?.date
              ? basicSearch?.date
              : basicSearch?.item?.yearfrom
          }`
      );
      if (response) {
        setVolumeDropDownList(sortVolumesFunction(response?.data?.data?.docs));
        // setValue(
        //   isSelecetdIssue && getDataList.length
        //     ? getDataList[0]?.yearfrom
        //     : basicSearch?.date
        //     ? basicSearch?.date
        //     : basicSearch?.item?.yearfrom
        // );
        setValue(
          isSelecetdIssue && getDataList.length
            ? getDataList[0]?.yearfrom
            : basicSearch?.date || basicSearch?.item?.yearfrom
        );
        dispatch(
          updateVolumeOfTOC(
            response?.data.data.docs?.find((ele: any) =>
              ele?.resourceissue_id == isSelecetdIssue && getDataList.length
                ? volumeDetailsTOC?.resourceissue_id
                : basicSearch?.item?.resourceissue_id
            )
          )
        );
        setValueOne(
          response?.data.data.docs?.find(
            (ele: any) =>
              ele?.resourceissue_id == volumeDetailsTOC?.resourceissue_id
            // : basicSearch?.item?.resourceissue_id
          )
        );
      }
    } else {
      if (
        getDataList?.length &&
        (value == -1 || value == undefined) &&
        volumeDetailsTOC != ""
      ) {
        let response = await browserJournalGetVolume(
          newQuery + `&fq__yearfrom=${getDataList[0]?.yearfrom}`
        );

        if (response) {
          setVolumeDropDownList(response?.data.data.docs);
          setValue(getDataList[0]?.yearfrom);
          dispatch(
            updateVolumeOfTOC(
              response?.data.data.docs?.find(
                (ele: any) =>
                  ele?.resourceissue_id == volumeDetailsTOC?.resourceissue_id
              )
            )
          );
          setValueOne(
            response?.data.data.docs?.find(
              (ele: any) =>
                ele?.resourceissue_id == volumeDetailsTOC?.resourceissue_id
            )
          );
        }
      } else if (years.length >= 1 && (value == -1 || value == undefined)) {
        setValue(years[0]);
        let response = await browserJournalGetVolume(
          newQuery + `&fq__yearfrom=${years[0]}`
        );
        if (response) {
          setVolumeDropDownList(
            sortVolumesFunction(response?.data?.data?.docs)
          );
          let firstVolData = response?.data?.data?.docs[0];
          setValueOne(firstVolData);
        }
      }
    }
    dispatch(updateLoadingState(false));
    goToTop();

    const filter_is_saved =
      Array.isArray(checkArticleExistance) &&
      checkArticleExistance.filter(
        (x: SaverArticlesType) => x.is_saved !== false
      );
    dispatch(updateSavedArticles(filter_is_saved));

    dispatch(getCardData(response?.data?.data));
  };

  async function fetchLinkDetails(arr: any) {
    if (!Array.isArray(arr)) return;
    let formData = new FormData();
    let sessionCustomerId = sessionStorage.getItem("informaticscustomer_id");

    let x = arr?.map((obj: any) => {
      let object: any = {
        customer_id: customerId || sessionCustomerId,
        article_id: obj?.article_id,
        consortia_virtualid: consortia_virtualid,
        resource_type: obj?.resource_type,
        access_type: obj?.article_open_status || null,
        is_oa_article: obj?.fulltext || false,
      };

      if (obj?.articledoi) {
        object.article_doi = obj?.articledoi;
      }

      if (obj?.resourceissue_id) {
        object.resourceissue_id = obj?.resourceissue_id;
      }

      if (obj?.is_onos_resource) {
        object.is_onos_resource = true;
      }

      return object;
    });

    let formApiData = {
      data: x,
    };

    formData.append("detailed_json", JSON.stringify(formApiData));
    const response = await getBulkLinkRecords(formData);

    if (response?.message === "Ok") {
      setLinkToDisplay(response.data);
    }
  }

  function getMarkedResult(res: any, id: string) {
    let data = structuredClone({
      ...res,
      searchTerm: searchItem.toString(),
    });
    // Handle id
    if (markedData.includes(id)) {
      let filterIds = markedArticlesid.filter((data: any) => data !== id);
      let filterIcCount = markedArticleCount.filter((data: any) => data !== id);
      let filterData = markedResult.filter(
        (data: any) => data?.article_id !== id
      );
      dispatch(updateMarkedResult(filterData));
      dispatch(updateMarkedArticleId(filterIds));

      dispatch(updateMarkedArticleCount(filterIcCount));
      setMarkdeData((prev) => prev.filter((obj) => obj !== id));
    } else if (markedData?.length) {
      let articlesIds = [...markedArticlesid, id];
      let markedResultData = [...markedResult, data];
      let idCount = [...markedArticleCount, id];

      dispatch(updateMarkedResult(markedResultData));
      dispatch(updateMarkedArticleId(articlesIds));
      dispatch(updateMarkedArticleCount(idCount));

      setMarkdeData((prev) => [...prev, id]);
    } else {
      if (!markedArticlesid.length) {
        dispatch(updateMarkedArticleId([id]));
        dispatch(updateMarkedResult([data]));
        dispatch(updateMarkedArticleCount([id]));
      } else {
        let articlesIds = [...markedArticlesid, id];
        let markedResultData = [...markedResult, data];
        dispatch(updateMarkedResult(markedResultData));
        dispatch(updateMarkedArticleId(articlesIds));
        dispatch(updateMarkedArticleCount([id]));
      }

      setMarkdeData([id]);
    }
  }

  function selectAllArticles(e: any) {
    try {
      let dataArray = searchResults?.docs;
      dataArray = dataArray?.map((x: any) =>
        structuredClone({ ...x, searchTerm: searchTerm })
      );
      if (!Array.isArray(dataArray)) return;
      if (e?.target?.checked) {
        let filteredMarkedIds = [];
        let filteredMarkedData = [];
        let articlesIds = dataArray?.map((obj) => obj?.article_id);

        for (let id of articlesIds) {
          if (!markedArticlesid.includes(id)) filteredMarkedIds.push(id);
        }

        for (let obj of dataArray) {
          if (filteredMarkedIds.includes(obj?.article_id))
            filteredMarkedData.push(obj);
        }
        dispatch(updateMarkedResult([...markedResult, ...filteredMarkedData]));

        dispatch(
          updateMarkedArticleId([...markedArticlesid, ...filteredMarkedIds])
        );
        dispatch(
          updateMarkedArticleCount([...markedArticlesid, ...filteredMarkedIds])
        );
        setMarkdeData(articlesIds);
      } else {
        setMarkdeData([]);
        // curr res ids
        let articlesIds = dataArray?.map((obj) => obj?.article_id);

        // check the current res id in marked articls ids and remove all
        let filteredMarkedIds = [];
        let filteredMarkedData = [];

        for (let id of markedArticlesid) {
          if (!articlesIds.includes(id)) filteredMarkedIds.push(id);
        }

        dispatch(updateMarkedArticleId(filteredMarkedIds));
        dispatch(updateMarkedArticleCount(filteredMarkedIds));

        // keep the data in mark result that matches  "filteredMarkedIds"
        for (let obj of markedResult) {
          if (filteredMarkedIds.includes(obj?.article_id))
            filteredMarkedData.push(obj);
        }

        dispatch(updateMarkedResult(filteredMarkedData));
      }
    } catch (err) {
      console.log(err);
    }
  }

  function isArticleBookmarked() {
    try {
      let dataArray = searchResults?.docs;

      if (!Array.isArray(dataArray)) return;

      let articlesIds = dataArray?.map((obj) => obj?.article_id);
      let id = [];

      for (let str of articlesIds) {
        if (markedArticlesid.includes(str)) id.push(str);
      }

      if (id.length) {
        setMarkdeData(id);
      } else {
        setMarkdeData([]);
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    markResultStatus();
  }, [markedData?.length]);

  function markResultStatus() {
    if (markedData?.length === searchResults?.docs?.length) {
      setMarkResult(true);
    } else {
      setMarkResult(false);
    }
  }

  const onRemove = (index: number) => {
    const removeKeyword = searchItem.filter((e: any, i: number) => {
      return e != searchItem[index];
    });
    //dispatch(updateTocPageSearch([]));
    dispatch(updateTocPageSearch(removeKeyword));

    setKeywords(removeKeyword);
  };

  const onChangeKeyword = async (e: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setvalueText(e.target.value);
    let searchedLetters: any = "";
    if (e.target.value.length >= 3) {
      // @ts-ignore
      let searchedArrayLetters = e.target.value.split(" ");
      searchedArrayLetters?.map((element: string) => {
        searchedLetters += `${element}\\ of\\ `;
      });

      let apiJournalData = {
        resource_name_tk: `${searchedLetters.slice(0, -6)}*`,
      };

      let response = await browserAutoSuggestion(
        objectToQueryString(apiJournalData)
      );

      setAutoSearch(response?.data.data.docs);
    }
    // dispatch(setKeyword(e.target.value));
  };

  function handleResource(data: any) {
    navigate(
      `/BrowseJournalTableSearch/BrowseJournalSearch?resourcemaster_id=${data?.resourcemaster_id}`
    );
  }

  let searchTerm =
    searchItem?.length == 0 ? details?.resource_name_tk : searchItem;
  function handleAuthor(authorName: string, data: any) {
    // dispatch(clearSearchTerm());
    // dispatch(clearAllFilters());
    // dispatch(clearPublicationFilter());
    // if (data?.authors?.length) {
    //   dispatch(updateAuthor([authorName]));
    //   let appliedFilter = {
    //     key: "author",
    //     value: authorName,
    //   };
    //   dispatch(updateAllFilter(appliedFilter));
    // } else if (data?.speakers?.length) {
    //   dispatch(updateSpeaker([authorName]));
    //   let appliedFilter = {
    //     key: "speaker",
    //     value: authorName,
    //   };
    //   dispatch(updateAllFilter(appliedFilter));
    // }
    // dispatch(updatePage(1));
    // dispatch(updateRow(15));
    // dispatch(search());
  }

  function getButtonType(article_id: string) {
    try {
      if (!article_id) return;
      if (!linkToDisplay) return;

      for (let obj of linkToDisplay) {
        if (obj?.article_id === article_id) {
          return obj?.button_to_display;
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  function closeSjrModal() {
    setIsOpen(false);
  }

  const getJornalHistory = async () => {
    const resourcemasterID = resourceId
      ? resourceId
      : details.resourcemaster_id;
    try {
      let response = await axios.get(
        api.baseApi +
          `/api/get_resource_history/get_resource_history?resource_id=${resourcemasterID}`
      );
      setJournalHistory(response.data.data[0][0]);
    } catch (err) {
      console.log(err);
    }
  };

  const handleAbstractView = (data: any) => {
    logTOCUsageData(30, data);
    navigate("/abstractFullScreen", {
      state: {
        data,
        searchTerm,
        from: "BrowseJournalTableSearch/BrowseJournalSearch",
        linkToDisplay,
        resultFrom: "BrowseJournalTableSearch/BrowseJournalSearch",
      },
    });
  };

  const hasRequiredData = volumeDropDownList?.some(
    (e: any) =>
      (e?.monthfrom && e?.monthfrom != "") ||
      (e?.volumefrom && e?.volumefrom != "") ||
      (e?.issuenumberfrom && e?.issuenumberfrom != "") ||
      e?.datefrom !== 0 ||
      e?.datefrom !== null
  );

  const objectWithBookTitle = volumeDropDownList?.find(
    (obj) => obj.book_title !== undefined
  );

  const sortVolumesFunction = (data: any) => {
    const sortedVolumes = data?.sort((a: any, b: any) => {
      if (a?.volumefrom === "Early-Online") return -1; // a should come first
      if (b?.volumefrom === "Early-Online") return 1; // b should come first

      const volumeA = parseInt(a?.volumefrom, 10);
      const volumeB = parseInt(b?.volumefrom, 10);
      const issueA = parseInt(a?.issuenumberfrom, 10);
      const issueB = parseInt(b?.issuenumberfrom, 10);

      // // First sort by volume, then by issue number
      // if (volumeA !== volumeB) {
      //   return volumeA - volumeB;
      // }
      // return issueB - issueA;
    });

    return sortedVolumes;
  };

  return (
    <div style={{ backgroundColor: "#f5f5f5" }}>
      <Box
        py={"0.5%"}
        sx={{
          paddingInline: { xs: "0px", sm: "22px", md: "66px" },
          backgroundColor: "#FFFFFF",
        }}
      >
        <Card sx={{ borderWidth: 0, overflow: "visible" }}>
          <CardContent
            sx={[
              {
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                padding: "1% 1% 0.5% 1%",
                "&:last-child": { paddingBottom: "0.5%" },
              },
            ]}
          >
            <Box
              sx={{
                ...webStyles.flexRow,
                alignItems: "flex-start",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                }}
              >
                <IconButton
                  onClick={() => {
                    dispatch(getCardData({}));
                    dispatch(updateTocPageSearch([]));
                    {
                      resourceId
                        ? navigate("/initialSearch")
                        : previousLocation.current.state.from ===
                          "BrowseJournalTableSearch"
                        ? navigate("/BrowseJournalTableSearch", {
                            state: {
                              from: "BrowseJournalTableSearch/BrowseJournalSearch",
                            },
                          })
                        : //: // : location?.state?.from === "abstractFullScreen"
                        // ? // : location?.state?.from === "basicSearch"
                        //   // ? navigate(-1):
                        //   navigate("/abstractFullScreen", {
                        //     state: {
                        //       data: location?.state?.previousScreenData,
                        //       from: "browserJournalSearch",
                        //     },
                        //   })
                        location?.state?.from === "basicSearch"
                        ? navigate(-1)
                        : // : navigate(-1);
                        location?.state?.from === "abstractFullScreen"
                        ? navigate("/abstractFullScreen", {
                            state: {
                              data: location?.state?.previousScreenData,
                              from: "browserJournalSearch",
                            },
                          })
                        : navigate(-1);
                    }

                    dispatch(updateVolumeOfTOC(""));
                    dispatch(updatedStoredSelectedIssue(false));
                  }}
                >
                  <ArrowBackIosIcon
                    sx={{ alignSelf: "center" }}
                    fontSize="small"
                  />
                </IconButton>
                <Box sx={{ ...webStyles.flexCol }}>
                  <Typography
                    sx={[webStyles.headerText, { margin: 0 }]}
                    variant="h5"
                    gutterBottom
                  >
                    <MathJax>
                      <div
                        id="title"
                        dangerouslySetInnerHTML={{
                          __html: highlightKeyword(
                            details?.resource_name_tk,
                            []
                          ),
                        }}
                      />
                    </MathJax>
                  </Typography>
                  <Box
                    sx={{
                      ...webStyles.flexRow,
                      alignItems: { xs: "flex-start", sm: "baseline" },
                    }}
                    mt={1}
                  >
                    <Typography
                      sx={{ ...webStyles.subText, display: "flex" }}
                      gutterBottom
                    >
                      <Text sx={{ ...webStyles.subText, marginRight: "4px" }}>
                        Published By:{"  "}
                      </Text>
                      <MathJax>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: details?.publisher_name_tk
                              ? details?.publisher_name_tk
                              : details?.publisher_name[0],
                          }}
                        />
                      </MathJax>
                    </Typography>
                    <CardActions
                      sx={{
                        padding: 0,
                      }}
                    >
                      <Box
                        sx={[
                          webStyles.flexBox,
                          {
                            justifyContent: "space-between",

                            width: "100%",
                          },
                        ]}
                      >
                        <Box
                          sx={webStyles.flexBox}
                          onClick={() => {
                            getJornalHistory();
                            setViewDetails(!viewDetails);
                            logTOCUsageData(93, details);
                          }}
                        >
                          <Typography
                            sx={{
                              alignSelf: "center",
                              color: Colors.primary,
                              marginLeft: { xs: 0, sm: 1 },
                              fontFamily: "Helvetica Neue",
                              fontStyle: "normal",
                              fontSize: { xs: "0.9rem", md: 14 },
                            }}
                          >
                            View Details
                          </Typography>

                          <ExpandMore
                            expand={expanded}
                            onClick={handleExpandClick}
                            aria-expanded={expanded}
                            aria-label="show more"
                            sx={{ color: Colors.primary, padding: 0 }}
                          >
                            <ExpandLessIcon />
                          </ExpandMore>
                        </Box>
                        {/* <Typography sx={{ fontStyle: "italic", alignSelf: "center" }}>
                            vol 23, no 1, mar 2023
                            </Typography> */}
                      </Box>
                    </CardActions>
                  </Box>
                  <CardContent
                    sx={{
                      padding: 0,
                      width: "100%",
                      "&:last-child": { padding: 0 },
                      justifyItems: "flex-start",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: { xs: "start", sm: "center" },
                        flexDirection: { xs: "column", sm: "row" },
                        justifyItems: "flex-start",
                        width: "100%",
                      }}
                    >
                      {/* ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
                        Peer Reviewd
                      ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                        }}
                      >
                        <Box
                          sx={[
                            webStyles.flexBox,
                            {
                              justifyContent: "space-between",
                              gap: 1,
                              margin: "auto 0px",
                            },
                          ]}
                        >
                          {details?.peer_reviewed && (
                            <PeopleIcon
                              sx={{ fontSize: { xs: "18px", md: "24px" } }}
                            ></PeopleIcon>
                          )}

                          {details?.peer_reviewed && (
                            <Typography sx={webStyles.peerReviewed}>
                              Peer Reviewed&nbsp;&nbsp;
                            </Typography>
                          )}
                        </Box>
                        <Box sx={{ display: { xs: "none", sm: "flex" } }}>
                          <IconButton>
                            <AccessLockJournal
                              setGridDisplay={setGridDisplay}
                              openAccess={
                                details?.is_openaccess ||
                                details?.openaccess_type === "Open Access"
                              }
                              access_type={lock?.access_type}
                              GridDisplay={gridDisplay}
                              resource_id={details}
                              gridId={gridId}
                              setGridId={setGridId}
                              gridData={gridData}
                              setGridData={setGridData}
                            />

                            {gridId !== "" && gridId == lock?.resource_id
                              ? gridDisplay && (
                                  <Grid sx={webStyles.gridStyle}>
                                    <TableContainer component={Paper}>
                                      <Table
                                        sx={{ minWidth: 10 }}
                                        aria-label="simple table"
                                      >
                                        <TableHead>
                                          <TableRow>
                                            <TableCell>Access Type</TableCell>
                                            <TableCell align="right">
                                              Start Date
                                            </TableCell>
                                            <TableCell align="right">
                                              End Date
                                            </TableCell>
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          {gridData?.map((gridItem: any) => (
                                            <TableRow
                                              key={"row.name"}
                                              sx={{
                                                "&:last-child td, &:last-child th":
                                                  { border: 0 },
                                              }}
                                            >
                                              <TableCell align="right">
                                                {gridItem?.access_type}
                                              </TableCell>

                                              <TableCell align="right">
                                                {gridItem?.start_date}
                                              </TableCell>
                                              <TableCell align="right">
                                                {gridItem?.end_date}
                                              </TableCell>
                                            </TableRow>
                                          ))}
                                        </TableBody>
                                      </Table>
                                    </TableContainer>
                                  </Grid>
                                )
                              : ""}
                          </IconButton>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          //width: { xs: "100%", sm: "auto" },
                          display: "flex",
                          // flexDirection: { xs: "column", sm: "row" },
                          flexDirection: "row",
                          width: "100%",
                        }}
                      >
                        {details?.sjr_score ? (
                          <Box>
                            <Typography
                              variant="body2"
                              sx={{ fontSize: { xs: "12px", md: "14px" } }}
                            >
                              {/* {details.filter_metrix[0]}: */}
                              SJR :{" "}
                              <a
                                onClick={() => setIsOpen(true)}
                                // href={`https://www.scimagojr.com/journalsearch.php?q=${details?.sjr_id}&amp;tip=sid&amp;exact=no`}
                                title="SCImago Journal &amp; Country Rank"
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                  color: Colors.primary,
                                  cursor: "pointer",
                                }}
                              >
                                {details?.sjr_score}
                              </a>
                            </Typography>{" "}
                            {/* <Typography
                  variant="body2"
                  style={{ marginRight: "0.5rem", color: Colors.primary }}
                > */}
                            {/* </Typography> */}
                          </Box>
                        ) : null}
                        <Divider
                          orientation="vertical"
                          style={{
                            height: "50%",
                            alignSelf: "center",
                            margin: "0% 1%",
                          }}
                        />
                        {details?.sjr_hindex ? (
                          <Box>
                            <Typography
                              variant="body2"
                              sx={{ fontSize: { xs: "12px", md: "14px" } }}
                            >
                              H-Index :
                              <span style={{ color: Colors.primary }}>
                                {details?.sjr_hindex}
                              </span>
                            </Typography>
                            {/* <Typography
                  variant="body2"
                  sx={{ color: Colors.gray_700, mr: ".5rem" }}
                >
                  {details.sjr_hindex}
                </Typography> */}
                          </Box>
                        ) : null}
                        <Divider
                          orientation="vertical"
                          style={{
                            height: "50%",
                            alignSelf: "center",
                            margin: "0% 1%",
                          }}
                        />
                        {consortiamaster_id === 1874 && details?.naas_value ? (
                          <Box>
                            <Typography variant="body2">
                              NAAS :
                              <span style={{ color: Colors.primary }}>
                                {details?.naas_value}
                              </span>
                            </Typography>
                          </Box>
                        ) : null}
                      </Box>
                    </Box>
                  </CardContent>
                </Box>
              </Box>
              <Box
                sx={[
                  webStyles.flexBox,
                  { alignItems: "flex-start" },
                  {
                    width: { xs: "100%", sm: "auto" },
                    justifyContent: "space-between",
                  },
                  { marginLeft: { xs: "auto", sm: "none" } },
                ]}
              >
                <Box
                  sx={{
                    display: { xs: "flex", sm: "none" },
                    marginLeft: "22px",
                  }}
                >
                  <IconButton>
                    <AccessLockJournal
                      setGridDisplay={setGridDisplay}
                      openAccess={
                        details?.is_openaccess ||
                        details?.openaccess_type === "Open Access"
                      }
                      access_type={lock?.access_type}
                      GridDisplay={gridDisplay}
                      resource_id={details}
                      gridId={gridId}
                      setGridId={setGridId}
                      gridData={gridData}
                      setGridData={setGridData}
                    />

                    {gridId !== "" && gridId == lock?.resource_id
                      ? gridDisplay && (
                          <Grid sx={webStyles.gridStyle}>
                            <TableContainer component={Paper}>
                              <Table
                                sx={{ minWidth: 10 }}
                                aria-label="simple table"
                              >
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Access Type</TableCell>
                                    <TableCell align="right">
                                      Start Date
                                    </TableCell>
                                    <TableCell align="right">
                                      End Date
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {gridData?.map((gridItem: any) => (
                                    <TableRow
                                      key={"row.name"}
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      <TableCell align="right">
                                        {gridItem?.access_type}
                                      </TableCell>

                                      <TableCell align="right">
                                        {gridItem?.start_date}
                                      </TableCell>
                                      <TableCell align="right">
                                        {gridItem?.end_date}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Grid>
                        )
                      : ""}
                  </IconButton>
                </Box>
                <Box>
                  {favorites ? (
                    <Tooltip title="Remove Favourite">
                      <IconButton
                        style={{ color: "#FFC000" }}
                        onClick={async (e) => {
                          logTOCUsageData(111, details);
                          e.stopPropagation();
                          if (profileStatus === "true") {
                            let response = await markFav(
                              details.resourcemaster_id,
                              false,
                              loginDetails
                            );
                            if (response) {
                              if (
                                response[0].message ==
                                "Marked favorite successfully!"
                              ) {
                                setFavorites(false);
                              } else if (
                                response[0].message == "limit count exceed"
                              ) {
                                notify("error", "limit count exceed");
                              } else if (
                                response[0].message ==
                                "Removed favorite successfully!"
                              ) {
                                setFavorites(false);
                              }
                              // setFav(true);
                              let resourceIdList: string[] = [];
                              alertUpdate.docs?.map((element: any) => {
                                resourceIdList.push(element.resourcemaster_id);
                              });

                              setJournalResults();
                            }
                          } else {
                            showIsProfileToast(
                              "Please login as profile user to set alert"
                            );
                          }
                        }}
                      >
                        <StarIcon></StarIcon>
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Set Favourite">
                      <IconButton
                        onClick={async (e) => {
                          logTOCUsageData(111, details);
                          e.stopPropagation();
                          if (profileStatus === "true") {
                            let response = await markFav(
                              details.resourcemaster_id,
                              true,
                              loginDetails
                            );
                            if (response) {
                              if (
                                response[0].message ==
                                "Marked favorite successfully!"
                              ) {
                                setFavorites(true);
                              } else if (
                                response[0].message == "limit count exceed"
                              ) {
                                notify("error", "limit count exceed");
                              }
                              let resourceIdList: string[] = [];
                              alertUpdate.docs?.map((element: any) => {
                                resourceIdList.push(element.resourcemaster_id);
                              });
                              setJournalResults();
                              // let updatedAlertRes = await resourceSettingAlert(
                              //   // item.resourcemaster_id
                              //   resourceIdList
                              // );
                              // setJournalSearchResults(updatedAlertRes);
                              // dispatch(alertsAndFav(updatedAlertRes));
                            }
                          } else {
                            showIsProfileToast(
                              "Please login as profile user to set alert"
                            );
                          }
                        }}
                      >
                        <StarOutlineIcon></StarOutlineIcon>
                      </IconButton>
                    </Tooltip>
                  )}
                  {notification ? (
                    <Tooltip title="Modify Alert">
                      <IconButton
                        style={{ color: "#F48120" }}
                        onClick={async (e) => {
                          e.stopPropagation();
                          if (profileStatus === "true") {
                            handleCite();
                            if (basicSearch?.from === "basicSearch") {
                              dispatch(search());
                            }
                          } else {
                            showIsProfileToast(
                              "Please login as profile user to set alert"
                            );
                          }
                        }}
                      >
                        <RssFeedIcon></RssFeedIcon>
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Set Alert">
                      <IconButton
                        onClick={async (e) => {
                          e.stopPropagation();
                          if (profileStatus === "true") {
                            handleCite();
                            if (basicSearch?.from === "basicSearch") {
                              dispatch(search());
                            }
                          } else {
                            showIsProfileToast(
                              "Please login as profile user to set alert"
                            );
                          }
                        }}
                      >
                        <RssFeedIcon></RssFeedIcon>
                      </IconButton>
                    </Tooltip>
                  )}
                </Box>
              </Box>
            </Box>

            <Box
              sx={[
                {
                  display: "flex",
                  flexDirection: "row",
                },
              ]}
            >
              <TextField
                required
                sx={[{ backgroundColor: "#fff" }, webStyles.inputField]}
                placeholder="Search Keyword"
                size={isXs ? "small" : "medium"}
                fullWidth
                value={valueText}
                onChange={(e) => {
                  // setKeywords([...searchItem, e.target.value]);
                  setvalueText(e.target.value);
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter" && valueText) handleSubmit();
                }}
              />

              {/* <Autocomplete
              id="free-solo-demo"
              freeSolo
              options={autoSearch?.map((option: any) => option.resource_name)}
              sx={{ width: 300 }}
              // @ts-ignore
              onChange={(event: any, newValue: string | null) => {
                setAutoSearch([]);
                // setKeyWords([
                //   ...keyWords,
                //   { searchType: searchContainOrStartWith, words: keyWord },
                // ]);
              }}
              onClick={(e) => console.log(e)}
              value={valueText}
              size="small"
              placeholder="Title"
              // @ts-ignore
              style={{ width: "100%" }}
              renderInput={(params) => (
                <TextField
                  required
                  sx={[{ backgroundColor: "#fff" }, webStyles.inputField]}
                  placeholder="Search Keyword"
                  value={valueText}
                  onChange={onChangeKeyword}
                  onKeyPress={(e) => {
                    if (e.key === "Enter" && valueText) handleSubmit();
                  }}
                  {...params}
                />
              )}
            /> */}

              <Button
                variant="outlined"
                sx={{
                  ...webStyles.buttonOne,
                  display: { xs: "none", sm: "flex" },
                }}
                onClick={handleSubmit}
              >
                Search
              </Button>
              <IconButton sx={{ display: { xs: "flex", sm: "none" } }}>
                <SearchIcon onClick={handleSubmit} />
              </IconButton>
            </Box>

            <Box
              sx={[
                //webStyles.flexRow,
                {
                  display: "flex",
                  flexDirection: { xs: "row", sm: "row" },
                  marginTop: "1.5%",
                  // marginBottom: "1%",
                  flexWrap: "wrap",
                  gap: 1,
                  alignItems: { xs: "start", sm: "center" },
                  width: { xs: "100%", sm: "none" },
                },
              ]}
            >
              <>
                <Button
                  sx={webStyles.clearallText}
                  onClick={() => {
                    setKeywords([]);
                    dispatch(updateTocPageSearch([]));
                  }}
                >
                  Clear All
                </Button>
                {/* {searchReducer?.setAlphabet != "" ? (
                <Stack direction="row" spacing={2}>
                  <Button
                    size="small"
                    variant="outlined"
                    endIcon={<CloseIcon />}
                    sx={webStyles.searchTextFont}
                    // onClick={() => {
                    //   if (i > 0) onRemove(val);
                    // }}
                  >
                    {searchReducer?.setAlphabet}
                  </Button>
                </Stack>
              ) : null} */}
                {searchItem.length !== 0
                  ? searchItem?.map((element: string, i: number) => {
                      return (
                        <Stack direction="row" spacing={2}>
                          <Button
                            size="small"
                            variant="outlined"
                            endIcon={<CloseIcon />}
                            sx={webStyles.searchTextFont}
                            onClick={() => {
                              onRemove(i);
                            }}
                          >
                            {toTitleCase(element)}
                          </Button>
                        </Stack>
                      );
                    })
                  : null}
              </>
            </Box>
          </CardContent>

          <Collapse in={expanded} timeout="auto" unmountOnExit></Collapse>
        </Card>
      </Box>
      <Box
        sx={[
          webStyles.flexBox,
          {
            width: "100%",
            paddingInline: { xs: "0xp", sm: "22px", md: "66px" },
            flexDirection: { xs: "column", md: "row" },
          },
        ]}
      >
        <Box mt="1%" sx={{ flex: 1 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              fontWeight={"700"}
              sx={{
                fontFamily: "Helvetica Neue",
                fontSize: { xs: "16px", md: "20px" },
                lineHeight: { xs: "18px", md: "24px" },
                mt: { xs: 1, md: 2.5 },
                mb: { xs: 1, md: 2.5 },
                ml: { xs: 1.5 },
              }}
            >
              Results{" "}
              {getDataCount?.hits
                ? getDataCount?.hits?.toLocaleString("en-US")
                : ""}
            </Typography>
            {/* <IconButton
              sx={{
                display: {
                  xs: "initial",
                  sm: "initial",
                  md: "none",
                },
                mr: "15px",
              }}
              onClick={() => {
                setFilterDrawer(true);
              }}
              size="small"
              color="primary"
            >
              <FilterAlt />
            </IconButton> */}
            <Button
              // variant="contained"
              variant="outlined"
              endIcon={<ArrowDropDownIcon />}
              onClick={() => {
                setFilterDrawer(true);
              }}
              sx={{
                marginRight: "15px",
                display: {
                  xs: "flex",
                  sm: "flex",
                  md: "none",
                },
              }}
            >
              Browse Issues
            </Button>
          </Box>
          <Box
            sx={{
              backgroundColor: "#ffffff",
              borderWidth: 1,
              borderColor: "#D1D5DB",
              borderStyle: "solid",
              borderRadius: "12px",
              overflow: "hidden",
              padding: { xs: 1, md: 3 },
              display: {
                xs: "none",
                sm: "none",
                md: "flex",
                lg: "flex",
                xl: "flex",
              },
            }}
          >
            <Box sx={[{ marginTop: "0%" }]}>
              <Typography
                fontWeight={"700"}
                sx={{
                  fontFamily: "Lora",
                  fontSize: { xs: "16px", md: "20px" },
                  lineHeight: { xs: "18px", md: "24px" },
                  mb: { xs: 1, md: 2.5 },
                }}
              >
                Browse Issues
              </Typography>
              <FormControl
                sx={{
                  marginRight: 1,
                  marginBottom: 2,
                  //minWidth: { xs: 150, md: 250 },
                }}
              >
                <Select
                  size={isXs ? "small" : "medium"}
                  displayEmpty
                  MenuProps={MenuProps}
                  id="demo-simple-select-helper"
                  renderValue={
                    value == -1 || value == undefined ? () => "Year" : undefined
                  }
                  value={value}
                  onChange={handleChange}
                  inputProps={{ "aria-label": "Without label" }}
                  style={{ borderRadius: 8 }}
                  sx={{
                    fontSize: { xs: "16px", md: "18px" },
                    minWidth: { xs: "16em", md: "18em" },
                  }}
                >
                  {YeardropDownList?.map((year: any, index: number) => {
                    return (
                      <MenuItem
                        value={year}
                        sx={{ fontSize: { xs: "16px", md: "18px" } }}
                      >
                        {year}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl
                sx={{ marginRight: 1, marginBottom: 2, minWidth: 250 }}
              >
                {hasRequiredData && objectWithBookTitle ? (
                  <Typography
                    sx={[webStyles.headerText, { margin: 0 }]}
                    variant="h5"
                    gutterBottom
                  >
                    <Text>{objectWithBookTitle.book_title}</Text>
                  </Typography>
                ) : (
                  <Select
                    size={isXs ? "small" : "medium"}
                    displayEmpty={true}
                    renderValue={
                      // valueOne !== ("" && undefined)
                      valueOne !== "" && valueOne !== undefined
                        ? () =>
                            `${month[valueOne?.monthfrom - 1] || ""} ${
                              valueOne?.datefrom && valueOne?.datefrom !== 0
                                ? valueOne?.datefrom
                                : ""
                            } ${
                              (valueOne?.monthfrom || valueOne?.datefrom) &&
                              valueOne?.volumefrom
                                ? "- "
                                : ""
                            }${
                              valueOne?.volumefrom
                                ? `Volume ${valueOne?.volumefrom}`
                                : ""
                            }${
                              valueOne?.issuenumberfrom && valueOne?.volumefrom
                                ? ", "
                                : ""
                            }${
                              valueOne?.issuenumberfrom
                                ? `Issue ${valueOne?.issuenumberfrom}`
                                : ""
                            }${
                              valueOne?.issuenumberto
                                ? `- ${valueOne?.issuenumberto}`
                                : ""
                            }${
                              valueOne?.issuepart
                                ? `, Part ${valueOne?.issuepart}`
                                : ""
                            }`
                        : () => "Volume, Issue"
                    }
                    MenuProps={MenuProps}
                    id="demo-simple-select-helper"
                    value={valueOne}
                    onChange={handleChangeOne}
                    inputProps={{ "aria-label": "Without label" }}
                    style={{ borderRadius: 8 }}
                    sx={{
                      fontSize: { xs: "16px", md: "18px" },
                      minWidth: { xs: "16em", md: "18em" },
                    }}
                  >
                    {/* {volume[0]?.map(() => {
                  <Text>{objectWithBookTitle.book_title}</Text>
                  </Typography> :  <Select
                  // size="small"
                  displayEmpty={true}
                  renderValue={
                    volumeDetailsTOC == "" ? () => "Volume, Issue" : undefined
                  }
                  MenuProps={MenuProps}
                  id="demo-simple-select-helper"
                  value={volumeDetailsTOC}
                  onChange={handleChangeOne}
                  inputProps={{ "aria-label": "Without label" }}
                  style={{ borderRadius: 8, minWidth: "23em" }}
                >
                  {/* {volume[0]?.map(() => {
                return (
                  <MenuItem value={"Containing"}>
                    March 2 - Volume 55, Issue 3
                  </MenuItem>
                );
              })} */}

                    {volumeDropDownList?.map((e: any, i: any) => {
                      return (
                        <MenuItem
                          value={e}
                          sx={{ fontSize: { xs: "16px", md: "18px" } }}
                        >
                          {month[e?.monthfrom - 1]}{" "}
                          {e?.datefrom && e?.datefrom !== 0 ? e?.datefrom : ""}
                          {(e?.monthfrom || e?.datefrom) && e?.volumefrom
                            ? "- "
                            : ""}
                          {e?.volumefrom ? `Volume ${e?.volumefrom}` : ""}
                          {e?.issuenumberfrom && e?.volumefrom ? ", " : ""}
                          {e?.issuenumberfrom && e?.datefrom && !e?.volumefrom
                            ? ", "
                            : ""}
                          {e?.issuenumberfrom
                            ? `Issue ${e?.issuenumberfrom}`
                            : ""}
                          {e?.issuenumberto ? `- ${e?.issuenumberto}` : ""}
                          {e?.issuepart ? `, Part ${e?.issuepart}` : ""}
                        </MenuItem>
                      );
                    })}
                  </Select>
                )}
              </FormControl>
            </Box>
          </Box>
        </Box>
        <Box mt="1%" sx={{ ml: { xs: 0, md: 2.5 }, flex: 6 }}>
          <Box mt="1%" mb="1%" sx={[webStyles.flexBox]}>
            <Checkbox
              onChange={selectAllArticles}
              checked={markResult}
              inputProps={{ "aria-label": "controlled" }}
            ></Checkbox>
            <Typography
              style={{
                alignSelf: "center",
                marginRight: "4%",
                color: Colors.primary,
                fontSize: "0.875rem",
                cursor: "pointer",
              }}
              onClick={() => {
                if (!markedData.length) return;
                navigate("/marked-result");
              }}
            >
              {markedData?.length ? `View Marked Results` : "Mark Results"}
              {markedData?.length > 0 ? "(" + markedData?.length + ")" : ""}
            </Typography>

            {details?.is_openaccess != true &&
              details?.openaccess_type !== "Open Access" && (
                <FormGroup>
                  <FormControlLabel
                    onClick={() => {
                      //usageReport("89");
                      //logTOCUsageData(89);
                      selectedTab == "all"
                        ? setSelectedTab("full-text")
                        : setSelectedTab("all");
                      logTOCUsageData(89, details);
                    }}
                    sx={[webStyles.checkBox, { color: Colors.black }]}
                    control={<Checkbox />}
                    label="Limit to Full Text"
                  />
                </FormGroup>
              )}
          </Box>
          {getDataList?.map((data: any, i: number) => {
            let authorDetails = Array.isArray(data?.author_details)
              ? data?.author_details
              : Array.isArray(data?.authors)
              ? data?.authors
              : Array.isArray(data?.speakers)
              ? data?.speakers
              : [];
            return (
              <CardItem
                index={i}
                setJournalResults={setJournalResults}
                data={data}
                getMarkedResult={getMarkedResult}
                markedArticlesid={markedData}
                searchTerm={
                  searchItem?.length == 0
                    ? details?.resource_name_tk
                    : searchItem.toString()
                }
                resultFrom="BrowseJournalTableSearch/BrowseJournalSearch"
              >
                {/***********************************************************************************************************************************************  
                Title Section
              ************************************************************************************************************************************************/}
                <CardContent sx={{ padding: "0px 14px" }}>
                  <MathJax>
                    <Text
                      onClick={() => handleAbstractView(data)}
                      fontWeight={{ xs: 700, sm: 600 }}
                      //fontSize={"1.125rem"}
                      fontFamily={"Lora"}
                      sx={{
                        textAlign: "left",
                        color: "#1A1A1A",
                        cursor: "pointer",
                        fontSize: { xs: ".9rem", sm: "1.125rem" },
                      }}
                    >
                      <div
                        id="title"
                        dangerouslySetInnerHTML={{
                          __html: Array.isArray(searchTerm)
                            ? keywordHighlight(
                                tocGetDataList,
                                searchTerm,
                                data?.article_id,
                                data?.title
                              )
                            : data?.title,
                        }}
                      />
                    </Text>
                  </MathJax>
                </CardContent>

                {/***********************************************************************************************************************************************
                Author Details
              ***********************************************************************************************************************************************/}
                {data?.data_type == dataTypes.journalArticle ||
                data?.data_type == dataTypes.journals ||
                data?.data_type === dataTypes.bookSeries ||
                data?.data_type == dataTypes.dissertationThesis ||
                data?.data_type == dataTypes.preprint ||
                data?.data_type == dataTypes.conferenceProceedings ? (
                  <CardContent sx={{ display: "flex", padding: "0 14px" }}>
                    {Array.isArray(data?.author_details) &&
                      !isStringOfArray(data?.author_details) &&
                      sortAuthorDetail(data?.author_details)?.map(
                        (obj: any, i) => {
                          let name =
                            obj?.author_fname + " " + obj?.author_lname;

                          const components = [
                            obj?.author_lname,
                            obj?.author_fname,
                          ].filter(Boolean);
                          let reversedName = components.join(", ");

                          if (i > (isXs ? 1 : 3)) return null;
                          return (
                            <Box
                              display={"flex"}
                              flexDirection={"row"}
                              justifyContent={"space-between"}
                              alignItems={"center"}
                            >
                              {i < (isXs ? 1 : 3) ? (
                                <>
                                  <Button
                                    // onClick={(e) => {
                                    //   handleAuthor(reversedName, data);
                                    // }}
                                    sx={{
                                      color: "black",
                                      fontFamily: "Helvetica Neue",
                                      fontSize: {
                                        xs: "14px",
                                        sm: "14px",
                                      },
                                      textDecoration: "underline",
                                      padding:
                                        i === 0 ? "8px 0px 6px 0px" : "6px 8px",
                                    }}
                                  >
                                    {reversedName}
                                  </Button>
                                  {obj?.orc_id ? (
                                    <a
                                      href={`https://orcid.org/${obj?.orc_id}`}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <img
                                        src={IdIcon}
                                        style={{
                                          paddingLeft: "5px",
                                          marginTop: "8px",
                                          paddingRight: "6px",
                                        }}
                                        alt="IdIcon"
                                      />
                                    </a>
                                  ) : null}
                                  {reversedName !== "" ? (
                                    <Divider
                                      orientation="vertical"
                                      style={{
                                        height: "60%",
                                        alignSelf: "center",
                                      }}
                                    />
                                  ) : null}
                                </>
                              ) : (
                                <Button
                                  sx={{
                                    color: "#6B7280",
                                    textDecoration: "underline",
                                    fontSize: { xs: "14px", sm: "14px" },
                                  }}
                                  onClick={() => handleDawer(data)}
                                >
                                  +{data.authors?.length - (isXs ? 1 : 3)} More
                                </Button>
                              )}
                            </Box>
                          );
                        }
                      )}
                    {Array.isArray(data?.author_details) &&
                      isStringOfArray(data?.author_details) &&
                      sortAuthor(data?.author_details)?.map(
                        (name: string, i: number) => {
                          if (i > (isXs ? 1 : 3)) return null;
                          return (
                            <Box
                              display={"flex"}
                              flexDirection={"row"}
                              justifyContent={"space-between"}
                            >
                              {i < (isXs ? 1 : 3) ? (
                                <>
                                  {name?.length ? (
                                    <Button
                                      onClick={() => handleAuthor(name, data)}
                                      sx={{
                                        fontFamily: "Helvetica Neue",
                                        fontSize: {
                                          xs: "14px",
                                          sm: "14px",
                                        },
                                        textDecoration: "underline",
                                        cursor: "auto",
                                        padding:
                                          i === 0
                                            ? "8px 8px 6px 0px"
                                            : "6px 8px",
                                      }}
                                    >
                                      {name.length
                                        ? name
                                            .trim()
                                            .replace(/^\,+|\,+$/g, "")
                                            .trim()
                                        : ""}{" "}
                                    </Button>
                                  ) : null}

                                  <Divider
                                    orientation="vertical"
                                    style={{
                                      height: "60%",
                                      alignSelf: "center",
                                    }}
                                  />
                                </>
                              ) : (
                                <Button
                                  sx={{
                                    color: "#6B7280",
                                    textDecoration: "underline",
                                  }}
                                  onClick={() => handleDawer(data)}
                                >
                                  +
                                  {data.authors
                                    ? data.authors?.length - (isXs ? 1 : 3)
                                    : data.author_details?.length -
                                      (isXs ? 1 : 3)}{" "}
                                  More
                                </Button>
                              )}
                            </Box>
                          );
                        }
                      )}
                  </CardContent>
                ) : null}
                {/***********************************************************************************************************************************************
                Journal name, Data, Year  Section
              ***********************************************************************************************************************************************/}
                {data?.data_type == dataTypes.journalArticle ||
                data?.data_type == dataTypes.journals ||
                data?.data_type === dataTypes.bookSeries ||
                data?.data_type == dataTypes.dissertationThesis ||
                data?.data_type == dataTypes.preprint ||
                data?.data_type == dataTypes.conferenceProceedings ||
                data?.data_type == dataTypes.audioVideo ? (
                  <CardContent
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      padding: "4px 14px",
                    }}
                  >
                    {/* {dataType.includes(2) ||
                    dataType.includes(4) ||
                    dataType.includes(8) ? null : (
                      <Box pr={"0.8rem"}>
                        <Tooltip title="Set Alert">
                          <RssFeedIcon fontSize="small"></RssFeedIcon>
                        </Tooltip>
                      </Box>
                    )} */}

                    <Text
                      sx={{
                        textAlign: "left",
                        fontSize: { xs: "14px", sm: "16px" },
                        // color: Colors.primary,
                        fontStyle: "italic",
                        // cursor: "pointer",
                      }}
                      // onClick={() => handleResource && handleResource(data)}
                    >
                      {metaData(data, isXs)}
                    </Text>
                  </CardContent>
                ) : null}

                <CardActions
                  disableSpacing
                  style={{ justifyContent: "space-between" }}
                  sx={{ padding: "4px 14px" }}
                >
                  <Stack direction="row" spacing={2}>
                    <Stack direction={"row"} gap={1}>
                      <Button
                        onClick={() => {
                          isXs ? handleAbstractView(data) : handleDawer(data);
                        }}
                        size="small"
                        sx={{
                          display: { xs: "none", sm: "flex" },
                          textDecoration: "underline",
                          color: "#000",
                          fontSize: { xs: "11px", sm: "14px" },
                        }}
                      >
                        Read More
                      </Button>

                      {data?.is_retracted &&
                      !(data?.is_onos_resource === true && isEnableONOS) ? (
                        <Tooltip title="Retracted Article">
                          <img src={RetractedIcon} alt="RetractedIcon" />
                        </Tooltip>
                      ) : (
                        ""
                      )}
                      {/* 
                    <Button
                    // onClick={async () => {
                    //   let dataToDisplay = await findInLibraryApi(
                    //     data
                    //   );
                    //   console.log("dataToDisplay", dataToDisplay);

                    //   navigate("findlibrary", {
                    //     state: { dataToDisplay },
                    //   });
                    // }}
                    >
                      Find In Article
                    </Button>

                    <Button
                    // onClick={async () => {
                    //   navigate("DdrScreen", {
                    //     state: { data },
                    //   });
                    // }}
                    >
                      DDR
                    </Button> */}
                    </Stack>
                  </Stack>
                  <Box display={"flex"} gap={1}>
                    {getButtonType(data?.article_id)?.includes(
                      "find_in_library"
                    ) &&
                      !data?.fulltext &&
                      (data?.data_type === dataTypes.journalArticle ||
                        data.data_type === dataTypes.conferenceProceedings ||
                        data.data_type === dataTypes.journals) &&
                      !(data?.is_onos_resource === true && isEnableONOS) && (
                        <Tooltip title="Find in Library">
                          <Button
                            sx={{ minWidth: 0 }}
                            onClick={async () => {
                              navigate("findlibrary", {
                                state: { data },
                              });
                              logTOCUsageData(38, data);
                            }}
                          >
                            <img
                              src={FindInLibraryIcon}
                              alt="FindInLibraryIcon"
                            />
                          </Button>
                        </Tooltip>
                      )}
                    {data?.pmcid ? (
                      <Button
                        href={`https://www.ncbi.nlm.nih.gov/pmc/articles/${data?.pmcid}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        PubMed Link
                      </Button>
                    ) : (
                      ""
                    )}

                    {/* <AccessLock
                      data={data}
                      linkToDisplay={linkToDisplay}
                      resultFrom="BrowseJournalTableSearch/BrowseJournalSearch"
                    /> */}
                    {isXs ? (
                      !data?.is_retracted ||
                      !data?.has_datasets ||
                      !data?.pmcid ? (
                        <AccessLock
                          data={data}
                          linkToDisplay={linkToDisplay}
                          resultFrom="searchResult"
                        />
                      ) : null
                    ) : (
                      <AccessLock
                        data={data}
                        linkToDisplay={linkToDisplay}
                        resultFrom="BrowseJournalTableSearch/BrowseJournalSearch"
                      />
                    )}
                    {!data?.fulltext &&
                      !(data?.is_onos_resource === true && isEnableONOS) &&
                      getButtonType(data?.article_id)?.includes(
                        "request_article"
                      ) && (
                        <Button
                          variant="outlined"
                          onClick={async () => {
                            navigate("DdrScreen", {
                              state: {
                                data,
                                resultFrom:
                                  "BrowseJournalTableSearch/BrowseJournalSearch",
                              },
                            });
                          }}
                        >
                          Request Article
                        </Button>
                      )}
                    {!data?.fulltext &&
                      !(data?.is_onos_resource === true && isEnableONOS) &&
                      getButtonType(data?.article_id)?.includes(
                        "available_in_library"
                      ) && (
                        <Button
                          size="small"
                          onClick={() => {
                            notify(
                              "info",
                              "Please visit the library to obtain the photocopy if required. Contact your library staff for any help."
                            );
                          }}
                          sx={{
                            textDecoration: "underline",
                            color: "#000",
                          }}
                        >
                          Available in library
                        </Button>
                      )}
                  </Box>
                </CardActions>

                <Collapse timeout="auto" unmountOnExit>
                  <CardContent>
                    <Text paragraph>text</Text>
                  </CardContent>
                </Collapse>
              </CardItem>
            );
          })}
          <IconButton
            aria-label="Scroll to Top"
            sx={{
              position: "fixed",
              // top: isBottom ? "50px" : "290px",
              top: "290px",
              right: "0.2vw",
              transform: "translateY(-50%)",
              border: "1px solid #D1D5DB",
              backgroundColor: "#FFF",
              color: "primary.main",
              "&:hover": {
                border: "1px solid #D1D5DB",
                backgroundColor: "#FFF",
                color: "primary.main",
                boxShadow: 2,
              },
            }}
            onClick={() => goToDown()}
          >
            <ArrowDownwardIcon />
          </IconButton>
          <IconButton
            aria-label="Scroll to Top"
            sx={{
              position: "fixed",
              top: "63vh",
              right: "0.2vw",
              transform: "translateY(-50%)",
              border: "1px solid #D1D5DB",
              backgroundColor: "#FFF",
              color: "primary.main",
              "&:hover": {
                border: "1px solid #D1D5DB",
                backgroundColor: "#FFF",
                color: "primary.main",
                boxShadow: 2,
              },
            }}
            onClick={() => goToTop()}
          >
            <ArrowUpwardIcon />
          </IconButton>
        </Box>
      </Box>
      <RightDrawer
        dialogueData={dialogueData}
        handleDawer={handleDawer}
        state={top}
        linkToDisplay={linkToDisplay}
        authorEmailData={authorEmailData}
        setTop={setTop}
        resultFrom="BrowseJournalTableSearch/BrowseJournalSearch"
      />
      <JournalViewDetails
        state={viewDetails}
        setViewDetails={setViewDetails}
        handleDawer={handleViewDrawer}
        details={details}
        journalHistory={journalHistory}
        setDetails={setDetails}
        setYearDropDownList={setYearDropDownList}
        setValue={setValue}
        setValueOne={setValueOne}
        setVolumeDropDownList={setVolumeDropDownList}
        setLinkToDisplay={setLinkToDisplay}
      ></JournalViewDetails>

      <Box my={3} sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Pagination
          count={count}
          handlePageChange={handlePageChange}
          rowsPerResult={rows}
          getRowsPerPage={getRowsPerPage}
          currentPage={currentPage}
        />
        {/* <Spinner isLoading={isLoading} /> */}
      </Box>
      <SJRModal
        isOpen={isOpen}
        handleClose={closeSjrModal}
        sjrId={details?.sjr_id}
      />
      <RSSAlert
        setJournalResults={setJournalResults}
        alerts={alerts}
        openViewModal={cite}
        handleCloseViewModal={handleCite}
        setNotification_set={setNotification_set}
        setMessage={setMessage}
        id={details}
        basicSearch={basicSearch}
        notification={notification}
      ></RSSAlert>
      <NoResultModal isOpen={noresultModal} handleClose={closeNoResultModal} />

      <Spinner isLoading={isLoading} />

      {isMobile && (
        <Drawer
          open={true}
          onClose={() => setFilterDrawer(false)}
          anchor="left"
          sx={{
            display: FilterDrawer ? "block" : "none",
            "& .MuiDrawer-paper": {
              width: { xs: "85%", sm: "60%" },
            },
          }}
          ModalProps={{
            disableScrollLock: true,
          }}
        >
          <Box bgcolor={"#FFF"} width={"100%"}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
                alignSelf: "center",
                padding: "1%",
              }}
            >
              <Typography
                variant="h6"
                fontFamily={"Lora"}
                fontWeight={"700"}
                fontSize={"24px"}
              >
                Filters
              </Typography>
              <IconButton
                aria-label="delete"
                size="medium"
                onClick={() => {
                  setFilterDrawer(false);
                }}
              >
                <Clear />
              </IconButton>
            </Box>
          </Box>
          <Divider></Divider>
          <BrowseIssues
            value={value}
            valueOne={valueOne}
            handleChange={handleChange}
            handleChangeOne={handleChangeOne}
            YeardropDownList={YeardropDownList}
            volumeDropDownList={volumeDropDownList}
            hasRequiredData={hasRequiredData}
            objectWithBookTitle={objectWithBookTitle}
            month={month}
            MenuProps={MenuProps}
          />
        </Drawer>
      )}
      <Snackbar
        open={message.open}
        autoHideDuration={1000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert severity="warning" sx={{ width: "100%" }}>
          {message.messages}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default BrowseJournalSearch;

const webStyles = {
  button: {
    margin: "0.5%",
  },
  flexCol: {
    display: "flex",
    flexDirection: "column",
  },
  flexBox: {
    display: "flex",
    flexDirection: "row",
  },
  mainContainer: {
    flex: 1,
  },
  headerText: {
    fontFamily: "Lora",
    color: Colors.primary,
    fontWeight: 700,
    fontSize: { xs: "16px", md: "24px" },
  },
  subText: {
    fontFamily: "Helvetica Neue",
    fontWeight: 700,
    fontSize: { xs: "0.8rem", md: "0.9rem" },
  },
  checkBox: {
    fontSize: 14,
    color: Colors.primary,
    fontFmaily: "Helvetica Neue",
    fontWeight: 400,
  },
  buttonOne: {
    marginLeft: "0.9%",
    padding: { xs: "0.4rem", md: "0.7rem" },
    width: "10%",
    minWidth: { xs: "64px", md: "128px" },
    fontWeight: "500",
  },
  inputField: {
    // width: "77%",
    marginRight: "1%",
    borderRadius: 60,
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: Colors.coolGray300,
        borderRadius: 2,
      },
    },
    fontFamily: "Helvetica Neue",
    fontSize: { xs: "0.75rem", md: "0.875rem" },
  },
  flexRow: {
    display: "flex",
    flexDirection: { xs: "column", sm: "row" },
    // backgroundColor: "red",
  },
  textResult: {
    fontFamily: "Lora",
    fontWeight: "700",
    // fontSize: 24,
    color: Colors.black,
    alignSelf: "center",
    backgroundColor: "green",
  },
  peerReviewed: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: { xs: 12, md: 14 },
    color: "#1A1A1A",
  },
  clearallText: {
    // fontFamily: "Helvetica Neue",
    // fontWeight: "500",
    // color: Colors.primary,
    // fontSize: 14,
    // alignSelf: "center",
    height: 22,
    // textTransform: "none",
  },
  searchTextFont: {
    fontFamily: "Inter",
    fontWeight: 500,
    color: Colors.gray900,
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    borderColor: Colors.gray_400,
    marginLeft: 1,
    textTransform: "none",
    paddingTop: 0,
    paddingBottom: 0,
    minHeight: 22,
    borderRadius: "6px",
    //  fontSize:"0.875rem"
  },
  gridStyle: {
    position: "absolute",
    width: "330px",
    // height: "118px",
    top: "5px",
    marginTop: "50px",
    background: "#FFFFFF",
    border: "1px dashed rgba(33, 33, 33, 0.1)",
    boxShadow: "4px 4px 20px rgba(0, 0, 0, 0.06)",
    borderRadius: "6px",
    dispay: "flex",
    justifyContent: "flex-end",
    zIndex: "100000",
  },
};
