import React, { useEffect, useState } from "react";

import { Box } from "@mui/system";
import {
  Button,
  Collapse,
  Divider,
  Grid,
  IconButton,
  IconButtonProps,
  styled,
  Drawer,
  Tooltip,
  Typography,
} from "@mui/material";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import RssFeedIcon from "@mui/icons-material/RssFeed";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { Colors } from "../../utils/constants";
import { useNavigate } from "react-router-dom";
import StarIcon from "@mui/icons-material/Star";
import { useDispatch } from "react-redux";
import { alertsAndFav } from "../../store/slice/browseJournal";
import { useSelector } from "react-redux";
import {
  journalLock,
  markFav,
  resourceSettingAlert,
  setFav,
} from "../../api/browseJournal";
import CiteArticle from "../resultsPopups/CiteArticle";
import RSSAlert from "../../components/resultsPopups/RSSAlert";
import { accessTypeButtons, showIsProfileToast } from "../../utils/helper";
// import DownloadButton from "../../components/downloadButton/downloadButton";
// import RedOpenLock from "../../components/downloadButton/RedlockOpen";
// import RedLockClosed from "../../components/downloadButton/RedLockClosed";
import DownloadButtonJournal from "../downloadButtonBrowse/downloadButtonJournal";
import RedLockClosedJournal from "../downloadButtonBrowse/RedLockClosedJournal";
import RedOpenLockJournal from "../downloadButtonBrowse/RedlockOpenJournal";
import { useAppSelector } from "../../store/hooks";
import { LinkResolverType } from "../../api/linkResolverApi";
import { updateBrowserPage } from "../../store/slice/browseJournal";
// import AccessLock from "../basicSearchScreen/AccessLock";
// import AccessLock as access  from "../../screens/basicSearchScreen/AccessLock";
import RSSIcon from "@mui/icons-material/RssFeed";
import { useAddTOC } from "../../screens/MyLibrary/MyAlerts/AddTOC.hook";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import objectToQueryString from "../../screens/browseJournal/objToQuery";
import SJRModal from "../rightDrawer/SJRModal";
import { usagelogApi } from "../../api/usageReportApi";
import { MathJax } from "better-react-mathjax";
import highlightKeyword from "../../utils/highlightKeyword";
import GrayOpenLock from "../downloadButtonBrowse/GrayOpenLock";
import DownloadONOSButton from "../downloadButtonBrowse/DownloadONOSButton";
import JournalViewDetails from "../rightDrawer/journalViewDetails";
import axios from "axios";
import api from "../../api/endpoints";
import { Clear } from "@mui/icons-material";

interface P {
  item: {
    resource_name: string;
  };
}
let userId = sessionStorage.getItem("user_id");

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const BrowserJournalBlocks: React.FC<any> = ({
  setJournalResults,
  index,
  journalSearchResults,
  setJournalSearchResults,
  item,
  alerts,
  loginDetails,
  setMessage,
  lock,
  gridDisplay,
  setGridDisplay,
  gridId,
  setGridId,
  setIsOpen,
  isOpen,
  closeSjrModal,
  setSjrId,
}) => {
  const [cite, setCite] = useState(false);
  const [isProfileUser, setIsProfileUser] = useState(false);

  const [expanded, setExpanded] = React.useState(false);
  const [viewDetails, setViewDetails] = useState(false);
  const [journalHistory, setJournalHistory] = React.useState([]);
  const [details, setDetails] = useState(item);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const alertUpdate: any = useSelector(
    (state: any) => state.browseJournalSearch.browserResult
  );
  const customerId = useSelector(
    (state: any) => state?.customer?.customerDetails?.informaticscustomer_id
  );
  const alertsAndFavs = useSelector(
    (state: any) => state?.browseJournalSearch?.alertFav[0]
  );
  const consortiamasterId = useSelector(
    (state: any) => state?.customer?.customerDetails?.consortiamaster_id
  );

  const profileData = useAppSelector((state) => state.login);

  const profileData1 = useAppSelector(
    (state) => state.customer.customerDetails
  );
  const currentYear: number = new Date().getFullYear();
  var regex = /<\/?[^>]+(>|$)/g;

  const selectedFilters: any = useSelector(
    (state: any) => state.browseFilterSlice
  );

  const is_ONOSCustomer = useSelector(
    (state: any) => state.customer.customerDetails?.is_enable_onos
  );

  const contextId =
    selectedFilters.collectionsValueJournal === "J-GateCollection"
      ? 1
      : selectedFilters.collectionsValueJournal === "Consortia Collections"
      ? 2
      : selectedFilters.collectionsValueJournal === "My Library Collections"
      ? 3
      : selectedFilters.collectionsValueJournal ===
        "MyPersonalLibraryCollection"
      ? 4
      : selectedFilters.collectionsValueJournal === "ONOS Collections"
      ? 5
      : selectedFilters.collectionsValueJournal === "Library OPAC"
      ? 6
      : selectedFilters.collectionsValueJournal === "Open Access Collections"
      ? 7
      : null;

  const logJournalFinderAtoZJournalUsageData = (
    usageActionID: any,
    details?: any
  ) => {
    const userId = sessionStorage.getItem("user_id");
    const informaticscustomer_Id = sessionStorage.getItem(
      "informaticscustomer_id"
    );
    const articleArticle_Id = details?.article_id ? details?.article_id : null;
    const articleResourcemaster_id = details?.resourcemaster_id
      ? details?.resourcemaster_id
      : null;
    const articleResourceissue_id = details?.resourceissue_id
      ? details?.resourceissue_id
      : null;
    const publisherId = details?.publisher_id ? details?.publisher_id[0] : null;
    const user_ipv4_address =
      sessionStorage.getItem("user_ipv4_address") || null;

    const userMasterId = profileData.user_id
      ? profileData.user_id
      : userId
      ? userId
      : null;
    const informaticscustomerId = profileData.informaticscustomer_id
      ? profileData.informaticscustomer_id
      : informaticscustomer_Id
      ? informaticscustomer_Id
      : null;
    const consortiamasterId =
      profileData1 && profileData1.consortiamaster_id
        ? profileData1.consortiamaster_id
        : null;
    const sessionKey = profileData.session_key ? profileData.session_key : null;
    const isArticleONOS = details?.is_onos_resource;
    const isONOS =
      is_ONOSCustomer &&
      (selectedFilters.collectionsValueJournal === "ONOS Collections" ||
        isArticleONOS)
        ? 1
        : 0;
    usagelogApi(
      userMasterId,
      informaticscustomerId,
      consortiamasterId,
      usageActionID,
      contextId,
      null,
      articleArticle_Id,
      articleResourceissue_id,
      publisherId,
      articleResourcemaster_id,
      // profileData.ip_v4,
      user_ipv4_address,
      null,
      sessionKey,
      1,
      null,
      isONOS
    );
  };

  const [gridData, setGridData] = useState([]);
  const handleCite = async () => {
    setCite(!cite);

    // let response = await citeArticle();
  };
  useEffect(() => {
    let userId = sessionStorage.getItem("isProfileUser");
    if (userId == "true") {
      setIsProfileUser(true);
    } else if (userId == "false") {
      setIsProfileUser(false);
    }
  }, []);

  const fav = async (ale: any) => {
    let response = await markFav(item.resourcemaster_id, false, loginDetails);

    if (response) {
      let x = alertsAndFavs?.map((ele: any, index: number) => {
        if (ele?.resource_id === ale?.resource_id) {
          return { ...ele, favorite_set: !ale?.favorite_set };
        } else {
          return ele;
        }
      });
      dispatch(alertsAndFav([x]));

      let resourceIdList: string[] = [];
      alertUpdate.docs?.map((element: any) => {
        resourceIdList.push(element.resourcemaster_id);
      });

      // setJournalResults();
    }
  };

  const removeFav = async (ale: any) => {
    let response = await markFav(item.resourcemaster_id, true, loginDetails);

    if (response) {
      let x = alertsAndFavs?.map((ele: any, index: number) => {
        if (ele?.resource_id === ale?.resource_id) {
          return { ...ele, favorite_set: !ale?.favorite_set };
        } else {
          return ele;
        }
      });
      dispatch(alertsAndFav([x]));

      let resourceIdList: string[] = [];
      alertUpdate.docs?.map((element: any) => {
        resourceIdList.push(element.resourcemaster_id);
      });
      // setJournalResults();
    }
  };

  const getJornalHistory = async (item: any) => {
    const resourcemasterID = item?.resourcemaster_id;
    try {
      let response = await axios.get(
        api.baseApi +
          `/api/get_resource_history/get_resource_history?resource_id=${resourcemasterID}`
      );
      setJournalHistory(response.data.data[0][0]);
    } catch (err) {
      console.log(err);
    }
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleViewDrawer = (data: any) => {
    setViewDetails(!viewDetails);
  };

  return (
    <>
      <Box sx={webStyles.container}>
        <Box
          sx={webStyles.flexBox}
          onClick={() => {
            const yearData = Array.isArray(item.year_from)
              ? item.year_from[0]
              : item.year_from;
            if (currentYear === yearData) {
              logJournalFinderAtoZJournalUsageData(90, item);
            } else {
              logJournalFinderAtoZJournalUsageData(87, item);
            }

            logJournalFinderAtoZJournalUsageData(92, item);
            dispatch(updateBrowserPage(1));
            navigate("/BrowseJournalTableSearch/BrowseJournalSearch", {
              state: {
                item: item,
                alerts: alerts,
                lock: lock,
                from: "BrowseJournalTableSearch",
              },
            });
          }}
        >
          <Box style={{ width: "90%" }}>
            <Typography
              gutterBottom
              sx={[
                webStyles.text,
                { fontSize: "1.3rem", fontFamily: "Lora", fontWeight: 600 },
              ]}
            >
              <MathJax>
                <div
                  id="title"
                  dangerouslySetInnerHTML={{
                    __html: highlightKeyword(item.resource_name, []),
                  }}
                />
              </MathJax>
            </Typography>
            <Typography gutterBottom sx={webStyles.subText}>
              {/* {authorName} */}
              {item.publisher_name}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "baseline" }}>
            {alerts?.favorite_set ? (
              <Tooltip title="Remove Favourite">
                <IconButton
                  style={{ color: "#FFC000" }}
                  onClick={async (e) => {
                    e.stopPropagation();
                    logJournalFinderAtoZJournalUsageData(111, item);

                    isProfileUser
                      ? fav(alerts)
                      : showIsProfileToast("Please login as profile user");
                  }}
                >
                  <StarIcon></StarIcon>
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Set Favourite">
                <IconButton
                  onClick={async (e) => {
                    e.stopPropagation();
                    logJournalFinderAtoZJournalUsageData(111, item);
                    isProfileUser
                      ? removeFav(alerts)
                      : showIsProfileToast("Please login as profile user");
                  }}
                >
                  <StarOutlineIcon></StarOutlineIcon>
                </IconButton>
              </Tooltip>
            )}

            {/* {alerts?.notification_set ? (
            <IconButton
              style={{ color: "red" }}
              onClick={async (e) => {
                e.stopPropagation();
                handleCite();
              }}
            >
              <RssFeedIcon></RssFeedIcon>
            </IconButton>
          ) : (
            <IconButton
              onClick={async (e) => {
                e.stopPropagation();

                handleCite();
              }}
            >
              <RssFeedIcon></RssFeedIcon>
            </IconButton>
          )} */}
            <Tooltip
              title={alerts?.notification_set ? "Modify Alert" : "Set Alert"}
            >
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  isProfileUser
                    ? handleCite()
                    : showIsProfileToast(
                        "Please login as profile user to set alert"
                      );

                  // handleCite();
                }}
                aria-label="rss"
                sx={{
                  color: alerts?.notification_set ? "#F48120" : "#6E6E6F",
                }}
              >
                <RSSIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
        <Box sx={[webStyles.flexBox]}>
          <div style={{ display: "flex" }}>
            {item.primary_publisher_country && (
              <Box sx={[webStyles.flexBox]}>
                <Typography
                  sx={{
                    whiteSpace: "nowrap",
                    margin: "auto",
                    fontSize: "0.9rem",
                    fontWeight: 700,
                    alignSelf: "center",
                    fontFamily: "Helvetica Neue",
                  }}
                >
                  {/* {country} */}
                  {item.primary_publisher_country}&nbsp;
                </Typography>
                &nbsp;
              </Box>
            )}

            {item?.sjr_score ? (
              <Box sx={[webStyles.flexBox]}>
                <Divider
                  orientation="vertical"
                  style={{
                    height: "50%",
                    alignSelf: "center",
                    margin: "0% 1%",
                  }}
                />
                <Typography
                  variant="body2"
                  sx={{ whiteSpace: "nowrap", margin: "auto" }}
                >
                  {/* {item.filter_metrix[0]}: */}
                  SJR :&nbsp;
                  <a
                    onClick={(e) => {
                      e.stopPropagation();
                      setSjrId(item?.sjr_id);
                      setIsOpen(true);
                    }}
                    // href={`https://www.scimagojr.com/journalsearch.php?q=${item?.sjr_id}&amp;tip=sid&amp;exact=no`}
                    title="SCImago Journal &amp; Country Rank"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: Colors.primary }}
                  >
                    {item?.sjr_score}&nbsp;
                  </a>
                </Typography>
                {/* <Typography
                  variant="body2"
                  style={{ marginRight: "0.5rem", color: Colors.primary }}
                > */}
                {/* </Typography> */}
                {/* <Divider
                orientation="vertical"
                style={{
                  height: "50%",
                  alignSelf: "center",
                  margin: "0% 1%",
                }}
              /> */}
                &nbsp;
              </Box>
            ) : null}

            {item?.sjr_hindex ? (
              <Box sx={[webStyles.flexBox]}>
                <Divider
                  orientation="vertical"
                  style={{
                    height: "50%",
                    alignSelf: "center",
                    margin: "0% 1%",
                  }}
                />
                <Typography
                  variant="body2"
                  sx={{ whiteSpace: "nowrap", margin: "auto" }}
                >
                  H-Index :&nbsp;
                  <span style={{ color: Colors.primary }}>
                    {item?.sjr_hindex}
                  </span>
                </Typography>
                &nbsp;
              </Box>
            ) : null}

            {consortiamasterId === 1874 && item?.naas_value ? (
              <Box sx={[webStyles.flexBox]}>
                <Divider
                  orientation="vertical"
                  style={{
                    height: "50%",
                    alignSelf: "center",
                    margin: "0% 1%",
                  }}
                />
                <Typography
                  variant="body2"
                  sx={{ whiteSpace: "nowrap", margin: "auto" }}
                >
                  NAAS :&nbsp;
                  <span style={{ color: Colors.primary }}>
                    {item?.naas_value}
                  </span>
                </Typography>
                &nbsp;
              </Box>
            ) : null}
          </div>

          <IconButton>
            <AccessLockJournal
              setGridDisplay={setGridDisplay}
              openAccess={item?.is_openaccess}
              access_type={lock?.access_type}
              GridDisplay={gridDisplay}
              resource_id={item}
              gridId={gridId}
              setGridId={setGridId}
              gridData={gridData}
              setGridData={setGridData}
            />

            {gridId !== "" && gridId == lock?.resource_id
              ? gridDisplay && (
                  <Grid sx={webStyles.gridStyle}>
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 10 }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Access Type</TableCell>
                            <TableCell align="right">Start Date</TableCell>
                            <TableCell align="right">End Date</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {gridData?.map((gridItem: any) => (
                            <TableRow
                              key={"row.name"}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell align="right">
                                {gridItem?.access_type}
                              </TableCell>

                              <TableCell align="right">
                                {gridItem?.start_date}
                              </TableCell>
                              <TableCell align="right">
                                {gridItem?.end_date}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                )
              : ""}
          </IconButton>
        </Box>
        <Box>
          <Button
            onClick={() => {
              getJornalHistory(item);
              setViewDetails(!viewDetails);
              setDetails(item);
              logJournalFinderAtoZJournalUsageData(93, details);
            }}
            sx={{ paddingLeft: "0px" }}
          >
            View Details
          </Button>
          <ExpandMore
            expand={true}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
            sx={{ color: Colors.primary, padding: 0 }}
          ></ExpandMore>
        </Box>

        <RSSAlert
          setJournalResults={setJournalResults}
          openViewModal={cite}
          handleCloseViewModal={handleCite}
          alerts={alerts}
          id={item}
          setMessage={setMessage}
        ></RSSAlert>
        <Collapse in={expanded} timeout="auto" unmountOnExit></Collapse>

        <JournalViewDetails
          state={viewDetails}
          setViewDetails={setViewDetails}
          handleDawer={handleViewDrawer}
          details={details}
          journalHistory={journalHistory}
          setDetails={setDetails}
        ></JournalViewDetails>
      </Box>
    </>
  );
};

export default BrowserJournalBlocks;

interface AccessLockType {
  data: any;
  linkToDisplay?: LinkResolverType[] | null;
}
export function AccessLock(props: AccessLockType) {
  const { data, linkToDisplay } = props;
  const customerId = useAppSelector(
    (state) => state.login.informaticscustomer_id
  );

  let greenLockUrl: string;

  if (data?.articledoi) {
    greenLockUrl = `https://doi.org/${data?.articledoi}`;
  }

  function getButtonType(article_id: string) {
    try {
      if (!article_id || !Array.isArray(linkToDisplay)) return;

      for (let obj of linkToDisplay) {
        if (obj?.article_id === article_id) {
          return obj?.button_to_display;
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  function getButtonLink() {
    try {
      if (!data?.article_id || !Array.isArray(linkToDisplay)) return;

      for (let obj of linkToDisplay) {
        if (obj?.article_id === data?.article_id) {
          return obj?.links[0];
        }
      }
    } catch (err) {
      console.log(err);
      return "";
    }
  }

  const redCloseLockLink = getButtonLink();

  function AccessButton() {
    if (!customerId) return;
    if (accessTypeButtons(data, customerId) === "GreenLock") {
      return <DownloadButtonJournal link={greenLockUrl} />;
    }
    if (accessTypeButtons(data, customerId) === "RedOpenLock") {
      return <RedOpenLockJournal />;
    }
    if (getButtonType(data?.article_id)?.includes("red_close_lock")) {
      return <RedLockClosedJournal link={redCloseLockLink} />;
    }
    if (accessTypeButtons(data, customerId) === null) {
      return null;
    }
  }

  return <>{AccessButton()}</>;
}

interface AccessLockJournalType {
  setGridDisplay: any;
  openAccess: boolean;
  access_type?: any;
  GridDisplay: boolean;
  resource_id: any;
  gridId: string;
  setGridId: any;
  gridData: any;
  setGridData: any;
}

export function AccessLockJournal(props: AccessLockJournalType) {
  const {
    openAccess,
    access_type,
    setGridDisplay,
    GridDisplay,
    resource_id,
    gridId,
    setGridId,
    setGridData,
  } = props;
  const customerId = useAppSelector(
    (state) => state.login.informaticscustomer_id
  );

  const customerIds = useSelector(
    (state: any) => state?.customer?.customerDetails?.informaticscustomer_id
  );

  const consortiamasterId = useSelector(
    (state: any) => state?.customer?.customerDetails?.consortiamaster_id
  );

  const selectedFilters = useAppSelector((state) => state.browseFilterSlice);

  const showGrid = async () => {
    const lockJson = {
      customer_id:
        customerIds || sessionStorage.getItem("informaticscustomer_id"),
      resource_id: resource_id?.resourcemaster_id,
      access_type: 2,
    };
    if (consortiamasterId !== null && consortiamasterId !== undefined)
      Object.assign(lockJson, { consortia_id: consortiamasterId });

    if (selectedFilters?.collectionsValueJournal === "My Library Collections") {
      Object.assign(lockJson, { context_id: 2 });
    }

    if (selectedFilters?.collectionsValueJournal === "Consortia Collections") {
      Object.assign(lockJson, { context_id: 3 });
    }
    let journalLockRes = await journalLock("?" + objectToQueryString(lockJson));
    setGridData(journalLockRes?.data?.data);
  };

  function AccessButtonLock() {
    if (!customerId) return;
    if (openAccess) {
      return <DownloadButtonJournal resource_id={resource_id} />;
    } else if (!openAccess && access_type?.includes("FT")) {
      return (
        <RedOpenLockJournal
          // @ts-ignore
          GridDisplay={GridDisplay}
          setGridDisplay={setGridDisplay}
          resource_id={resource_id}
          gridId={gridId}
          setGridId={setGridId}
          showGrid={showGrid}
        />
      );
    } else if (!openAccess && access_type?.includes("RA")) {
      return (
        <RedOpenLockJournal
          // @ts-ignore
          GridDisplay={GridDisplay}
          setGridDisplay={setGridDisplay}
          resource_id={resource_id}
          gridId={gridId}
          setGridId={setGridId}
          showGrid={showGrid}
        />
      );
    } else if (!openAccess && access_type?.includes("RC")) {
      return <RedLockClosedJournal />;
    } else if (!openAccess && access_type?.includes("AL")) {
      return (
        <GrayOpenLock
          GridDisplay={GridDisplay}
          setGridDisplay={setGridDisplay}
          resource_id={resource_id}
          gridId={gridId}
          setGridId={setGridId}
          showGrid={showGrid}
        />
      );
    } else if (!openAccess && access_type?.includes("FL")) {
      return (
        <GrayOpenLock
          GridDisplay={GridDisplay}
          setGridDisplay={setGridDisplay}
          resource_id={resource_id}
          gridId={gridId}
          setGridId={setGridId}
          showGrid={showGrid}
        />
      );
    } else if (!openAccess && access_type?.includes("ONOS")) {
      return <DownloadONOSButton />;
    }
  }

  return <>{AccessButtonLock()}</>;
}

const webStyles = {
  flexBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  button: {
    // margin: "0.5%",
  },
  container: {
    backgroundColor: Colors.white,
    padding: "2%",
    borderRadius: 5,
    borderWidth: 1,
    borderColor: "red",
    marginTop: "1%",
    cursor: "pointer",
  },
  text: {
    fontFamile: "Lora",
  },
  subText: {
    color: Colors.gray_600,
    fontFamily: "Helvetica Neue",
    fontSize: "0.8rem",
  },
  gridStyle: {
    position: "absolute",
    width: "330px",
    // height: "118px",
    right: "1px",
    top: "5px",
    marginTop: "50px",
    background: "#FFFFFF",
    border: "1px dashed rgba(33, 33, 33, 0.1)",
    boxShadow: "4px 4px 20px rgba(0, 0, 0, 0.06)",
    borderRadius: "6px",
    dispay: "flex",
    justifyContent: "flex-end",
    zIndex: "100000",
  },
};
