import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Text from "@mui/material/Typography";
import React, { useCallback, useEffect, useRef, useState } from "react";
import BasicSearchTopBar from "../../components/basicSearchTopBar/basicSearchTopBar";
import { Colors, authorRole, dataTypes } from "../../utils/constants";

// CARD COMPONENT
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";

import RssFeedIcon from "@mui/icons-material/RssFeed";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import SortByRelevence from "../../components/basicSearchTopBar/sortByRelevence";
import RightDrawer from "../../components/rightDrawer/rightDrawer";

import {
  Checkbox,
  Drawer,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import axios from "axios";
import { MathJax } from "better-react-mathjax";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  authorJsonFacetQuery,
  basicSearchAuthorEmail,
  basicSearchForCounts,
  basicSearchMain,
  basicSearchQuery,
  dSpaceSearchQuery,
  getDataSetDetails,
  jsonFacetQuery,
  staticAuthorFacetData,
  staticFacetData,
  staticSubjectFacetData,
  subjectJsonFacetQuery,
} from "../../api/Search/BasicSearch.api";
import {
  getPersonalLibraryCollection,
  getPersonalLibraryCollectionSRP,
  personalCoreCount,
} from "../../api/Search/CollectionApi";
import { SearchContext, setSearch } from "../../api/Search/SetSearch";
import { journalLock } from "../../api/browseJournal";
import api from "../../api/endpoints";
import { formatString, queryConstructor } from "../../api/formQuery";
import { usagelogApi } from "../../api/usageReportApi";
import { userLogout } from "../../api/userLogoutApi";
import RetractedIcon from "../../assets/img/Retracted.svg";
import DatasetIcon from "../../assets/img/database.svg";
import FindInLibraryIcon from "../../assets/img/findinlib.svg";
import IdIcon from "../../assets/img/id.png";
import PreprintIcon from "../../assets/img/preprint.svg";
import AnalyticsDropdown from "../../components/basicSearchTopBar/AnalyticsDropdown";
import CardItem from "../../components/card";
import DownloadButton from "../../components/downloadButton/downloadButton";
import GotoTopButton from "../../components/gotoTop";
import LibraryCatalogueCard from "../../components/libraryCatalogue/Card";
import NoResultModal from "../../components/modals/NoResultFound";
import Pagination from "../../components/pagination";
import RSSAlertSearch from "../../components/resultsPopups/RSSAlertSearch";
import Spinner from "../../components/spinner";
import ScrollToTop from "../../hooks/scrollToTop";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { updateQueries } from "../../store/slice/appliedQueries";
import {
  updateAuthorSearchResults,
  updateAuthorSort,
} from "../../store/slice/authorFinderSlice";
import {
  incrementCounter,
  incrementMainCounter,
  resetBotListner,
  resetCounter,
  resetTimer,
  startTimer,
} from "../../store/slice/botListner";
import {
  setSearchKeyword,
  updateBrowserPage,
} from "../../store/slice/browseJournal";
import { clearCustomerDetails } from "../../store/slice/customerDetails";
import {
  clearAllFilters,
  clearAllFiltersValue,
  clearMonthFromFilter,
  clearPublicationFilter,
  removeAllFilterType,
  spliceAllFilterArray,
  updataLibraryOpac,
  updateAllFilter,
  updateAuthor,
  updateConfrence,
  updateConsortiaCollection,
  updateCountryOfPublication,
  updateCountryOfPublishingAuthor,
  updateDateFrom,
  updateDSPACECollection,
  updateEarlyOnline,
  updateInstitution,
  updateInventor,
  updateJgateCollection,
  updateJournal,
  updateJournalRank,
  updateMonthFrom,
  updateMyLibraryCollection,
  updateOnosCollection,
  updateOpenAccess,
  updatePersonalLibrary,
  updatePublisher,
  updateResearch,
  updateResearcher,
  updateResourceId,
  updateSort,
  updateSourceType,
  updateSpeaker,
  updateSubject,
  updateUniversityName,
  updateYearFrom,
} from "../../store/slice/filterSlice";
import { logout } from "../../store/slice/loginSlice";
import { setModal } from "../../store/slice/modalSlice";
import { clearQuery, updateQuery } from "../../store/slice/queryForCountSlice";
import {
  clearSearchTerm,
  removeSearchTerm,
  reset,
  sliceSearchTerm,
  updateAdvSearchQuery,
  updateAll,
  updateAllFacets,
  updateAuthorFacets,
  updateAuthorFiltersLoader,
  updateDSpace,
  updateDataType,
  updateFullText,
  updateIsSettled,
  updateMarkedArticleCount,
  updateMarkedArticleId,
  updateMarkedResult,
  updateNewSearchStatus,
  updateOtherFiltersLoader,
  updatePage,
  updateRow,
  updateSearchResults,
  updateSearchTerm,
  updateSubjectFacets,
  updateSubjectFilterLoader,
} from "../../store/slice/searchSlice";
import {
  clearAllSetSearch,
  updateSetInNumber,
  updateSetSearchQuery,
  updateSetSearchStatus,
} from "../../store/slice/setSearch";
import { notify } from "../../utils/Notify";
import {
  accessTypeButtons,
  checkDuplicateFilter,
  getParamsByName,
  getQueryParams,
  goToTop,
  isStringOfArray,
  removeOperatorFromEnd,
  showIsProfileToast,
  sortAuthor,
  sortAuthorDetail,
  sortByAuthorRole,
} from "../../utils/helper";
import { keywordHighlight } from "../../utils/highlightKeyword";
import metaData from "../../utils/metadata";
import { onSearch as search } from "../Search/onSearch";
import {
  checkArticleExistance,
  linkToDisplay as linkToDisplayfunc,
} from "../SearchHistory/HistoryTable";
import { stringSanitizer } from "../SearchHistory/SearchHistory";
import objectToQueryString from "../browseJournal/objToQuery";
import AccessLock from "./AccessLock";
import Filters from "./Filters";
import { filterUpdate } from "../../api/redux-thunks/filterUpdate";
import { updatedStoredSelectedIssue } from "../../store/slice/browserFilterSliceDetails";
import GotoBottomButton from "../../components/gotoTop/gotoDown";
import { Clear, FilterAlt } from "@mui/icons-material";

type StateType = {
  state: {
    searchTerm: string;
    fullTextQuery: any;
    allQuery: any;
    allCount: string | number;
    fullCount: string | number;
    noRerender?: boolean;
    from?: string;
  };
};

const BasicSearchScreen: React.FC = () => {
  const navigate = useNavigate();
  const { state }: StateType = useLocation();
  const dispatch = useAppDispatch();

  const { time, counter, mainCounter } = useAppSelector(
    (state) => state.botListner
  );
  const showAlertData = useAppSelector(
    (state) => state.searchReducer?.alertSettingData
  );
  const filterReducer = useAppSelector((state) => state.filterReducer);
  const isLoading = useAppSelector((state) => state.filterReducer.loading);
  const markedArticlesid = useAppSelector(
    (state) => state.searchReducer.markedArticleId
  );
  const allAppliedQueries = useAppSelector(
    (state: any) => state?.appliedQueries.appliedQueries
  );

  const showbasicSearchData = useAppSelector(
    (state) => state.searchReducer?.searchResult?.docs
  );

  const otherFacetData: any = useAppSelector(
    (state) => state.searchReducer?.allFacets
  );

  const authorFacetData: any = useAppSelector(
    (state) => state.searchReducer?.allFacets
  );

  const [top, setTop] = useState(false);
  const [noresultModal, setNoResultModal] = useState(false);
  const [isDSpaceActive, setIsDSpaceActive] = useState(false);
  const [FilterDrawer, setFilterDrawer] = useState(false);

  const allCount = typeof state?.allCount === "number" ? state?.allCount : 0;
  const fullCount = typeof state?.fullCount === "number" ? state?.fullCount : 0;

  const queryCount = useAppSelector((state) => state.queryForCount);
  console.log("queryCount", queryCount);

  const setSearchReducer = useAppSelector((state) => state.setSearch);
  const [textCount, setTextCount] = useState({
    all: allCount,
    fulltext: fullCount,
  });
  const [markResult, setMarkResult] = useState(false);
  const [markedData, setMarkdeData] = useState<string[]>(markedArticlesid);
  const [authorEmailData, setAuthorEmailData] = useState([]);
  const linkToDisplay = useAppSelector(
    (state) => state.searchReducer.linkToDisplay
  );
  const isOpac = useAppSelector((state) => state.filterReducer.libraryOpac);
  const isDSPACE = useAppSelector((state) => state.filterReducer.dspace);
  const isOpac_dspace = useAppSelector(
    (state) => state.filterReducer.isOpac_dspace
  );

  const customerDetails = useAppSelector(
    (state) => state.customer.customerDetails
  );

  const alertTypeSetting = useAppSelector(
    (state) => state.searchReducer.alertSettingData
  );

  const isCaptchaVerified = useAppSelector(
    (state) => state.captchaVerification.isCaptchaVerified
  );
  const profileData = useAppSelector((state) => state.login);

  const profileData1 = useAppSelector(
    (state) => state.customer.customerDetails
  );
  //Get Customer Data
  const customerData = useAppSelector(
    (state) => state.customer.customerDetails
  );

  const allAppliedFilter = useAppSelector(
    (state) => state.filterReducer.allAppliedFilter
  );

  const currentYear: number = new Date().getFullYear();

  const profileStatus = sessionStorage.getItem("isProfileUser");

  const selectedFilters = useAppSelector((state) => state.filterReducer);
  const debounceTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  const counterRef = useRef<number>(0);
  const location = useLocation();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const params = new URLSearchParams();
  let [, setSearchParams] = useSearchParams();
  const searchBoxSearchTerm =
    urlParams.get("searchterm") || urlParams.get("q") || "";
  const searchId = urlParams.get("searchId") || "";
  const favId = urlParams.get("favoriteId") || "";
  const userId = useAppSelector((state) => state.login.user_id);

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("md"));
  const Xs = useMediaQuery(theme.breakpoints.down("sm"));

  const [isSearching, setIsSearching] = useState<boolean>(false);
  const isGuestUserLogin = sessionStorage.getItem("isGuestUserLogin");

  const isEnableONOS = useAppSelector(
    (state) => state.customer.customerDetails?.is_enable_onos
  );

  const contextId =
    selectedFilters.collectionValue === "J-GateCollection"
      ? 1
      : selectedFilters.collectionValue === "ConsortiaSubscriptions"
      ? 2
      : selectedFilters.collectionValue === "MyLibraryCollection"
      ? 3
      : selectedFilters.collectionValue === "MyPersonalLibraryCollection"
      ? 4
      : selectedFilters.collectionValue === "ONOSCollections"
      ? 5
      : selectedFilters.collectionValue === "Library OPAC"
      ? 6
      : selectedFilters.collectionValue === "Open Access Collections"
      ? 7
      : null;
  const logBasicSearchUsageData = (
    usageActionID: any,
    details?: any,
    origin_id?: any
  ) => {
    const userId = sessionStorage.getItem("user_id");
    const informaticscustomer_Id = sessionStorage.getItem(
      "informaticscustomer_id"
    );
    const articleArticle_Id = details?.article_id ? details?.article_id : null;
    const articleResourcemaster_id = details?.resourcemaster_id
      ? details?.resourcemaster_id
      : null;
    const articleResourceissue_id = details?.resourceissue_id
      ? details?.resourceissue_id
      : null;
    const publisherId = details?.publisher_id ? details?.publisher_id[0] : null;
    const user_ipv4_address =
      sessionStorage.getItem("user_ipv4_address") || null;

    const userMasterId = profileData.user_id
      ? profileData.user_id
      : userId
      ? userId
      : null;
    const informaticscustomerId = profileData.informaticscustomer_id
      ? profileData.informaticscustomer_id
      : informaticscustomer_Id
      ? informaticscustomer_Id
      : 0;
    const consortiamasterId =
      profileData1 && profileData1.consortiamaster_id
        ? profileData1.consortiamaster_id
        : null;
    const sessionKey = profileData.session_key ? profileData.session_key : null;
    const originId = origin_id ? origin_id : null;
    const isArticleONOS = details?.is_onos_resource;
    const isONOS =
      isEnableONOS &&
      (selectedFilters.collectionValue === "ONOSCollections" || isArticleONOS)
        ? 1
        : 0;

    usagelogApi(
      userMasterId,
      informaticscustomerId,
      consortiamasterId,
      usageActionID,
      contextId,
      originId,
      articleArticle_Id,
      articleResourceissue_id,
      publisherId,
      articleResourcemaster_id,
      // profileData.ip_v4,
      user_ipv4_address,
      null,
      sessionKey,
      1,
      null,
      isONOS
    );
  };

  const logBasicSearchUsageDataONOSFulltext = (
    usageActionID: any,
    details?: any,
    origin_id?: any
  ) => {
    const userId = sessionStorage.getItem("user_id");
    const informaticscustomer_Id = sessionStorage.getItem(
      "informaticscustomer_id"
    );
    const articleArticle_Id = details?.article_id ? details?.article_id : null;
    const articleResourcemaster_id = details?.resourcemaster_id
      ? details?.resourcemaster_id
      : null;
    const articleResourceissue_id = details?.resourceissue_id
      ? details?.resourceissue_id
      : null;
    const publisherId = details?.publisher_id ? details?.publisher_id[0] : null;
    const user_ipv4_address =
      sessionStorage.getItem("user_ipv4_address") || null;

    const userMasterId = profileData.user_id
      ? profileData.user_id
      : userId
      ? userId
      : null;
    const informaticscustomerId = profileData.informaticscustomer_id
      ? profileData.informaticscustomer_id
      : informaticscustomer_Id
      ? informaticscustomer_Id
      : 0;
    const consortiamasterId =
      profileData1 && profileData1.consortiamaster_id
        ? profileData1.consortiamaster_id
        : null;
    const sessionKey = profileData.session_key ? profileData.session_key : null;
    const originId = origin_id ? origin_id : null;
    const isArticleONOS = details?.is_onos_resource;
    const isONOS =
      isEnableONOS &&
      (selectedFilters.collectionValue === "ONOSCollections" || isArticleONOS)
        ? 1
        : 0;

    usagelogApi(
      userMasterId,
      informaticscustomerId,
      consortiamasterId,
      usageActionID,
      contextId,
      originId,
      articleArticle_Id,
      articleResourceissue_id,
      publisherId,
      articleResourcemaster_id,
      // profileData.ip_v4,
      user_ipv4_address,
      null,
      sessionKey,
      1,
      null,
      0
    );
  };

  // for DF-1808
  // useEffect(() => {
  //   showLoginModa();
  // }, [profileStatus]);

  useEffect(() => {
    showGuestLoginModa();
  }, [customerDetails?.customer_id]);

  const hasLoggedRef = useRef(false);

  useEffect(() => {
    if (location.pathname === "/search/" && !hasLoggedRef.current) {
      dispatch(updateSearchTerm(searchBoxSearchTerm.trim()));
      logBasicSearchUsageData(8, "", 8);
      logBasicSearchUsageData(11);
      hasLoggedRef.current = true;
    }
  }, [location.pathname, searchBoxSearchTerm]);

  // useEffect(() => {
  //   if (!isGuestUserLogin) {
  //     sessionStorage.removeItem("hasUpdatedSearchTerm"); // Reset on logout
  //     return;
  //   }

  //   const hasUpdated = sessionStorage.getItem("hasUpdatedSearchTerm");
  //   if (hasUpdated) return;

  //   const timeoutId = setTimeout(() => {
  //     let searchTerm = getParamsByName("searchterm") || "";

  //     if (searchTerm) {
  //       const trimmedSearchTerm = searchTerm.trim();
  //       dispatch(updateSearchTerm(trimmedSearchTerm));
  //       dispatch(
  //         updateAllFilter({
  //           key: "search-term",
  //           value: trimmedSearchTerm,
  //         })
  //       );

  //       dispatch(
  //         updateQueries({
  //           key: "searchTerm",
  //           value: trimmedSearchTerm,
  //         })
  //       );
  //     }
  //     sessionStorage.setItem("hasUpdatedSearchTerm", "true");
  //     sessionStorage.setItem("isGuestUserLogin", "false");
  //   }, 1000);

  //   return () => clearTimeout(timeoutId);
  // }, [isGuestUserLogin]);

  useEffect(() => {
    if (searchId) {
      searchAlertSearch(searchId);
    } else if (favId) {
      favIdSearch(favId);
    }
  }, []);

  const favIdSearch = async (favId: string) => {
    const res = await axios.get(
      `${api.baseApi}/api/get_search_url_by_favorite_id/get_search_url_by_favorite_id?search_id=${favId}`
    );

    handleFavSearch(
      res?.data?.data[0]?.search_url,
      res?.data?.data[0]?.subject_name
    );
  };

  const handleFavSearch = (url: string, subject: string) => {
    const getSubjectTypeValues = (data: string[]) => {
      dispatch(updateSubject(data));
      let filteredSubject = allAppliedFilter.filter(
        (obj: any) => obj?.key === "subject"
      );

      let appliedFilter: any[] = [];
      data.forEach((val) => {
        const isExist = checkDuplicateFilter(allAppliedFilter, val);
        if (!isExist) {
          appliedFilter.push({
            key: "subject",
            value: val,
          });
        }
      });

      dispatch(updateAllFilter(appliedFilter));
      dispatch(updateQueries(appliedFilter));
      dispatch(filterUpdate(filteredSubject, "subject"));
      logBasicSearchUsageData(56);
    };
    getSubjectTypeValues([subject]);
    dispatch(search("", null, true));
  };

  const handleSubjectAlerts = (url: string) => {
    const queryParams = getQueryParams(url);

    if (queryParams?.fq__resource_type) {
      let arr = queryParams?.fq__resource_type
        .replace(/[^a-zA-Z0-9\s]/g, "")
        .split(" OR ")
        ?.map((str: string) => Number(str));

      dispatch(updateDataType(arr));
    }

    if (queryParams?.fq__subjects_name_l3) {
      let subjectQuery = queryParams?.fq__subjects_name_l3
        .split("OR")
        .map((x: string) => {
          let str = x;
          str = str.trim().replace(/[()"]/g, "").replace("~_~", "&");
          return str;
        });

      // Update subject filter
      dispatch(updateSubject(subjectQuery));
      // to Update the breadcurm
      let appliedFilter: any[] = [];
      Array.isArray(subjectQuery) &&
        subjectQuery.forEach((val: string) => {
          const isExist = checkDuplicateFilter(subjectQuery, val);
          if (!isExist) {
            appliedFilter.push({
              key: "subject",
              value: val,
            });
          }
        });

      // dispatch(updateQueries(appliedFilter));
      dispatch(updateAllFilter(appliedFilter));
    }

    dispatch(search("", null, true));
  };

  const searchAlertSearch = async (searchId: string) => {
    // id from search alert and get data for search data
    const url =
      location.pathname === "/subject"
        ? `${api.baseApi}/api/get_search_query_by_subjectid/get_search_query_by_subjectid?search_id=${searchId}`
        : `${api.baseApi}/api/get_search_query_by_searchid/get_search_query_by_searchid?search_id=${searchId}`;
    const res = await axios.get(url);
    if (location.pathname === "/subject") {
      handleSubjectAlerts(res?.data?.data[0][0]?.search_url);
    } else {
      if (hasLoggedRef.current) {
        handleSearchResult(
          res?.data?.data[0][0]?.search_terms,
          res?.data?.data[0][0]?.search_url,
          res?.data?.data[0]?.search_type
        );
      } else {
        navigate(
          `/search/?q=${
            JSON.parse(res?.data?.data[0][0]?.search_terms).search_term
          }&searchId=${searchId}`
        );
      }
    }
  };

  const handleSearchResult = async (
    dirtyString: string,
    searchURL: string,
    searchType: string
  ) => {
    if (isSearching) return;

    const searchTerm = stringSanitizer(dirtyString);

    if (searchTerm.toLocaleUpperCase().includes("#S")) {
      handleSetSearchClick(searchURL);
      return;
    }
    if (!searchTerm) {
      dispatch(clearSearchTerm());
      setIsSearching(false);
      return;
    }
    const advanceSearchTerms = [
      "resourcemaster_id",
      "title_fz",
      "yearfrom",
      "keywords_fz",
      "author_address_fz",
      "issn",
      "articledoi",
      "author_all_fz",
      "resource_name_tk",
    ];
    const anotherSetOfAdvSearchTerms = [
      "(resourcemaster_id",
      "(title_fz",
      "(yearfrom",
      "(keywords_fz",
      "(author_address_fz",
      "(issn",
      "(articledoi",
      "(author_all_fz",
      "(resource_name_tk",
      "(titleKeywordsAbs",
      "((title_fz",
      "((yearfrom",
      "((keywords_fz",
      "((author_address_fz",
      "((issn",
      "((articledoi",
      "((author_all_fz",
      "((resource_name_tk",
      "((titleKeywordsAbs",
    ];
    let isAdvanceSearchTerm = advanceSearchTerms.some((term) =>
      new RegExp(`\\b${term}\\b`).test(dirtyString)
    );
    try {
      dispatch(clearSearchTerm());
      dispatch(clearAllFilters());
      dispatch(clearAllFiltersValue());
      dispatch(clearPublicationFilter());

      const missingQueryObj = {
        rows: 15,
        op_mode: "and",
      };

      const fullTextQuery = await queryConstructor({
        "fq__(fulltext": `true OR acl_group=(${profileData.informaticscustomer_id}))`,
        ...missingQueryObj,
        sort: "dateofpublication desc",
        datsearchtype_id:
          searchType === "Advanced Search"
            ? "2"
            : searchType === "Basic Search"
            ? "1"
            : searchType === "Author Search"
            ? "3"
            : "1",
      });
      const allTextQuery = await queryConstructor({
        ...missingQueryObj,
        sort: "dateofpublication desc",
        datsearchtype_id:
          searchType === "Advanced Search"
            ? "2"
            : searchType === "Basic Search"
            ? "1"
            : searchType === "Author Search"
            ? "3"
            : "1",
      });

      // replace the "&" with "~_~"
      let replacedQuery = searchURL.replace(/ & /g, ` ~_~ `);

      // fq__profileuser_id should be removed form the query
      let fqProfileQuery = `&fq__profileuser_id=${profileData.user_id}`;

      let actualFullTextQuery = fullTextQuery + "&" + replacedQuery;
      let actualAllextQuery =
        replacedQuery.replace(
          `&fq__(fulltext=true OR acl_group=(${profileData.informaticscustomer_id}))`,
          ""
        ) +
        "&" +
        "&logsearchhistory=true" +
        allTextQuery;
      const queryParams = getQueryParams(actualAllextQuery);
      let countApiCall, recordApiCall;

      if (queryParams?.fq__profileuser_id) {
        countApiCall = personalCoreCount;
        // recordApiCall = getPersonalLibraryCollection;
        recordApiCall = getPersonalLibraryCollectionSRP;
      } else {
        countApiCall = basicSearchForCounts;
        // recordApiCall = basicSearchMain;
        recordApiCall = basicSearchQuery;
      }

      actualFullTextQuery = actualFullTextQuery.replace(fqProfileQuery, "");
      actualAllextQuery = actualAllextQuery.replace(fqProfileQuery, "");

      const fullTextData = await recordApiCall(actualFullTextQuery);
      const allText = await countApiCall(actualAllextQuery);
      const fulltext = await countApiCall(actualFullTextQuery);

      await checkArticleExistance(fullTextData?.docs, userId, dispatch);
      await findAlertTypeSetting(fullTextData?.docs);
      await linkToDisplayfunc(fullTextData?.docs, customerData, dispatch);
      dispatch(
        updateQuery({
          fullTextQuery: actualFullTextQuery,
          allTextQuery: actualAllextQuery,
        })
      );

      const queryParamsKeys = Object.keys(queryParams);
      let isAdv = anotherSetOfAdvSearchTerms.some((term) =>
        queryParamsKeys.includes(term)
      );
      isAdvanceSearchTerm = isAdvanceSearchTerm || isAdv;

      updateQueryParamsToRedux(queryParams, dirtyString, isAdvanceSearchTerm);

      dispatch(updateSearchResults(fullTextData));
      dispatch(updateFullText(true));
      dispatch(updateAll(false));
      dispatch(updateRow(15));
      dispatch(updatePage(1));
      dispatch(updateSort("dateofpublication desc"));
      dispatch(updateNewSearchStatus(true));

      if (isAdvanceSearchTerm) {
        let advSearchObj: any = {};
        let arr = [...advanceSearchTerms, ...anotherSetOfAdvSearchTerms];

        arr.forEach((term) => {
          if (queryParams[term]) {
            advSearchObj[term] = queryParams[term];
          }
        });

        const advQuery = await queryConstructor(advSearchObj);
        let decodedQuery = decodeURIComponent(advQuery);
        dispatch(updateAdvSearchQuery(decodedQuery));
      }

      setIsSearching(false);
      // const titleKeywordsAbs = queryParams?.titleKeywordsAbs;
      let x = dirtyString.replace(
        /author_address_fz:|title_fz:|keywords_fz:|author_all_fz:|issn:|resource_name_tk|articledoi:|resourcemaster_id:|yearfrom:/gi,
        ""
      );
      if (searchType === "Author Search" && fullTextData?.docs) {
        dispatch(updateAuthorSearchResults(fullTextData));
        navigate("/authorfindersearch?searchterm=" + searchTerm);
        dispatch(updateAuthorSort("dateofpublication desc"));
        return;
      }

      if (fullTextData?.docs) {
        navigate("/search?searchterm=" + JSON.parse(dirtyString).search_term, {
          state: {
            searchTerm: x,
            fullTextQuery: fullTextQuery,
            allQuery: searchURL,
            allCount: allText?.hits,
            fullCount: fulltext?.hits,
          },
        });
      }

      dispatch(updateSubjectFilterLoader(true));
      dispatch(updateAuthorFiltersLoader(true));
      dispatch(updateOtherFiltersLoader(true));

      if (queryParams?.fq__profileuser_id) {
        //Jsonfacets
        const subjectFacetData = await getPersonalLibraryCollectionSRP(
          actualFullTextQuery,
          staticSubjectFacetData
        );
        dispatch(updateSubjectFacets(subjectFacetData?.jsonfacets));
        dispatch(updateSubjectFilterLoader(false));
        const authorFacetData = await getPersonalLibraryCollectionSRP(
          actualFullTextQuery,
          staticAuthorFacetData
        );
        dispatch(updateAuthorFacets(authorFacetData?.jsonfacets));
        dispatch(updateAuthorFiltersLoader(false));
        const allFacetData = await getPersonalLibraryCollectionSRP(
          actualFullTextQuery,
          staticFacetData
        );
        dispatch(updateAllFacets(allFacetData?.jsonfacets));
        dispatch(updateOtherFiltersLoader(false));
      } else {
        //JsonFacets
        const subjectFacetData = await subjectJsonFacetQuery(
          actualFullTextQuery
        );
        dispatch(updateSubjectFacets(subjectFacetData?.jsonfacets));
        dispatch(updateSubjectFilterLoader(false));
        const authorFacetData = await authorJsonFacetQuery(actualFullTextQuery);
        dispatch(updateAuthorFacets(authorFacetData?.jsonfacets));
        dispatch(updateAuthorFiltersLoader(false));
        const otherFacetData = await jsonFacetQuery(actualFullTextQuery);
        dispatch(updateAllFacets(otherFacetData?.jsonfacets));
        dispatch(updateOtherFiltersLoader(false));
      }
    } catch (error) {
      setIsSearching(false);
    }
  };

  async function handleSetSearchClick(searchUrl: string) {
    const queryParams = getQueryParams(searchUrl);
    Object.assign(queryParams, { sort: "dateofpublication desc" });
    const isOnlyConsortiaAccess = customerData?.product_type === "7";
    if (isOnlyConsortiaAccess) {
      Object.assign(queryParams, {
        fq__acl_group: customerData.consortia_filter,
      });
    }
    const query = await queryConstructor(queryParams);
    const response = await setSearch(query, "fulltext");
    await linkToDisplayfunc(response?.docs, customerData, dispatch);
    dispatch(updateSearchResults(response));

    queryParams?.set_number_in &&
      dispatch(updateSetInNumber(queryParams?.set_number_in));

    dispatch(
      updateAllFilter({
        key: "search-term",
        value: queryParams?.set_number_in,
      })
    );

    dispatch(
      updateQueries({
        key: "searchTerm",
        value: queryParams?.set_number_in,
      })
    );

    if (queryParams?.fq__yearfrom) {
      dispatch(updateYearFrom(queryParams.fq__yearfrom));
      dispatch(
        updateQueries({
          key: "currentYear",
          value: queryParams.fq__yearfrom,
        })
      );
    }

    dispatch(updateSetSearchQuery(query));
    dispatch(updateSetSearchStatus(true));
    const allText = await setSearch(query, "all", "count");
    const fullText = await setSearch(query, "fulltext", "count");

    if (response?.docs) {
      navigate("/search?searchterm=" + queryParams?.set_number_in, {
        state: {
          allCount: allText?.hits,
          fullCount: fullText?.hits,
        },
      });
    }

    //JsonFacets
    dispatch(updateSubjectFilterLoader(true));
    dispatch(updateAuthorFiltersLoader(true));
    dispatch(updateOtherFiltersLoader(true));
    const subjectFacetData = await setSearch(
      query,
      "fulltext",
      "all",
      "true",
      false,
      "all",
      1
    );
    dispatch(updateSubjectFacets(subjectFacetData?.jsonfacets));
    dispatch(updateSubjectFilterLoader(false));
    const authorFacetData = await setSearch(
      query,
      "fulltext",
      "all",
      "true",
      false,
      "all",
      2
    );
    dispatch(updateAuthorFacets(authorFacetData?.jsonfacets));
    dispatch(updateAuthorFiltersLoader(false));
    const otherFacetData = await setSearch(
      query,
      "fulltext",
      "all",
      "true",
      false,
      "all",
      3
    );
    dispatch(updateAllFacets(otherFacetData?.jsonfacets));
    dispatch(updateOtherFiltersLoader(false));
  }

  const updateQueryParamsToRedux = (
    queryParams: any,
    dirtyString: any,
    isAdvSearchTerm?: boolean
  ) => {
    // Updating search term
    const titleKeywordsAbs = queryParams?.titleKeywordsAbs;

    titleKeywordsAbs && dispatch(updateSearchTerm(titleKeywordsAbs));
    if (
      queryParams?.author_address_fz ||
      queryParams?.title_fz ||
      queryParams?.keywords_fz ||
      queryParams?.author_all_fz ||
      queryParams?.issn ||
      queryParams?.articledoi ||
      queryParams?.resourcemaster_id ||
      queryParams?.yearfrom ||
      queryParams?.resource_name_tk ||
      isAdvSearchTerm
    ) {
      let x = removeDuplicateTerms(dirtyString);
      dispatch(
        updateAllFilter({
          key: "search-term",
          value: titleKeywordsAbs,
        })
      );
    } else {
      if (titleKeywordsAbs?.replace("titleKeywordsAbs=", "")) {
        dispatch(
          updateAllFilter({
            key: "search-term",
            value: titleKeywordsAbs?.replace("titleKeywordsAbs=", ""),
          })
        );
      }
    }
    dispatch(
      updateQueries({
        key: "searchTerm",
        value: titleKeywordsAbs,
      })
    );

    if (queryParams?.fq__fulltext) {
      dispatch(updateOpenAccess(true));
    }

    // Updating publication date filter
    if (queryParams?.fq__early_online) {
      dispatch(updateEarlyOnline(true));
      dispatch(
        updateQueries({
          key: "earlyOnline",
          value: true,
        })
      );
    }

    if (queryParams?.fq__yearfrom) {
      dispatch(updateYearFrom(queryParams.fq__yearfrom));
      dispatch(
        updateQueries({
          key: "currentYear",
          value: queryParams.fq__yearfrom,
        })
      );
    }

    if (queryParams?.fq__dateofpublication) {
      dispatch(updateMonthFrom(queryParams.fq__dateofpublication));
    }

    // Updating Collection filter
    if (queryParams?.fq__acl_group) {
      if (queryParams.fq__acl_group === customerData?.my_library_filter) {
        dispatch(updateMyLibraryCollection(true));
        dispatch(
          updateQueries({
            key: "myLibraryCollection",
            value: true,
          })
        );
      } else if (queryParams.fq__acl_group === customerData?.consortia_filter) {
        dispatch(updateConsortiaCollection(true));
        dispatch(
          updateQueries({
            key: "consortiaSubscriptions",
            value: true,
          })
        );
      }
    }

    if (queryParams?.fq__profileuser_id) {
      dispatch(updatePersonalLibrary(true));
      dispatch(
        updateQueries({
          key: "myPersonalLibraryCollection",
          value: true,
        })
      );
    }

    // Updating resource type
    if (queryParams?.fq__resource_type) {
      let arr = queryParams?.fq__resource_type
        .replace(/[^a-zA-Z0-9\s]/g, "")
        .split(" OR ")
        ?.map((str: string) => Number(str));

      dispatch(updateDataType(arr));
    }

    // Updating Filters
    if (queryParams?.fq__data_type) {
      // Document type filter
      const dataType = queryParams?.fq__data_type
        .split("OR")
        .map((x: string) => {
          let str = x;
          str = str.trim().replace(/[()"]/g, "").replace("~_~", "&");
          return str;
        });
      console.log(queryParams?.fq__data_type.split("OR"));
      dispatch(updateSourceType(dataType));

      // to Update the breadcurm
      let appliedFilter: any[] = [];
      Array.isArray(dataType) &&
        dataType.forEach((val: string) => {
          appliedFilter.push({
            key: "data_type",
            value: val,
          });
        });
      dispatch(updateQueries(appliedFilter));
      dispatch(updateAllFilter(appliedFilter));
    }

    if (queryParams?.fq__subjects_name_l3) {
      let subjectQuery = queryParams?.fq__subjects_name_l3
        .split("OR")
        .map((x: string) => {
          let str = x;
          str = str.trim().replace(/[()"]/g, "").replace("~_~", "&");
          return str;
        });

      // Update subject filter
      dispatch(updateSubject(subjectQuery));
      // to Update the breadcurm
      let appliedFilter: any[] = [];
      Array.isArray(subjectQuery) &&
        subjectQuery.forEach((val: string) => {
          appliedFilter.push({
            key: "subject",
            value: val,
          });
        });
      dispatch(updateQueries(appliedFilter));
      dispatch(updateAllFilter(appliedFilter));
    }

    if (queryParams?.fq__authors_tk) {
      const authorParam = queryParams?.fq__authors_tk
        .split("OR")
        .map((x: string) => {
          let str = x;
          str = str.trim().replace(/[()"]/g, "").replace("~_~", "&");
          return str;
        });
      dispatch(updateAuthor(authorParam));
      // to Update the breadcurm
      let appliedFilter: any[] = [];
      Array.isArray(authorParam) &&
        authorParam.forEach((val: string) => {
          appliedFilter.push({
            key: "author",
            value: val,
          });
        });
      dispatch(updateAllFilter(appliedFilter));
      dispatch(updateQueries(appliedFilter));
    }

    if (queryParams?.fq__speakers) {
      const speakerParam = queryParams?.fq__speakers
        .split("OR")
        .map((x: string) => {
          let str = x;
          str = str.trim().replace(/[()"]/g, "").replace("~_~", "&");
          return str;
        });
      dispatch(updateSpeaker(speakerParam));

      // to Update the breadcurm
      let appliedFilter: any[] = [];
      Array.isArray(speakerParam) &&
        speakerParam.forEach((val: string) => {
          appliedFilter.push({
            key: "speaker",
            value: val,
          });
        });
      dispatch(updateQueries(appliedFilter));
      dispatch(updateAllFilter(appliedFilter));
    }

    if (queryParams?.fq__guide_name_tk) {
      const guideParam = queryParams?.fq__guide_name_tk
        .split("OR")
        .map((x: string) => {
          let str = x;
          str = str.trim().replace(/[()"]/g, "").replace("~_~", "&");
          return str;
        });
      dispatch(updateResearch(guideParam));

      // to Update the breadcurm
      let appliedFilter: any[] = [];
      Array.isArray(guideParam) &&
        guideParam.forEach((val: string) => {
          appliedFilter.push({
            key: "research",
            value: val,
          });
        });
      dispatch(updateQueries(appliedFilter));
      dispatch(updateAllFilter(appliedFilter));
    }

    if (queryParams?.fq__researcher_tk) {
      const researcherParam = queryParams?.fq__researcher_tk
        .split("OR")
        .map((x: string) => {
          let str = x;
          str = str.trim().replace(/[()"]/g, "").replace("~_~", "&");
          return str;
        });
      dispatch(updateResearcher(researcherParam));

      // to Update the breadcurm
      let appliedFilter: any[] = [];
      Array.isArray(researcherParam) &&
        researcherParam.forEach((val: string) => {
          appliedFilter.push({
            key: "researcher",
            value: val,
          });
        });
      dispatch(updateQueries(appliedFilter));
      dispatch(updateAllFilter(appliedFilter));
    }

    if (queryParams?.fq__journal_name) {
      const journalParam = queryParams?.fq__journal_name
        .split("OR")
        .map((x: string) => {
          let str = x;
          str = str.trim().replace(/[()"]/g, "").replace("~_~", "&");
          return str;
        });
      dispatch(updateJournal(journalParam));

      // to Update the breadcurm
      let appliedFilter: any[] = [];
      Array.isArray(journalParam) &&
        journalParam.forEach((val: string) => {
          appliedFilter.push({
            key: "journal",
            value: val,
          });
        });
      dispatch(updateQueries(appliedFilter));
      dispatch(updateAllFilter(appliedFilter));
    }

    if (queryParams?.fq__primary_publisher_country) {
      const primaryPublisherParam = queryParams?.fq__primary_publisher_country
        .split("OR")
        .map((x: string) => {
          let str = x;
          str = str.trim().replace(/[()"]/g, "").replace("~_~", "&");
          return str;
        });
      dispatch(updateCountryOfPublication(primaryPublisherParam));

      // to Update the breadcurm
      let appliedFilter: any[] = [];
      Array.isArray(primaryPublisherParam) &&
        primaryPublisherParam.forEach((val: string) => {
          appliedFilter.push({
            key: "countryOfPublication",
            value: val,
          });
        });
      dispatch(updateQueries(appliedFilter));
      dispatch(updateAllFilter(appliedFilter));
    }

    if (queryParams?.fq__publisher_name) {
      const publisherNameParam = queryParams?.fq__publisher_name
        .split("OR")
        .map((x: string) => {
          let str = x;
          str = str.trim().replace(/[()"]/g, "").replace("~_~", "&");
          return str;
        });
      dispatch(updatePublisher(publisherNameParam));

      // to Update the breadcurm
      let appliedFilter: any[] = [];
      Array.isArray(publisherNameParam) &&
        publisherNameParam.forEach((val: string) => {
          appliedFilter.push({
            key: "publisher",
            value: val,
          });
        });
      dispatch(updateQueries(appliedFilter));
      dispatch(updateAllFilter(appliedFilter));
    }

    if (queryParams?.fq__author_address) {
      const authorAddressParam = queryParams?.fq__author_address
        .split("OR")
        .map((x: string) => {
          let str = x;
          str = str.trim().replace(/[()"]/g, "").replace("~_~", "&");
          return str;
        });
      dispatch(updateInstitution(authorAddressParam));

      // to Update the breadcurm
      let appliedFilter: any[] = [];
      Array.isArray(authorAddressParam) &&
        authorAddressParam.forEach((val: string) => {
          appliedFilter.push({
            key: "institutions",
            value: val,
          });
        });
      dispatch(updateQueries(appliedFilter));
      dispatch(updateAllFilter(appliedFilter));
    }

    if (queryParams?.fq__filter_metrix) {
      const journalRankParam = queryParams?.fq__filter_metrix
        .split("OR")
        .map((x: string) => {
          let str = x;
          str = str.trim().replace(/[()"]/g, "").replace("~_~", "&");
          return str;
        });
      dispatch(updateJournalRank(journalRankParam));

      // to Update the breadcurm
      let appliedFilter: any[] = [];
      Array.isArray(journalRankParam) &&
        journalRankParam.forEach((val: string) => {
          appliedFilter.push({
            key: "journalRank",
            value: val,
          });
        });
      dispatch(updateQueries(appliedFilter));
      dispatch(updateAllFilter(appliedFilter));
    }

    if (queryParams?.fq__author_country_name) {
      const countryOfPublishingAuthorParam =
        queryParams?.fq__author_country_name.split("OR").map((x: string) => {
          let str = x;
          str = str.trim().replace(/[()"]/g, "").replace("~_~", "&");
          return str;
        });
      dispatch(updateCountryOfPublishingAuthor(countryOfPublishingAuthorParam));

      // to Update the breadcurm
      let appliedFilter: any[] = [];
      Array.isArray(countryOfPublishingAuthorParam) &&
        countryOfPublishingAuthorParam.forEach((val: string) => {
          appliedFilter.push({
            key: "countryOfPublishingAuthor",
            value: val,
          });
        });
      dispatch(updateQueries(appliedFilter));
      dispatch(updateAllFilter(appliedFilter));
    }
  };

  function removeDuplicateTerms(searchTerm: string) {
    // Split the search term into individual terms
    if (searchTerm.includes("title_fz") && searchTerm.includes("keywords_fz")) {
      let term = searchTerm.replace(
        /author_address_fz:|title_fz:|keywords_fz:|author_all_fz:|issn:|articledoi:|resourcemaster_id:|yearfrom:/gi,
        ""
      );
      const splitedArr = term?.split(/\s+(?![^()]*\))/);
      let newArr = [];

      for (let obj of splitedArr) {
        if (obj.startsWith("(") && obj.endsWith(")")) {
          let arr1 = obj?.slice(1, -1)?.replace(/[()]/g, "").split(" ");

          let arr2: string[] = [];
          for (let i = 0; i < arr1.length; i++) {
            if (!arr2.includes(arr1[i])) {
              arr2.push(arr1[i]);
            }
          }

          let val = `${removeOperatorFromEnd(arr2.join(" "))}`;
          newArr.push(val);
        } else {
          newArr.push(obj);
        }
      }
      for (let i = 0; i < newArr.length; i++) {
        if (newArr[i] === newArr[i + 2]) {
          newArr.splice(i + 1, 2);
        }
      }
      term = newArr.join(" ");
      return term;
    } else {
      let term = searchTerm.replace(
        /author_address_fz:|title_fz:|keywords_fz:|author_all_fz:|issn:|articledoi:|resourcemaster_id:|yearfrom:/gi,
        ""
      );
      return term;
    }
  }

  function showLoginModa() {
    if (!customerDetails?.allow_profile) return;

    if (sessionStorage.getItem("isLoginModalShowed") === "true") return;
    if (profileStatus === "false" || customerDetails?.customer_id === 0) {
      dispatch(
        setModal({
          modalType: "SHOW_LOGIN",
          modalProps: {
            open: true,
            isProfileUser: profileStatus,
          },
        })
      );
      sessionStorage.setItem("isLoginModalShowed", "true");
    }
  }

  function showGuestLoginModa() {
    if (customerDetails?.customer_id === 0) {
      dispatch(
        setModal({
          modalType: "SHOW_LOGIN",
          modalProps: {
            open: true,
            isProfileUser: profileStatus,
          },
        })
      );
    }
  }

  useEffect(() => {
    if (state?.from === "basic") {
      const debounce = setTimeout(() => {
        handleBasicserachApis();
      }, 5);

      return () => {
        clearTimeout(debounce);
      };
    }
  }, []);

  const handleBasicserachApis = async () => {
    // await checkArticleExistance(state?.fullTextData?.docs);
    dispatch(updateSubjectFilterLoader(true));
    dispatch(updateAuthorFiltersLoader(true));
    dispatch(updateOtherFiltersLoader(true));

    await checkArticleExistance(showbasicSearchData, userId, dispatch);
    await findAlertTypeSetting(showbasicSearchData);
    // await linkToDisplayfunc(showbasicSearchData, userId, dispatch);
    await linkToDisplayfunc(showbasicSearchData, customerData, dispatch);
    await logBasicSearchUsageData(11);
    await logBasicSearchUsageData(29);

    //JsonFacets;
    const subjectFacetData = await subjectJsonFacetQuery(
      queryCount?.fullTextQuery
    );
    dispatch(updateSubjectFacets(subjectFacetData?.jsonfacets));
    dispatch(updateSubjectFilterLoader(false));
    const authorFacetData = await authorJsonFacetQuery(
      queryCount?.fullTextQuery
    );
    dispatch(updateAuthorFacets(authorFacetData?.jsonfacets));
    dispatch(updateAuthorFiltersLoader(false));
    const allFacetData = await jsonFacetQuery(queryCount?.fullTextQuery);
    dispatch(updateAllFacets(allFacetData?.jsonfacets));
    dispatch(updateOtherFiltersLoader(false));
  };

  //To Get the Count for both FullText or All (¬_¬)
  //FYI, will run only once or on page refresh
  useEffect(() => {
    const getData = async () => {
      if (
        setSearchReducer.isSetSearchEnabled &&
        setSearchReducer.setSearchQuery
      )
        return;
      try {
        const isPersonalCore = filterReducer.myPersonalLibCollection;
        const full = queryCount.fullTextQuery;
        const all = queryCount.allTextQuery;

        if (!filterReducer.libraryOpac && !filterReducer.dspace) {
          if (full && all && !isPersonalCore) {
            const allText = await basicSearchForCounts(all);
            const fulltext = await basicSearchForCounts(full);
            setTextCount({
              all: allText.hits,
              fulltext: fulltext.hits,
            });
            if (fulltext?.hits == 0 && allText?.hits > 0) {
              dispatch(updateFullText(false));
              dispatch(updateAll(true));
              dispatch(search());
            }
            if (fulltext?.hits === 0 && allText?.hits === 0) {
              setNoResultModal(true);
            }
          } else if (isPersonalCore && full && all) {
            const allText = await personalCoreCount(all);
            const fulltext = await personalCoreCount(full);

            setTextCount({
              all: allText?.hits,
              fulltext: fulltext?.hits,
            });
            if (fulltext?.hits == 0) {
              dispatch(updateFullText(false));
              dispatch(search());
            }
            if (fulltext?.hits === 0 && allText?.hits === 0) {
              setNoResultModal(true);
            }
          }
        }
      } catch (err) {
        console.error(err);
      }
    };

    // if (state?.noRerender || isDSpace) return;

    // getData();

    if (!state?.noRerender && !isDSpace) {
      // Apply manual debounce
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
      debounceTimeout.current = setTimeout(() => {
        getData();
      }, 1); // Delay by 100ms
    }

    // Cleanup for debounceG
    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    };
  }, [queryCount.allTextQuery, queryCount.fullTextQuery]);

  useEffect(() => {
    const isPersonalCore = filterReducer.myPersonalLibCollection;

    async function getSearchDataCount() {
      if (
        setSearchReducer.isSetSearchEnabled &&
        setSearchReducer.setSearchQuery &&
        !isPersonalCore
      ) {
        let searchContext: SearchContext = "all";
        if (filterReducer.myPersonalLibCollection) {
          searchContext = "myPersonal";
        } else if (filterReducer.myLibraryCollection) {
          searchContext = "myLibrary";
        } else if (filterReducer.consortiaCollection) {
          searchContext = "myConsortia";
        }
        // const allText = await setSearch(
        //   setSearchReducer.setSearchQuery,
        //   searchContext,
        //   "count",
        //   null,
        //   false,
        //   "all"
        // );
        const allText = await setSearch(
          setSearchReducer.setSearchQuery,
          searchContext,
          "count",
          "false",
          false,
          "all"
        );
        // const fullText = await setSearch(
        //   setSearchReducer.setSearchQuery,
        //   searchContext === "all" ? "fulltext" : searchContext,
        //   "count",
        //   null,
        //   false,
        //   "fulltext"
        // );
        const fullText = await setSearch(
          setSearchReducer.setSearchQuery,
          searchContext === "all" ? "fulltext" : searchContext,
          "count",
          "false",
          false,
          "fulltext"
        );
        setTextCount({
          all: allText?.hits,
          fulltext: fullText?.hits,
        });

        if (fullText?.hits == 0 && allText?.hits > 0) {
          dispatch(updateFullText(false));
          dispatch(updateAll(true));
          dispatch(search());
        }
        if (fullText?.hits === 0 && allText?.hits === 0) {
          setNoResultModal(true);
        }
        return;
      } else if (
        setSearchReducer.isSetSearchEnabled &&
        setSearchReducer.setSearchQuery &&
        isPersonalCore
      ) {
        const allText = await setSearch(
          setSearchReducer.setSearchQuery,
          "myPersonal",
          "count"
        );
        const fullText = await setSearch(
          setSearchReducer.setSearchQuery,
          "myPersonal",
          "count",
          null,
          false,
          "fulltext"
        );
        setTextCount({
          all: allText?.hits,
          fulltext: fullText?.hits,
        });

        if (fullText?.hits == 0 && allText?.hits > 0) {
          dispatch(updateFullText(false));
          dispatch(updateAll(true));
          dispatch(search());
        }
        if (fullText?.hits === 0 && allText?.hits === 0) {
          setNoResultModal(true);
        }
      }
    }

    const debounce = setTimeout(() => {
      getSearchDataCount();
    }, 100);

    return () => {
      clearTimeout(debounce);
    };
  }, [setSearchReducer.setSearchQuery]);

  useEffect(() => {
    if (isOpac_dspace) {
      handleDSPACEHits();
    }
  }, [isOpac_dspace]);

  const handleDSPACEHits = async () => {
    let queryObj = {};
    Object.assign(queryObj, {
      basic_search: formatString(searchTerm),
    });
    Object.assign(queryObj, { page_no: 1 });
    Object.assign(queryObj, { per_page: 15 });

    let dspaceQuery = await queryConstructor(queryObj, "AND", true);

    const data: any = await dSpaceSearchQuery(dspaceQuery);
    dispatch(updateSearchResults(data));
  };

  let searchResults: any = useAppSelector(
    (state) => state.searchReducer.searchResult
  );

  let opacSearchResults: any = useAppSelector(
    (state) => state.searchReducer.opacResult
  );

  // ====SNACKBAR=======

  let currentPage = useAppSelector((state) => state?.searchReducer?.page);

  const rows = useAppSelector((state) => state.searchReducer.rows);

  const fulltext = useAppSelector((state) => state.searchReducer.fullText);
  const isAll = useAppSelector((state) => state.searchReducer.all);
  const isDSpace = useAppSelector((state) => state.searchReducer.dSpace);

  const searchTerm = useAppSelector((state) => state.searchReducer.searchTerm);

  const markedArticleCount = useAppSelector(
    (state) => state.searchReducer.markedArticleCount
  );

  const isSettled = useAppSelector((state) => state.searchReducer.isSettled);

  let keywordData = searchResults?.highlighting;

  let customerId: any =
    useAppSelector((state) => state?.login?.informaticscustomer_id) ||
    sessionStorage.getItem("informaticscustomer_id");

  const markedResult = useAppSelector(
    (state) => state.searchReducer.markedResult
  );

  const subjectType = useAppSelector(
    (state) => state.searchReducer.subjectType
  );

  const [dialogueData, setDialogueData] = React.useState({});
  const isMounted = useRef(false);
  const refContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    isSettled && isArticleBookmarked();
  }, [isSettled]);

  const handleDawer = async (data: any) => {
    setTop(!top);
    setDialogueData(data);
    const authorData = await basicSearchAuthorEmail(data?.article_id);
    setAuthorEmailData(authorData);

    logBasicSearchUsageData(30, data);
  };

  const handleChange = async (_event: any, value: string) => {
    if (value === "fulltext") {
      //logBasicSearchUsageData(29);
      logBasicSearchUsageDataONOSFulltext(29);
      if (textCount.fulltext == 0) setNoResultModal(true);
      dispatch(updateFullText(true));
      dispatch(updatePage(1));
      dispatch(updataLibraryOpac(false));
      dispatch(updateDSPACECollection(false));
    } else if (value === "all") {
      // logBasicSearchUsageData(28);
      logBasicSearchUsageDataONOSFulltext(28);

      if (textCount.all == 0) setNoResultModal(true);
      dispatch(updateFullText(false));
      dispatch(updatePage(1));
      dispatch(updataLibraryOpac(false));
      dispatch(updateDSPACECollection(false));
    } else if (value === "opac") {
      dispatch(sliceSearchTerm());
      dispatch(clearAllFilters());
      dispatch(spliceAllFilterArray());
      dispatch(clearMonthFromFilter());
      dispatch(clearPublicationFilter());

      dispatch(updateFullText(false));
      dispatch(updatePage(1));
      dispatch(updataLibraryOpac(true));
      dispatch(updateDSPACECollection(false));
    } else if (value === "DSPACE") {
      dispatch(sliceSearchTerm());
      dispatch(clearAllFilters());
      dispatch(spliceAllFilterArray());
      dispatch(clearMonthFromFilter());
      dispatch(clearPublicationFilter());

      dispatch(updateFullText(false));
      dispatch(updatePage(1));
      dispatch(updataLibraryOpac(false));
      dispatch(updateDSPACECollection(true));

      // dispatch(clearAllFilters());
      // dispatch(removeAppliedFilters());
    }

    await dispatch(search());

    dispatch(updateIsSettled(true));
  };

  useEffect(() => {
    onload();
  }, [subjectType.length]);

  useEffect(() => {
    markResultStatus();
  }, [markedData?.length, isSettled]);

  function markResultStatus() {
    try {
      if (searchResults?.docs?.length === 0) return;
      let dataArray = searchResults?.docs;

      if (!Array.isArray(dataArray)) return;

      let articlesIds = dataArray?.map((obj) => obj?.article_id);
      let allItemsPresent = articlesIds.every((id) =>
        markedArticlesid.includes(id)
      );
      if (
        markedData?.length === searchResults?.docs?.length &&
        allItemsPresent
      ) {
        setMarkResult(true);
      } else {
        setMarkResult(false);
      }
    } catch (err) {
      console.error(err);
    }
  }
  // Pagination
  let count = Math.ceil(searchResults?.hits / rows);
  // Opac pagination
  let opacCount = Math.ceil(opacSearchResults?.hits / rows);

  const handleLogout = useCallback(async () => {
    try {
      dispatch(reset());
      dispatch(
        setSearchKeyword({
          searchType: "",
          words: "",
        })
      );

      if (sessionStorage.getItem("isIPLogin") === "true") {
        navigate("/thankYou");
        return;
      }

      const querylogout: any = await userLogout();
      dispatch(logout(querylogout));
      dispatch(clearCustomerDetails());

      sessionStorage.clear();
      localStorage.clear();

      navigate("/login");
    } catch (error) {
      console.error("Error State", error);
    }
  }, [dispatch, navigate]);

  useEffect(() => {
    const interval = setInterval(() => {
      // dispatch(startTimer());
      if (counterRef.current >= 180) {
        counterRef.current = 0;
      } else {
        counterRef.current += 1;
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [dispatch]);

  useEffect(() => {
    if (counter !== 0 && counter % 3 === 0 && time <= 60) {
      dispatch(resetTimer());
      dispatch(resetCounter());
      dispatch(incrementMainCounter());
      counterRef.current = 0;

      if (mainCounter === 2) {
        dispatch(resetBotListner());
        handleLogout();
        notify("error", "Too many requests, Please login again");
      } else {
        dispatch(
          setModal({
            modalType: "CAPTCHA",
            modalProps: {
              open: true,
            },
          })
        );
      }
    }
  }, [handleLogout, mainCounter, time, counter, dispatch]);

  async function handlePageChange(p: number) {
    if (!isCaptchaVerified) {
      dispatch(
        setModal({
          modalType: "CAPTCHA",
          modalProps: {
            open: true,
          },
        })
      );

      return;
    }

    dispatch(updatePage(p));
    dispatch(startTimer(counterRef.current));
    dispatch(incrementCounter());
    setMarkResult(false);

    isMounted.current = true;
    goToTop();
    await dispatch(search());
    dispatch(updateIsSettled(true));
  }

  async function getRowsPerPage(value: number) {
    // let count = Math.ceil(searchResults?.hits / value);
    let count;
    if (isOpac) {
      count = Math.ceil(opacSearchResults?.hits / value);
    } else {
      count = Math.ceil(searchResults?.hits / value);
    }
    isMounted.current = true;
    dispatch(updateRow(value));

    if (currentPage >= count) {
      dispatch(updatePage(count));
    }

    await dispatch(search());
    goToTop();
  }

  function getMarkedResult(res: any, id: string) {
    let data = structuredClone({
      ...res,
      searchTerm: searchTerm.toString(),
      advancedSearchTerm: filterReducer?.allAppliedFilter?.map((val) =>
        val.value.toString()
      ),
    });
    // Handle id
    if (markedData.includes(id)) {
      let filterIds = markedArticlesid.filter((data) => data !== id);
      let filterIcCount = markedArticleCount.filter((data) => data !== id);
      let filterData = markedResult.filter(
        (data: any) => data?.article_id !== id
      );
      dispatch(updateMarkedResult(filterData));
      dispatch(updateMarkedArticleId(filterIds));

      dispatch(updateMarkedArticleCount(filterIcCount));
      setMarkdeData((prev) => prev.filter((obj) => obj !== id));
    } else if (markedData?.length) {
      let articlesIds = [...markedArticlesid, id];
      let markedResultData = [...markedResult, data];
      let idCount = [...markedArticleCount, id];

      dispatch(updateMarkedResult(markedResultData));
      dispatch(updateMarkedArticleId(articlesIds));
      dispatch(updateMarkedArticleCount(idCount));

      setMarkdeData((prev) => [...prev, id]);
    } else {
      if (!markedArticlesid.length) {
        dispatch(updateMarkedArticleId([id]));
        dispatch(updateMarkedResult([data]));
        dispatch(updateMarkedArticleCount([id]));
      } else {
        let articlesIds = [...markedArticlesid, id];
        let markedResultData = [...markedResult, data];
        dispatch(updateMarkedResult(markedResultData));
        dispatch(updateMarkedArticleId(articlesIds));
        dispatch(updateMarkedArticleCount([id]));
      }

      setMarkdeData([id]);
    }
  }

  function selectAllArticles(e: any) {
    try {
      let dataArray = searchResults?.docs;
      dataArray = dataArray?.map((x: any) =>
        structuredClone({
          ...x,
          searchTerm: searchTerm,
          advancedSearchTerm: filterReducer?.allAppliedFilter?.map((val) =>
            val.value.toString()
          ),
        })
      );

      if (!Array.isArray(dataArray)) return;

      if (e?.target?.checked) {
        let filteredMarkedIds = [];
        let filteredMarkedData = [];
        let articlesIds = dataArray?.map((obj) => obj?.article_id);

        for (let id of articlesIds) {
          if (!markedArticlesid.includes(id)) filteredMarkedIds.push(id);
        }

        for (let obj of dataArray) {
          if (filteredMarkedIds.includes(obj?.article_id))
            filteredMarkedData.push(obj);
        }

        dispatch(updateMarkedResult([...markedResult, ...filteredMarkedData]));
        dispatch(
          updateMarkedArticleId([...markedArticlesid, ...filteredMarkedIds])
        );
        dispatch(
          updateMarkedArticleCount([...markedArticlesid, ...filteredMarkedIds])
        );
        setMarkdeData(articlesIds);
      } else {
        setMarkdeData([]);
        // curr res ids
        let articlesIds = dataArray?.map((obj) => obj?.article_id);

        // check the current res id in marked articls ids and remove all
        let filteredMarkedIds = [];
        let filteredMarkedData = [];

        for (let id of markedArticlesid) {
          if (!articlesIds.includes(id)) filteredMarkedIds.push(id);
        }

        dispatch(updateMarkedArticleId(filteredMarkedIds));
        dispatch(updateMarkedArticleCount(filteredMarkedIds));

        // keep the data in mark result that matches  "filteredMarkedIds"
        for (let obj of markedResult) {
          if (filteredMarkedIds.includes(obj?.article_id))
            filteredMarkedData.push(obj);
        }

        dispatch(updateMarkedResult(filteredMarkedData));
      }
    } catch (err) {
      console.error(err);
    }
  }

  // Function to trigger api after refresh - if data is empty in redux
  function onload() {
    let searchTerm = getParamsByName("searchterm") || getParamsByName("q");
    //let searchTerm = getParamsByName("q");
    if (!searchTerm) return;

    const searchedData = {
      key: "search-term",
      value: searchTerm,
    };

    // If same seach term is already in the filters it prevents adding the allAppliedFilter with the same seachTerm again
    const searchFiltersApplied = allAppliedFilter.filter(
      (filter) => filter?.key === "search-term"
    );
    let currentSearchTermFilter;
    if (searchFiltersApplied.length > 0) {
      currentSearchTermFilter = searchFiltersApplied.filter(
        (filter) => filter?.value === searchTerm
      )[0];
    }

    if (!currentSearchTermFilter?.value) {
      //dispatch(updateAllFilter(searchedData));
      if (
        location?.state?.from !== "searchHistory" &&
        location?.state?.from !== "savedSearchHistory" &&
        location?.state?.from !== "subjectAlerts"
      ) {
        dispatch(updateAllFilter(searchedData));
      }
    }

    if (
      location?.state?.from !== "searchHistory" &&
      location?.state?.from !== "savedSearchHistory" &&
      location?.state?.from !== "subjectAlerts"
    ) {
      dispatch(updateSearchTerm(searchTerm));
    }

    if (searchResults?.docs) return;
    if (!subjectType.length) return;

    dispatch(search());
  }

  function handleBoooks(data: any, i: any) {
    logBasicSearchUsageData(130);
    navigate(`/BookSearch?bookmasterid=${data?.bookmaster_id}`);
  }

  function handleAuthor(authorName: string, data: any, authorDetail?: any) {
    // Make new search, claar all filters
    dispatch(clearSearchTerm());
    dispatch(clearAllFilters());
    dispatch(clearPublicationFilter());
    dispatch(clearAllFiltersValue());
    dispatch(clearAllSetSearch());

    params.delete("searchTerm");
    setSearchParams(params, {
      replace: true,
    });

    if (
      data.data_type === dataTypes.journalArticle ||
      data.data_type === dataTypes.journals ||
      data.data_type === dataTypes.conferenceProceedings ||
      data.data_type === dataTypes.magazine ||
      data.data_type === dataTypes.preprint ||
      data.data_type === dataTypes.books
    ) {
      logBasicSearchUsageData(19, data);
    }

    if (data.data_type === dataTypes.dissertationThesis) {
      // logBasicSearchUsageData(19, data);
      logBasicSearchUsageData(20, data);
    }

    if (data.data_type === dataTypes.audioVideo) {
      logBasicSearchUsageData(22, data);
    }
    if (
      data?.authors?.length ||
      authorRole[authorDetail?.datauthorrole_id] === "Author"
    ) {
      dispatch(updateAuthor([authorName]));

      let appliedFilter = {
        key: "author",
        value: authorName,
      };

      dispatch(updateAllFilter(appliedFilter));
    } else if (
      data?.speakers?.length ||
      authorRole[authorDetail?.datauthorrole_id] === "Speaker"
    ) {
      dispatch(updateSpeaker([authorName]));
      let appliedFilter = {
        key: "speaker",
        value: authorName,
      };

      dispatch(updateAllFilter(appliedFilter));
    } else if (
      data?.guide_name === authorName ||
      authorRole[authorDetail?.datauthorrole_id] === "Guide"
    ) {
      dispatch(updateResearch([authorName]));
      dispatch(
        updateAllFilter({
          key: "research",
          value: authorName,
        })
      );
    } else if (
      data?.researcher_tk === authorName ||
      authorRole[authorDetail?.datauthorrole_id] === "Researcher"
    ) {
      dispatch(updateResearcher([authorName]));
      dispatch(
        updateAllFilter({
          key: "researcher",
          value: authorName,
        })
      );
    } else if (
      data?.editors?.length ||
      authorRole[authorDetail?.datauthorrole_id] === "Editor"
    ) {
      dispatch(updateResearcher([authorName]));
      dispatch(
        updateAllFilter({
          key: "editor",
          value: authorName,
        })
      );
    } else if (
      data?.editors?.length ||
      authorRole[authorDetail?.datauthorrole_id] === "Inventor"
    ) {
      dispatch(updateInventor([authorName]));
      dispatch(updateAllFilter({ key: "Inventor", value: authorName }));
    }

    dispatch(updatePage(1));
    dispatch(updateRow(15));
    dispatch(search("", null, true));
  }

  async function handleResource(data: any, i: number) {
    // Make new search, claar all filters
    if (
      data?.data_type !== dataTypes.journalArticle &&
      data?.data_type !== dataTypes.journals
    ) {
      dispatch(clearSearchTerm());
      dispatch(clearAllFilters());
      dispatch(clearPublicationFilter());
      dispatch(clearAllFiltersValue());
      dispatch(clearQuery());
      params.delete("searchTerm");
      setSearchParams(params, {
        replace: true,
      });
    }
    if (
      data?.data_type === dataTypes.journalArticle ||
      data?.data_type === dataTypes.journals
    ) {
      const item = data;

      dispatch(updateBrowserPage(1));
      // Get acces lock type
      let accessLockType;
      if (item?.openaccess_type !== "Open Access" || !item?.openaccess_type) {
        const payloadData = {
          customer_id: customerId,
          resource_id: item?.resourcemaster_id,
          access_type: 1,
        };
        let journalLockRes = await journalLock(
          "?" + objectToQueryString(payloadData)
        );
        accessLockType = Array.isArray(journalLockRes?.data?.data)
          ? journalLockRes?.data?.data[0]
          : {};
      }
      const yearData = Array.isArray(data?.yearfrom)
        ? data?.yearfrom[0]
        : data?.yearfrom;

      if (currentYear === yearData) {
        logBasicSearchUsageData(90, data);
      } else {
        logBasicSearchUsageData(87, data);
      }
      logBasicSearchUsageData(92, data);
      logBasicSearchUsageData(91, data);
      dispatch(updatedStoredSelectedIssue(false));
      navigate(`/BrowseJournalTableSearch/BrowseJournalSearch`, {
        state: {
          item: item,
          from: "basicSearch",
          alerts: showAlertData[i],
          date: data?.yearfrom,
          lock: accessLockType,
          previousScreenData: data,
        },
      });
    } else if (data?.data_type === dataTypes.conferenceProceedings) {
      dispatch(updateConfrence([data?.conference_name]));
      dispatch(
        updateAllFilter({
          key: "conference_name",
          value: data?.conference_name,
        })
      );
      dispatch(search());
      logBasicSearchUsageData(115, data);
    } else if (data?.data_type === dataTypes.dissertationThesis) {
      dispatch(updateUniversityName([data?.university_name]));
      dispatch(
        updateAllFilter({
          key: "university_name",
          value: data?.university_name,
        })
      );
      dispatch(search());
      logBasicSearchUsageData(23, data);
    } else if (data?.data_type === dataTypes.audioVideo) {
      dispatch(updatePublisher(data?.publisher_name));
      dispatch(
        updateAllFilter({
          key: "publisher",
          value:
            Array.isArray(data?.publisher_name) && data?.publisher_name.length
              ? data?.publisher_name[0]
              : "",
        })
      );
      dispatch(search());
      logBasicSearchUsageData(25, data);
    } else if (data?.data_type === dataTypes.preprint) {
      if (data?.publisher_name?.length)
        dispatch(updatePublisher(data?.publisher_name));
      dispatch(
        updateAllFilter({
          key: "publisher",
          value:
            Array.isArray(data?.publisher_name) && data?.publisher_name.length
              ? data?.publisher_name[0]
              : "",
        })
      );
      dispatch(search());
      logBasicSearchUsageData(24, data);
    } else if (data.data_type === dataTypes.magazine) {
      dispatch(updateResourceId([data?.resourcemaster_id]));
      dispatch(
        updateAllFilter({
          key: "magazine",
          value: data?.resource_name_tk,
        })
      );
      dispatch(search());
      logBasicSearchUsageData(116, data);
    } else if (
      data.data_type === dataTypes.books ||
      data.data_type === dataTypes.dataset ||
      data.data_type === dataTypes.patents
    ) {
      if (data?.publisher_name?.length)
        dispatch(updatePublisher(data?.publisher_name));
      dispatch(
        updateAllFilter({
          key: "publisher",
          value:
            Array.isArray(data?.publisher_name) && data?.publisher_name.length
              ? data?.publisher_name[0]
              : "",
        })
      );
      if (data.data_type === dataTypes.books) {
        logBasicSearchUsageData(133, data);
      }

      dispatch(search());
      // logBasicSearchUsageData(24, data);
    }

    // go to top
    goToTop();
  }

  function isArticleBookmarked() {
    try {
      let dataArray = searchResults?.docs;

      if (!Array.isArray(dataArray)) return;

      let articlesIds = dataArray?.map((obj) => obj?.article_id);
      if (articlesIds.length === 0) return;
      let id: string[] = [];

      let currentSearchTerm = markedResult?.filter(
        (obj: any) => obj?.searchTerm === searchTerm.toString()
      );

      for (let str of articlesIds) {
        if (markedArticlesid.includes(str)) id.push(str);
      }

      const payload = [...markedArticleCount, ...id];
      dispatch(updateMarkedArticleCount(payload));

      if (id.length) {
        setMarkdeData(() => id);
      } else if (!currentSearchTerm.length) {
        setMarkdeData([]);
      }
    } catch (err) {
      console.error(err);
    }
  }

  //set alert
  const [openRSSViewModal, setOpenRSSViewModal] = useState<Boolean>(false);
  const [rssdata, setRssdata] = useState("");

  const handleOpenViewModal = (data: any) => {
    setOpenRSSViewModal(true);
    setRssdata(data);
  };

  const handleCloseViewModal = () => setOpenRSSViewModal(false);

  const isAuthenticated = sessionStorage.getItem("informaticscustomer_id");

  function getButtonType(article_id: string) {
    try {
      if (!article_id) return;
      if (!linkToDisplay) return;

      for (let obj of linkToDisplay) {
        if (obj?.article_id === article_id) {
          return obj?.button_to_display;
        }
      }
    } catch (err) {
      console.error(err);
    }
  }

  async function closeNoResultModal() {
    try {
      setNoResultModal(false);
      let inputField = document.getElementById(
        "search-field"
      ) as HTMLInputElement;
      inputField?.focus();
      inputField?.select();
      if (textCount.fulltext === 0 && textCount.all > 0) {
        dispatch(updateFullText(false));
        dispatch(updateAll(true));
        dispatch(search());
      }
      if (textCount.fulltext === 0 && textCount.all === 0) {
        if (!Array.isArray(allAppliedQueries)) return;
        let lastAppliedQuery = allAppliedQueries[allAppliedQueries.length - 1];

        if (lastAppliedQuery.key === "searchTerm") {
          removeSearchKeyword(lastAppliedQuery.value);
        } else if (lastAppliedQuery.key === "earlyOnline") {
          dispatch(updateEarlyOnline(false));
          dispatch(search());
        } else if (lastAppliedQuery.key === "lastOneWeek") {
          dispatch(updateDateFrom(""));
          dispatch(search());
        } else if (lastAppliedQuery.key === "lastOneMonth") {
          dispatch(updateMonthFrom(""));
          dispatch(search());
        } else if (lastAppliedQuery.key === "yearRange") {
          dispatch(updateYearFrom(""));
          dispatch(search());
        } else if (lastAppliedQuery.key === "jgateCollection") {
          dispatch(updateJgateCollection(false));
          dispatch(search());
        } else if (lastAppliedQuery.key === "consortiaSubscriptions") {
          dispatch(updateConsortiaCollection(false));
          dispatch(search());
        } else if (lastAppliedQuery.key === "myLibraryCollection") {
          dispatch(updateMyLibraryCollection(false));
          dispatch(search());
        } else if (lastAppliedQuery.key === "onosCollection") {
          dispatch(updateOnosCollection(false));
          dispatch(search());
        } else if (lastAppliedQuery.key === "myPersonalLibraryCollection") {
          dispatch(updatePersonalLibrary(false));
          dispatch(search());
        } else if (lastAppliedQuery.key === "opac") {
          dispatch(updataLibraryOpac(false));
          dispatch(search());
        } else if (lastAppliedQuery.key === "currentYear") {
          dispatch(updateYearFrom(""));
          dispatch(search());
        }
      }
    } catch (err) {
      console.error(err);
    }
  }

  function handleAvailableinLib() {
    logBasicSearchUsageData(31);

    notify(
      "info",
      "Please visit the library to obtain the photocopy if required. Contact your library staff for any help."
    );
  }

  function removeSearchKeyword(value: string) {
    if (searchTerm.length < 2) return;
    dispatch(removeSearchTerm(value));
    dispatch(removeAllFilterType(value));
    dispatch(search());
  }

  // To know if the alert is set to journal already
  function findAlertTypeSetting(
    resourcemaster_id: string | number
  ): boolean | null {
    try {
      if (!resourcemaster_id) return false;
      if (!Array.isArray(alertTypeSetting)) return null;

      const articleData = alertTypeSetting.filter(
        (obj) => obj?.resource_id === resourcemaster_id.toString()
      );
      if (
        articleData[0]?.alert_type === 1 ||
        articleData[0]?.alert_type === 2 ||
        articleData[0]?.alert_type === 3
      ) {
        return true;
      }

      return null;
    } catch (err) {
      console.error(err);
      return null;
    }
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        refContainer.current &&
        refContainer.current.contains(event.target as Node)
      ) {
        showGuestLoginModa();
      }
    };

    const handleMouseClick = (event: MouseEvent) => {
      handleClickOutside(event);
    };
    document.addEventListener("click", handleMouseClick);
    return () => {
      document.removeEventListener("click", handleMouseClick);
    };
  }, [customerDetails?.customer_id]);

  const handleAbstractFullScreen = (data: any) => {
    logBasicSearchUsageData(30, data);
    navigate("/abstractFullScreen", {
      state: { data, linkToDisplay, searchTerm, resultFrom: "searchResult" },
    });
  };

  const handleDataSets = async (data: any) => {
    const res = await getDataSetDetails(data?.article_id);

    if (res?.total_count == 1) {
      window.open(res?.dataset_details[0]?.data_link);
    } else {
      navigate("/abstractFullScreen", {
        state: {
          data,
          linkToDisplay,
          searchTerm,
          resultFrom: "searchResult",
          res,
          multipleDatasets: true,
        },
      });
    }
  };

  // return(<></>)
  // For handling Filter drawer close on change
  useEffect(() => {
    if (FilterDrawer) {
      setFilterDrawer(false);
    }
  }, [filterReducer]);

  return (
    <Box ref={refContainer}>
      <BasicSearchTopBar totalResult={searchResults?.hits} />
      <ScrollToTop />
      <Divider />
      <Box
        sx={{
          backgroundColor: "#F4F5F7",
          pointerEvents: isAuthenticated ? "auto" : "none",
        }}
      >
        <Box
          sx={{
            paddingInline: { xs: "0px", sm: "35px", md: "66px", lg: "66px" },
          }}
        >
          <Grid container>
            <Box sx={{ display: { xs: "none", sm: "none", md: "contents" } }}>
              <Filters
                searchResults={searchResults}
                resultCount={
                  filterReducer?.libraryOpac
                    ? searchResults?.hits
                    : textCount?.all
                }
                allCount={textCount?.all}
              />
            </Box>

            <Grid item xs={12} sm={12} md={9.5} id="main-search-node">
              {/* Header Div */}
              {true && (
                <Box
                  sx={[
                    webStyle.flexBox,
                    {
                      marginTop: "1%",
                      height: { xs: 60, sm: 60, md: 35 },
                      width: "96%",
                    },
                  ]}
                >
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      ml: { xs: "15px", sm: "15px", md: "0px" },
                      justifyContent: "space-between",
                    }}
                  >
                    <Box sx={{ display: "flex", gap: 2 }}>
                      {!isOpac && !isDSPACE && (
                        <Box
                          sx={{
                            display: { xs: "none", sm: "none", md: "flex" },
                          }}
                        >
                          <Checkbox
                            sx={{ ml: "5px" }}
                            onChange={selectAllArticles}
                            checked={markResult}
                            inputProps={{ "aria-label": "controlled" }}
                            disabled={isDSpace}
                            size={isXs ? "small" : "medium"}
                          ></Checkbox>
                          <Typography
                            sx={{
                              alignSelf: "center",
                              marginRight: "4%",
                              color: Colors.primary,
                              fontSize: {
                                xs: ".6.5rem",
                                sm: "0.7rem",
                                md: "0.8rem",
                                lg: "0.875rem",
                                xl: "0.875rem",
                              },
                              cursor: "pointer",
                              flexShrink: 0,
                            }}
                            onClick={() => {
                              if (!markedArticleCount.length || isDSpace)
                                return;
                              navigate("/marked-result");
                            }}
                          >
                            {markedArticleCount?.length
                              ? `View Marked Results`
                              : "Mark Results"}
                            {markedArticleCount?.length > 0
                              ? "(" + markedArticleCount?.length + ")"
                              : ""}
                          </Typography>
                        </Box>
                      )}

                      {!isOpac && !isDSPACE && (
                        <ToggleButtonGroup
                          onChange={handleChange}
                          color="primary"
                          exclusive
                          aria-label="Platform"
                          size="small"
                          sx={{
                            display: { xs: "flex" },
                            width: { xs: "100%", sm: "auto" }, // Ensures responsiveness
                            justifyContent: "center",
                            flexDirection: { xs: "row", sm: "row" }, // Stack in xs, row in sm+
                          }}
                        >
                          <ToggleButton
                            sx={[
                              fulltext && !isOpac
                                ? webStyle.checkedStyle
                                : webStyle.unCheckedStyle,
                              {
                                ":hover": {
                                  background: fulltext
                                    ? Colors.primary
                                    : "#fff",
                                  opacity: 0.85,
                                },
                              },
                              { whiteSpace: "nowrap" },
                              { width: { xs: "50%", sm: "auto" } },
                            ]}
                            value={"fulltext"}
                          >
                            Full Text{" "}
                            {textCount.fulltext >= 0
                              ? "(" +
                                textCount.fulltext?.toLocaleString("en-US") +
                                ")"
                              : ""}
                          </ToggleButton>
                          <ToggleButton
                            value="all"
                            sx={[
                              // !fulltext && !isOpac
                              !fulltext && !isOpac
                                ? webStyle.checkedStyle
                                : webStyle.unCheckedStyle,
                              {
                                ":hover": {
                                  // background: !fulltext ? Colors.primary : "#fff",
                                  background: !fulltext
                                    ? Colors.primary
                                    : "#fff",
                                  opacity: 0.85,
                                },
                              },
                              {
                                whiteSpace: "nowrap",
                                pl: { xs: "1.5rem", sm: "24px", md: "24px" },
                                pr: { xs: "1.5rem", sm: "24px", md: "24px" },
                                width: { xs: "50%", sm: "auto" },
                              },
                            ]}
                          >
                            {"  "}All{" "}
                            {textCount.all >= 0
                              ? "(" +
                                textCount.all?.toLocaleString("en-US") +
                                ")"
                              : ""}
                            {"  "}
                          </ToggleButton>
                        </ToggleButtonGroup>
                      )}
                      {/* {!isOpac && customerDetails?.informaticscustomer_id == 18 && (
                    <ToggleButtonGroup
                      onChange={handleChange}
                      color="primary"
                      exclusive
                      aria-label="Platform"
                      size="small"
                      sx={{ marginLeft: "6px" }}
                    >
                      <ToggleButton
                        value="dspace"
                        sx={[
                          !isOpac && isDSpace
                            ? webStyle.checkedStyle
                            : webStyle.unCheckedStyle,
                          {
                            ":hover": {
                              background: isDSpace ? Colors.primary : "#fff",
                              opacity: 0.85,
                            },
                          },
                        ]}
                      >
                        DSpace(IR)
                      </ToggleButton>
                    </ToggleButtonGroup>
                  )} */}
                      {(isOpac || isOpac_dspace) && (
                        <ToggleButtonGroup
                          onChange={handleChange}
                          color="primary"
                          exclusive
                          aria-label="Platform"
                          size="small"
                        >
                          <ToggleButton
                            value="opac"
                            sx={[
                              isOpac
                                ? webStyle.checkedStyle
                                : webStyle.unCheckedStyle,
                              {
                                ":hover": {
                                  background: isOpac ? Colors.primary : "#fff",
                                  opacity: 0.85,
                                },
                              },
                            ]}
                          >
                            Library Catalogue{" "}
                            {opacSearchResults?.hits
                              ? "(" +
                                opacSearchResults?.hits.toLocaleString(
                                  "en-US"
                                ) +
                                ")"
                              : ""}
                          </ToggleButton>
                        </ToggleButtonGroup>
                      )}

                      {(isDSPACE || isOpac_dspace) && (
                        <ToggleButtonGroup
                          onChange={handleChange}
                          color="primary"
                          exclusive
                          aria-label="Platform"
                          size="small"
                          sx={{ marginLeft: "10px" }}
                        >
                          <ToggleButton
                            value="DSPACE"
                            sx={[
                              isDSPACE
                                ? webStyle.checkedStyle
                                : webStyle.unCheckedStyle,
                              {
                                ":hover": {
                                  background: isDSPACE
                                    ? Colors.primary
                                    : "#fff",
                                  opacity: 0.85,
                                },
                              },
                            ]}
                          >
                            IR/DSPACE
                            {searchResults?.hits
                              ? " (" +
                                searchResults?.hits.toLocaleString("en-US") +
                                ")"
                              : ""}
                          </ToggleButton>
                        </ToggleButtonGroup>
                      )}
                    </Box>

                    <Box>
                      {!isOpac && !isDSPACE && (
                        <Box
                          sx={{
                            display: { xs: "none", sm: "flex" },
                            gap: { xs: 1, sm: 1, md: 2 },
                            ml: { xs: "15px", sm: "15px", md: "0px" },
                            pr: { xs: "15px", sm: 0, md: 0 },
                          }}
                        >
                          <Box sx={{ display: "flex", gap: 1 }}>
                            <AnalyticsDropdown
                              facets={searchResults?.jsonfacets}
                              disable={textCount?.all === 0}
                              isAuthorFinder={false}
                            />
                            <SortByRelevence
                              disable={textCount?.all === 0}
                              // sortData={
                              //   searchResults?.jsonfacets?.filter_metrix?.buckets
                              // }
                              sortData={otherFacetData?.filter_metrix?.buckets}
                            />
                          </Box>
                          <Box
                            sx={{
                              display: {
                                xs: "none",
                                sm: "flex",
                                md: "none",
                                lg: "none",
                                xl: "none",
                              },
                            }}
                          >
                            <Stack>
                              <IconButton
                                sx={{
                                  display: {
                                    xs: "initial",
                                    sm: "initial",
                                    md: "none",
                                  },
                                  mr: "15px",
                                }}
                                onClick={() => {
                                  setFilterDrawer(true);
                                }}
                                size="small"
                                color="primary"
                              >
                                <FilterAlt />
                              </IconButton>
                            </Stack>
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: { xs: "flex", sm: "none" },
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      height: "38px",
                    }}
                  >
                    <Stack>
                      <Typography
                        fontWeight={"700"}
                        fontSize={{ xs: "16px", sm: "18px" }}
                        lineHeight={"24px"}
                        sx={{
                          display: {
                            xs: "initial",
                            sm: "initial",
                            md: "none",
                          },
                          mr: "10px",
                          ml: "15px",
                        }}
                      >
                        Results{" "}
                        {isDSpace
                          ? searchResults?.hits?.toLocaleString("en-US")
                          : isOpac
                          ? opacSearchResults.hits?.toLocaleString("en-US")
                          : filterReducer?.libraryOpac
                          ? searchResults?.hits
                          : textCount?.all
                          ? filterReducer?.libraryOpac
                            ? searchResults?.hits?.toLocaleString("en-US")
                            : textCount?.all?.toLocaleString("en-US")
                          : searchResults?.hits?.toLocaleString("en-US")}
                      </Typography>
                    </Stack>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                      }}
                    >
                      {!isOpac && !isDSPACE && (
                        <Box
                          sx={{
                            display: { xs: "flex", sm: "none" },
                            gap: { xs: 2, sm: 1, md: 2 },
                            ml: { xs: "10px", sm: "15px", md: "0px" },
                            pr: { xs: "10px", sm: 0, md: 0 },
                          }}
                        >
                          <Box sx={{ display: "flex", gap: 1 }}>
                            <AnalyticsDropdown
                              facets={searchResults?.jsonfacets}
                              disable={textCount?.all === 0}
                              isAuthorFinder={false}
                            />
                            <SortByRelevence
                              disable={textCount?.all === 0}
                              // sortData={
                              //   searchResults?.jsonfacets?.filter_metrix?.buckets
                              // }
                              sortData={otherFacetData?.filter_metrix?.buckets}
                            />
                          </Box>
                        </Box>
                      )}

                      <Stack>
                        <IconButton
                          sx={{
                            display: {
                              xs: "initial",
                              sm: "initial",
                              md: "none",
                            },
                            mr: "15px",
                          }}
                          onClick={() => {
                            setFilterDrawer(true);
                          }}
                          size="small"
                          color="primary"
                        >
                          <FilterAlt />
                        </IconButton>
                      </Stack>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: {
                        xs: "none",
                        sm: "flex",
                        md: "none",
                        lg: "none",
                        xl: "none",
                      },
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginTop: "0.3rem",
                    }}
                  >
                    <Stack>
                      <Typography
                        fontWeight={"700"}
                        fontSize={{ xs: "16px", sm: "18px" }}
                        lineHeight={"24px"}
                        sx={{
                          display: {
                            xs: "initial",
                            sm: "initial",
                            md: "none",
                          },
                          mr: "10px",
                          ml: "15px",
                        }}
                      >
                        Results{" "}
                        {isDSpace
                          ? searchResults?.hits?.toLocaleString("en-US")
                          : isOpac
                          ? opacSearchResults.hits?.toLocaleString("en-US")
                          : filterReducer?.libraryOpac
                          ? searchResults?.hits
                          : textCount?.all
                          ? filterReducer?.libraryOpac
                            ? searchResults?.hits?.toLocaleString("en-US")
                            : textCount?.all?.toLocaleString("en-US")
                          : searchResults?.hits?.toLocaleString("en-US")}
                      </Typography>
                    </Stack>
                  </Box>
                  <Box
                    sx={{
                      display: { xs: "flex", sm: "flex", md: "none" },
                      width: { sm: "100%", md: "10%" },
                      alignItems: "center",
                      justifyContent: "space-between",
                      height: { xs: "17px", sm: "20px" },
                    }}
                  >
                    {" "}
                    {!isOpac && !isDSPACE && (
                      <>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          {" "}
                          <Checkbox
                            sx={{ ml: "5px" }}
                            onChange={selectAllArticles}
                            checked={markResult}
                            inputProps={{ "aria-label": "controlled" }}
                            disabled={isDSpace}
                            size={isXs ? "medium" : "medium"}
                          ></Checkbox>
                          <Typography
                            sx={{
                              alignSelf: "center",
                              marginRight: "4%",
                              color: Colors.primary,
                              fontSize: {
                                xs: "0.879rem",
                                sm: "1rem",
                                md: "0.8rem",
                                lg: "0.875rem",
                                xl: "0.875rem",
                              },
                              cursor: "pointer",
                              flexShrink: 0,
                            }}
                            onClick={() => {
                              if (!markedArticleCount.length || isDSpace)
                                return;
                              navigate("/marked-result");
                            }}
                          >
                            {markedArticleCount?.length
                              ? `View Marked Results`
                              : "Mark Results"}
                            {markedArticleCount?.length > 0
                              ? "(" + markedArticleCount?.length + ")"
                              : ""}
                          </Typography>
                        </Box>
                      </>
                    )}
                  </Box>
                </Box>
              )}

              {/*/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
              BODY (ARTICLE CARD)
              //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/}
              <Box
                sx={{
                  marginTop: { xs: "0px", sm: "0px", md: "-10px" },
                }}
              >
                {Array.isArray(searchResults?.docs) && !isOpac ? (
                  searchResults?.docs?.map((data: any, i: number) => {
                    return (
                      <Box key={i}>
                        <CardItem
                          data={data}
                          getMarkedResult={getMarkedResult}
                          markedArticlesid={markedData}
                          searchTerm={searchTerm.toString()}
                          resultFrom="searchResult"
                        >
                          {/***********************************************************************************************************************************************
                          Title
                        ***********************************************************************************************************************************************/}
                          <CardContent sx={{ padding: "0px 14px" }}>
                            {data?.title && (
                              <Text
                                onClick={() => handleAbstractFullScreen(data)}
                                fontWeight={{ xs: 700, sm: 600 }}
                                fontFamily={"Lora"}
                                sx={{
                                  textAlign: "left",
                                  color: "#1A1A1A",
                                  cursor: "pointer",
                                  fontSize: { xs: ".9rem", sm: "1.125rem" },
                                }}
                                component={"div"}
                              >
                                <MathJax>
                                  <div
                                    id="title"
                                    dangerouslySetInnerHTML={{
                                      __html: keywordHighlight(
                                        keywordData,
                                        searchTerm,
                                        data?.article_id,
                                        data?.title
                                      ),
                                    }}
                                  />
                                </MathJax>
                              </Text>
                            )}
                          </CardContent>

                          {/***********************************************************************************************************************************************
                          Author Details
                        ***********************************************************************************************************************************************/}
                          {data?.data_type === dataTypes.journalArticle ||
                          data?.data_type === dataTypes.journals ||
                          data?.data_type === dataTypes.bookSeries ||
                          data?.data_type === dataTypes.dissertationThesis ||
                          data?.data_type === dataTypes.preprint ||
                          data?.data_type === dataTypes.conferenceProceedings ||
                          data?.data_type === dataTypes.magazine ||
                          data?.data_type === dataTypes.books ||
                          data?.data_type === dataTypes.dataset ||
                          data?.data_type === dataTypes.dspaceThesis ||
                          data?.data_type === dataTypes.dspaceBookChapter ||
                          data?.data_type === dataTypes.dspaceBook ||
                          data?.data_type === dataTypes.dspaceDataTypeNull ||
                          data?.data_type === dataTypes.patents ||
                          isOpac ||
                          data?.data_type === dataTypes.audioVideo ? (
                            <CardContent
                              sx={{ display: "flex", padding: "0 14px" }}
                            >
                              {Array.isArray(
                                data?.data_type === dataTypes.books
                                  ? data?.authors_tk
                                  : data?.author_details
                              ) &&
                                !isStringOfArray(
                                  data?.data_type === dataTypes.books
                                    ? data?.authors_tk
                                    : data?.author_details
                                ) &&
                                sortByAuthorRole(
                                  sortAuthorDetail(
                                    data?.data_type === dataTypes.books
                                      ? data?.authors_tk
                                      : data?.author_details
                                  ),
                                  data?.data_type ===
                                    dataTypes.dissertationThesis
                                )?.map((obj: any, i) => {
                                  const firstName = obj?.author_fname
                                    ? obj?.author_fname
                                    : "";
                                  const lastName = obj?.author_lname
                                    ? obj?.author_lname
                                    : "";

                                  const reversedName = lastName
                                    ? lastName + ", " + firstName
                                    : firstName;
                                  const displayName = lastName
                                    ? lastName.replace(/,/g, " ") +
                                      ", " +
                                      firstName.replace(/,/g, " ")
                                    : firstName.replace(/,/g, " ");

                                  if (i > (isXs ? 1 : 3)) return null;

                                  return (
                                    <Box
                                      display={"flex"}
                                      flexDirection={"row"}
                                      justifyContent={"space-between"}
                                      alignItems={"center"}
                                    >
                                      {i < (isXs ? 1 : 3) ? (
                                        <>
                                          <Tooltip
                                            title={
                                              authorRole[obj?.datauthorrole_id]
                                            }
                                          >
                                            <Button
                                              onClick={() => {
                                                handleAuthor(
                                                  reversedName,
                                                  data,
                                                  obj
                                                );
                                              }}
                                              sx={{
                                                fontFamily: "Helvetica Neue",
                                                fontSize: {
                                                  xs: "14px",
                                                  sm: "14px",
                                                },
                                                textDecoration: "underline",
                                                padding:
                                                  i === 0
                                                    ? "8px 0px 6px 0px"
                                                    : "6px 8px",
                                              }}
                                            >
                                              {displayName}
                                            </Button>
                                          </Tooltip>
                                          {obj?.orc_id ? (
                                            <a
                                              href={`https://orcid.org/${obj?.orc_id}`}
                                              target="_blank"
                                              rel="noreferrer"
                                            >
                                              <img
                                                src={IdIcon}
                                                style={{
                                                  paddingLeft: "5px",
                                                  marginTop: "8px",
                                                  paddingRight: "6px",
                                                }}
                                                alt="IdIcon"
                                              />
                                            </a>
                                          ) : null}

                                          <Divider
                                            orientation="vertical"
                                            style={{
                                              height: "60%",
                                              alignSelf: "center",
                                            }}
                                          />
                                        </>
                                      ) : (
                                        <Button
                                          sx={{
                                            color: "#6B7280",
                                            textDecoration: "underline",
                                            fontSize: {
                                              xs: "14px",
                                              sm: "14px",
                                            },
                                          }}
                                          onClick={() => {
                                            isXs
                                              ? handleAbstractFullScreen(data)
                                              : handleDawer(data);
                                          }}
                                        >
                                          +
                                          {data.author_details?.length -
                                            (isXs ? 1 : 3)}{" "}
                                          More
                                        </Button>
                                      )}
                                    </Box>
                                  );
                                })}
                              {Array.isArray(
                                data?.data_type === dataTypes.books
                                  ? data?.authors_tk
                                  : data?.author_details
                              ) &&
                                isStringOfArray(
                                  data?.data_type === dataTypes.books
                                    ? data?.authors_tk
                                    : data?.author_details
                                ) &&
                                sortAuthor(
                                  data?.data_type === dataTypes.books
                                    ? data?.authors_tk
                                    : data?.author_details
                                )?.map((name: string, i: number) => {
                                  if (i > (isXs ? 1 : 3)) return null;
                                  return (
                                    <Box
                                      key={i}
                                      display={"flex"}
                                      flexDirection={"row"}
                                      justifyContent={"space-between"}
                                    >
                                      {i < (isXs ? 1 : 3) ? (
                                        <>
                                          {name?.length ? (
                                            <Button
                                              onClick={() =>
                                                !isDSPACE &&
                                                handleAuthor(name, data)
                                              }
                                              sx={{
                                                fontFamily: "Helvetica Neue",
                                                fontSize: {
                                                  xs: "14px",
                                                  sm: "14px",
                                                },
                                                textDecoration: !isDSPACE
                                                  ? "underline"
                                                  : "none",
                                                padding:
                                                  i === 0
                                                    ? "8px 8px 6px 0px"
                                                    : "6px 8px",
                                                color: !isDSPACE
                                                  ? Colors.primary
                                                  : "black",
                                                cursor: !isDSPACE
                                                  ? "pointer"
                                                  : "default",
                                              }}
                                            >
                                              {name.length
                                                ? name
                                                    .trim()
                                                    .replace(/^\,+|\,+$/g, "")
                                                    .trim()
                                                : ""}{" "}
                                            </Button>
                                          ) : null}

                                          <Divider
                                            orientation="vertical"
                                            style={{
                                              height: "60%",
                                              alignSelf: "center",
                                            }}
                                          />
                                        </>
                                      ) : (
                                        <Button
                                          sx={{
                                            color: "#6B7280",
                                            textDecoration: "underline",
                                            fontSize: {
                                              xs: "14px",
                                              sm: "14px",
                                            },
                                          }}
                                          onClick={() => {
                                            isXs
                                              ? handleAbstractFullScreen(data)
                                              : handleDawer(data);
                                          }}
                                        >
                                          +
                                          {data?.data_type ===
                                            dataTypes.books &&
                                          !data?.has_chapter
                                            ? data.editors
                                              ? data.editors?.length -
                                                (isXs ? 1 : 3)
                                              : data.editor_details?.length -
                                                (isXs ? 1 : 3)
                                            : data.authors
                                            ? data.authors?.length -
                                              (isXs ? 1 : 3)
                                            : data.author_details?.length -
                                              (isXs ? 1 : 3)}{" "}
                                          More
                                        </Button>
                                      )}
                                    </Box>
                                  );
                                })}
                            </CardContent>
                          ) : null}
                          {data?.has_chapter && (
                            <CardContent
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                padding: "4px 14px",
                              }}
                            >
                              {" "}
                              <Text
                                component={"div"}
                                sx={{
                                  textAlign: "left",
                                  fontSize: {
                                    xs: "15px",
                                    sm: "16px",
                                  },
                                  color: Colors.primary,
                                  fontStyle: "italic",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  handleBoooks(data, i);
                                }}
                              >
                                {data?.book_name}
                              </Text>
                            </CardContent>
                          )}
                          {/***********************************************************************************************************************************************
                          Journal name, Data, Year
                        ***********************************************************************************************************************************************/}
                          {data?.data_type === dataTypes.journalArticle ||
                          data?.data_type === dataTypes.journals ||
                          data?.data_type === dataTypes.bookSeries ||
                          data?.data_type === dataTypes.dissertationThesis ||
                          data?.data_type === dataTypes.preprint ||
                          data?.data_type === dataTypes.conferenceProceedings ||
                          data?.data_type === dataTypes.magazine ||
                          data?.data_type === dataTypes.dspaceThesis ||
                          data?.data_type === dataTypes.dspaceBookChapter ||
                          data?.data_type === dataTypes.dspaceBook ||
                          data?.data_type === dataTypes.dspaceDataTypeNull ||
                          data?.data_type === dataTypes.books ||
                          data?.data_type === dataTypes.dataset ||
                          data?.data_type === dataTypes.patents ||
                          data?.data_type === dataTypes.audioVideo ? (
                            <CardContent
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                padding: "4px 14px",
                                alignItems: { xs: "start", sm: "center" },
                              }}
                            >
                              {data?.data_type ===
                                dataTypes.dissertationThesis ||
                              data?.data_type === dataTypes.audioVideo ||
                              data?.data_type === dataTypes.preprint ||
                              data?.data_type === dataTypes.magazine ||
                              data?.data_type ===
                                dataTypes.conferenceProceedings ||
                              data?.data_type === dataTypes.dspaceThesis ||
                              data?.data_type === dataTypes.dspaceBookChapter ||
                              data?.data_type === dataTypes.dspaceBook ||
                              data?.data_type ===
                                dataTypes.dspaceDataTypeNull ||
                              data?.data_type === dataTypes.dataset ||
                              data?.data_type === dataTypes.patents ||
                              data?.data_type === dataTypes.books ? null : (
                                <Box
                                  pr={"0.8rem"}
                                  mt={{ xs: "-6px", sm: "-29px", md: "0px" }}
                                >
                                  <Tooltip
                                    //title="Set Alert"
                                    title={
                                      findAlertTypeSetting(
                                        data?.resourcemaster_id
                                      )
                                        ? "Modify Alert"
                                        : "Set Alert"
                                    }
                                    onClick={() => {
                                      if (profileStatus === "true") {
                                        handleOpenViewModal(data);
                                      } else {
                                        showIsProfileToast(
                                          "Please login as profile user to set alert"
                                        );
                                      }
                                    }}
                                  >
                                    <IconButton
                                      sx={{
                                        "&:hover": {
                                          color: "#F48120",
                                        },
                                        color: findAlertTypeSetting(
                                          data?.resourcemaster_id
                                        )
                                          ? "#F48120"
                                          : "",
                                        padding: 0,
                                      }}
                                    >
                                      <RssFeedIcon fontSize="small"></RssFeedIcon>
                                    </IconButton>
                                  </Tooltip>
                                </Box>
                              )}
                              <Text
                                component={"div"}
                                sx={{
                                  textAlign: "left",
                                  // pt:{xs:.75,sm:0,md:0},
                                  fontSize: { xs: "14px", sm: "16px" },
                                  // color: Colors.primary,
                                  color: !isDSPACE ? Colors.primary : "black",
                                  fontStyle: "italic",
                                  cursor: !isDSPACE ? "pointer" : "default",
                                }}
                                onClick={() =>
                                  !isDSPACE && handleResource(data, i)
                                }
                              >
                                {metaData(data, isXs)}
                              </Text>
                            </CardContent>
                          ) : null}

                          {/***********************************************************************************************************************************************
                          Footer Section
                        ***********************************************************************************************************************************************/}
                          <CardActions
                            disableSpacing
                            style={{ justifyContent: "space-between" }}
                            sx={{
                              padding: "4px 14px",
                            }}
                          >
                            {/* <Box></Box> */}
                            <Stack direction="row" spacing={2}>
                              <Stack direction={"row"} gap={1}>
                                <Button
                                  onClick={() => {
                                    isXs
                                      ? handleAbstractFullScreen(data)
                                      : handleDawer(data);
                                  }}
                                  size="small"
                                  sx={{
                                    display: { xs: "none", sm: "flex" },
                                    textDecoration: "underline",
                                    color: "#000",
                                    fontSize: { xs: "11px", sm: "14px" },
                                  }}
                                >
                                  Read More
                                </Button>
                                {data?.has_datasets ? (
                                  <Tooltip title="Dataset">
                                    <IconButton
                                      sx={{ padding: 0 }}
                                      onClick={() => {
                                        logBasicSearchUsageData(37, data);
                                        handleDataSets(data);
                                      }}
                                    >
                                      <img
                                        src={DatasetIcon}
                                        alt="DatasetIcon"
                                      />
                                    </IconButton>
                                  </Tooltip>
                                ) : (
                                  ""
                                )}
                                {/* is_retracted */}
                                {data?.is_retracted ? (
                                  <Tooltip title="Retracted Article">
                                    <img
                                      src={RetractedIcon}
                                      alt="RetractedIcon"
                                    />
                                  </Tooltip>
                                ) : (
                                  ""
                                )}
                              </Stack>
                            </Stack>

                            <Box display={"flex"} gap={1}>
                              {accessTypeButtons(data, customerId) ===
                                "RedClosedLock" &&
                                // !== "GreenLock" &&
                                // accessTypeButtons(data, customerId) !==
                                //   "RedClosedLock" &&
                                data?.has_preprint &&
                                !(
                                  data?.is_onos_resource === true &&
                                  isEnableONOS
                                ) && (
                                  <Tooltip title="View Pre-Print">
                                    <Button
                                      onClick={() =>
                                        logBasicSearchUsageData(35, data)
                                      }
                                      href={data?.preprint_link}
                                      rel="noopener noreferrer"
                                      target="_blank"
                                      sx={{ minWidth: 0 }}
                                      size={isXs ? "small" : "medium"}
                                    >
                                      <img
                                        src={PreprintIcon}
                                        alt="PreprintIcon"
                                      />
                                    </Button>
                                  </Tooltip>
                                )}
                              {!data?.fulltext &&
                                !(
                                  data?.is_onos_resource === true &&
                                  isEnableONOS
                                ) &&
                                getButtonType(data?.article_id)?.includes(
                                  "request_article"
                                ) && (
                                  <Button
                                    variant="outlined"
                                    onClick={async () => {
                                      navigate("DdrScreen", {
                                        state: {
                                          data,
                                          resultFrom: "searchResult",
                                        },
                                      });
                                    }}
                                    size={isXs ? "small" : "medium"}
                                  >
                                    Request Article
                                  </Button>
                                )}

                              {!data?.fulltext &&
                                getButtonType(data?.article_id)?.includes(
                                  "available_in_library"
                                ) &&
                                !(
                                  data?.is_onos_resource === true &&
                                  isEnableONOS
                                ) && (
                                  <Button
                                    size="small"
                                    onClick={handleAvailableinLib}
                                    sx={{
                                      textDecoration: "underline",
                                      color: "#000",
                                    }}
                                  >
                                    Available in library
                                  </Button>
                                )}

                              {/* Button
                            onClick={async () => {
                              navigate("DdrScreen", {
                                state: { data },
                              });
                            }}
                          > */}

                              {getButtonType(data?.article_id)?.includes(
                                "find_in_library"
                              ) &&
                                !data?.fulltext &&
                                (data?.data_type === dataTypes.journalArticle ||
                                  data.data_type ===
                                    dataTypes.conferenceProceedings ||
                                  data.data_type === dataTypes.journals) &&
                                !(
                                  data?.is_onos_resource === true &&
                                  isEnableONOS
                                ) && (
                                  <Tooltip title="Find in Library">
                                    <Button
                                      sx={{ minWidth: 0 }}
                                      onClick={async () => {
                                        logBasicSearchUsageData(38, data);
                                        navigate("findlibrary", {
                                          state: { data },
                                        });
                                      }}
                                      size={isXs ? "small" : "medium"}
                                    >
                                      <img
                                        src={FindInLibraryIcon}
                                        alt="FindInLibraryIcon"
                                      />
                                    </Button>
                                  </Tooltip>
                                )}
                              {data?.pmcid ? (
                                <Button
                                  href={`https://www.ncbi.nlm.nih.gov/pmc/articles/${data?.pmcid}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  size={isXs ? "small" : "medium"}
                                >
                                  PubMed Link
                                </Button>
                              ) : (
                                ""
                              )}
                              {isDSPACE ? (
                                <DownloadButton
                                  link={data?.fulltext_url}
                                  articleData={data}
                                  resultFrom={"searchResult"}
                                />
                              ) : Xs ? (
                                (!data?.is_retracted ||
                                  !data?.has_datasets ||
                                  !data?.pmcid) && (
                                  <AccessLock
                                    data={data}
                                    linkToDisplay={linkToDisplay}
                                    resultFrom="searchResult"
                                  />
                                )
                              ) : (
                                <AccessLock
                                  data={data}
                                  linkToDisplay={linkToDisplay}
                                  resultFrom="searchResult"
                                />
                              )}
                              {isOpac && data?.details_url ? (
                                <Button
                                  href={data?.details_url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  size={isXs ? "small" : "medium"}
                                >
                                  View Details
                                </Button>
                              ) : null}
                            </Box>
                          </CardActions>
                          {data?.is_retracted &&
                            data?.has_datasets &&
                            data?.pmcid && (
                              <Box
                                display={{ xs: "flex", sm: "none" }}
                                gap={1}
                                justifyContent={"flex-end"}
                              >
                                {isDSPACE ? (
                                  <DownloadButton
                                    link={data?.fulltext_url}
                                    articleData={data}
                                    resultFrom={"searchResult"}
                                  />
                                ) : (
                                  <AccessLock
                                    data={data}
                                    linkToDisplay={
                                      linkToDisplay && linkToDisplay
                                    }
                                    resultFrom={"searchResult"}
                                  />
                                )}
                              </Box>
                            )}
                        </CardItem>
                      </Box>
                    );
                  })
                ) : (
                  <>
                    {Array.isArray(opacSearchResults?.docs) &&
                      isOpac &&
                      opacSearchResults?.docs?.map((data: any) => (
                        <LibraryCatalogueCard
                          data={data}
                          searchTerm={searchTerm}
                        />
                      ))}
                  </>
                )}
              </Box>

              <RSSAlertSearch
                openViewModal={openRSSViewModal}
                handleCloseViewModal={handleCloseViewModal}
                maxWidth={false}
                resourceData={rssdata}
                resourcetype={rssdata}
                alertTypeSetting={alertTypeSetting}
              />
              <NoResultModal
                isOpen={noresultModal}
                handleClose={closeNoResultModal}
              />

              <Box my={3} sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Pagination
                  count={isOpac ? opacCount : count}
                  handlePageChange={handlePageChange}
                  rowsPerResult={rows}
                  getRowsPerPage={getRowsPerPage}
                  currentPage={currentPage}
                />
                <Spinner isLoading={isLoading} />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <GotoBottomButton />
      <GotoTopButton />
      <RightDrawer
        dialogueData={dialogueData}
        handleDawer={handleDawer}
        state={top}
        linkToDisplay={linkToDisplay}
        authorEmailData={authorEmailData}
        setTop={setTop}
        resultFrom={"searchResult"}
      />
      <Drawer
        open={true}
        sx={{ display: FilterDrawer ? "block" : "none" }}
        ModalProps={{
          disableScrollLock: true,
        }}
      >
        <Box
          bgcolor={"#FFF"}
          width={"100%"}
          sx={{
            position: "sticky", // Sticks the header to the top when scrolling
            top: 0,
            zIndex: 1, // Ensure it appears above the scrolling content
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              alignSelf: "center",
              padding: "1%",
            }}
          >
            <Typography
              variant="h6"
              fontFamily={"Lora"}
              fontWeight={"700"}
              fontSize={"24px"}
            >
              Filters
            </Typography>
            <IconButton
              aria-label="delete"
              size="small"
              onClick={() => {
                setFilterDrawer(false);
              }}
            >
              <Clear />
            </IconButton>
          </Box>
        </Box>
        <Divider></Divider>
        <Box width={"100%"}>
          <Filters
            searchResults={searchResults}
            resultCount={
              filterReducer?.libraryOpac ? searchResults?.hits : textCount?.all
            }
            allCount={textCount?.all}
          />
        </Box>
      </Drawer>
    </Box>
  );
};

export default BasicSearchScreen;

const webStyle = {
  flexBox: {
    display: "flex",
    flexDirection: { xs: "column", sm: "column", md: "row" },
    justifyContent: "space-between",
    gap: 1,
    marginBottom: { xs: "60px", sm: "60px", md: "30px" },
  },
  leftBox: {
    width: "78%",
    backgroundColor: Colors.white,
    borderWidth: 1,
    borderColor: Colors.gray_1000,
    borderRadius: 2,
    marginTop: "3%",
    padding: "7%",
    boxShadow: 1,
  },
  titleHeader: {
    fontFamily: "Lora",
    fontWeight: 700,
    textAlign: "left",
    fontSize: "1.125rem",
  },
  checkedStyle: {
    backgroundColor: Colors.primary,
    color: Colors.white,
    minWidth: { xs: "40px", sm: "60px", md: "100px" },
    fontSize: { xs: "0.875rem", sm: "1rem", md: "13px" },
  },
  unCheckedStyle: {
    color: "#7852FB",
    backgroundColor: "#FFFFFF",
    minWidth: { xs: "40px", sm: "60px", md: "100px" },
    fontSize: { xs: "0.875rem", sm: "1rem", md: "13px" },
  },
};
