import { formatString, queryConstructor } from "../../api/formQuery";
import {
  authorJsonFacetQuery,
  basicSearch,
  basicSearchForCounts,
  basicSearchQuery,
  dSpaceSearchQuery,
  jsonFacetQuery,
  staticAuthorFacetData,
  staticFacetData,
  staticSubjectFacetData,
  subjectJsonFacetQuery,
} from "../../api/Search/BasicSearch.api";
import { updateQuery } from "../../store/slice/queryForCountSlice";
import { CustomerDetailsType, SaverArticlesType } from "../../types/interface";
import {
  getOpacData,
  getPersonalLibraryCollection,
  getPersonalLibraryCollectionSRP,
  personalCoreCount,
} from "../../api/Search/CollectionApi";
import { AppThunk } from "../../store";
import {
  updateLoadingState,
  clearMonthFromFilter,
} from "../../store/slice/filterSlice";
import {
  updateAlertSettingData,
  updateAllFacets,
  updateAuthorFacets,
  updateAuthorFiltersLoader,
  updateIsSettled,
  updateLinkToDisplay,
  updateLogSearch,
  updateOpacResults,
  updateOtherFiltersLoader,
  updateSavedArticles,
  updateSearchResults,
  updateSubjectFacets,
  updateSubjectFilterLoader,
} from "../../store/slice/searchSlice";
import { getBulkLinkRecords } from "../../api/linkResolverApi";
import { checkMultipleArticleExistence } from "../../api/MyLibrary/MyFolders.api";
import { getAlertSettings } from "../../api/MyLibrary/TOCAlerts.api";
import { setSearch } from "../../api/Search/SetSearch";
import { updateSetSearchQuery } from "../../store/slice/setSearch";
import { getParamsByName } from "../../utils/helper";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { notify } from "../../utils/Notify";

export const onSearch =
  (
    customQuery?: string,
    userDetail?: CustomerDetailsType | null,
    logSearchHistory = false
  ): AppThunk<any> =>
  async (dispatch, getState) => {
    dispatch(updateLoadingState(true));

    const searchReducer = getState().searchReducer;
    const filterReducer = getState().filterReducer;
    const customerData = getState().customer.customerDetails;
    const user_id = getState().login.user_id;
    const searchKeys = Object.keys(searchReducer);
    const filterKeys = Object.keys(filterReducer);
    const setSearchReducer = getState().setSearch;

    let sessionKey = sessionStorage.getItem("session_key");
    let userId = sessionStorage.getItem("user_id");
    let informaticscustomerId = sessionStorage.getItem(
      "informaticscustomer_id"
    );
    const customerId = customerData?.informaticscustomer_id;
    const consortia_virtualid = customerData?.consortia_virtualid;
    const urlSearchTerm = getParamsByName("searchterm") || getParamsByName("q");
    // const isDSpace = searchReducer.dSpace;

    dispatch(updateIsSettled(false));

    let searchQueryObj: any = {};
    let queryObj: any = {};
    let dataType: any = {};

    searchQueryObj.customerid = informaticscustomerId;
    searchQueryObj.sessionid = sessionKey;
    searchQueryObj.profileuserid = userId;

    //=============== Search Query ===================================//
    if (searchReducer?.logSearch) {
      searchQueryObj.sessionid = sessionStorage.getItem("session_key");
      searchQueryObj.profileuserid = sessionStorage.getItem("user_id");
      searchQueryObj.datsearchtype_id = "1";
      searchQueryObj.customerid = sessionStorage.getItem(
        "informaticscustomer_id"
      );
      dispatch(updateLogSearch(false));
    }

    if (searchKeys.includes("searchTerm")) {
      const includesTitleOrKeywords =
        /author_address_fz:|title_fz:|keywords_fz:|author_all_fz:|issn:|articledoi:|resourcemaster_id:|yearfrom:/.test(
          searchReducer?.searchTerm.join()
        );
      if (includesTitleOrKeywords) {
        const keywordString = searchReducer?.searchTerm
          .toString()
          .split(",")
          .join(" ");
        let modifiedSearchTerm = keywordString.split(/:(.*)/);
        searchQueryObj[modifiedSearchTerm[0]] = modifiedSearchTerm[1];
      } else {
        // searchQueryObj.titleKeywordsAbs = searchReducer?.searchTerm.length
        //   ? searchReducer?.searchTerm
        //   : urlSearchTerm;

        if (searchReducer?.searchTerm.length) {
          searchQueryObj.titleKeywordsAbs = searchReducer?.searchTerm;
        }
      }
    }
    if (searchKeys.includes("page")) {
      searchQueryObj.page = searchReducer.page;
    }

    if (searchKeys.includes("rows")) {
      searchQueryObj.rows = searchReducer.rows;
    }
    if (searchKeys.includes("authorName")) {
      searchQueryObj.authors = searchReducer.authorName;
    }

    if (searchKeys.includes("dataType")) {
      if (
        searchReducer.dataType.length &&
        !searchReducer.dataType.includes(0)
      ) {
        dataType.fq__resource_type = searchReducer.dataType;
      }
    }

    if (searchReducer.dataType.includes(0)) {
      dataType.fq__resource_type = searchReducer.defaultDataType;
    }

    if (
      (searchReducer.dataType.includes(100) &&
        searchReducer?.dataType.length === 1) ||
      (searchReducer.dataType.includes(101) &&
        searchReducer?.dataType.length === 1) ||
      (searchReducer.dataType.includes(101) &&
        searchReducer.dataType.includes(101) &&
        searchReducer?.dataType.length === 2)
    ) {
      dataType.fq__resource_type = searchReducer.defaultDataType;
    }

    if (searchKeys.includes("subjectType")) {
      if (searchReducer.subjectType.length) {
        dataType.fq__subjects_id_l2 = searchReducer.subjectType;
      }
    }

    searchQueryObj.op_mode = "and";

    if (searchReducer.advanceSearchQuery) {
      let refineSearchTerms = searchReducer.searchTerm;
      let query = "";
      if (refineSearchTerms.length) {
        refineSearchTerms.forEach((term) => {
          query += " AND " + term;
        });
      }
      let y = searchReducer.advanceSearchQuery.indexOf("=");
      let leftObj = searchReducer.advanceSearchQuery.slice(0, y);
      searchQueryObj[leftObj] =
        `${searchReducer.advanceSearchQuery.slice(y + 1)}` + query;
    }

    if (searchReducer.search_type_id) {
      searchQueryObj.datsearchtype_id = searchReducer.search_type_id;
    }
    //================================================================//

    let searchQuery = await queryConstructor(searchQueryObj, "AND", true);
    let dataTypeQuery = await queryConstructor(dataType);
    //=============== FIlter Query ===================================//

    if (filterKeys.includes("subject")) {
      queryObj.fq__subjects_name_l3 = filterReducer["subject"];
    }
    if (filterKeys.includes("sourceType")) {
      queryObj.fq__data_type = filterReducer["sourceType"];
    }
    if (filterKeys.includes("author")) {
      queryObj.fq__authors_tk = filterReducer["author"];
    }
    if (filterKeys.includes("speaker")) {
      queryObj.fq__speakers = filterReducer["speaker"];
    }
    if (filterKeys.includes("research")) {
      queryObj.fq__guide_name_tk = filterReducer["research"];
    }
    if (filterKeys.includes("researcher")) {
      queryObj.fq__researcher_tk = filterReducer["researcher"];
    }
    if (filterKeys.includes("journal")) {
      queryObj.fq__journal_name = filterReducer["journal"];
    }
    if (filterKeys.includes("countryOfPublication")) {
      queryObj.fq__primary_publisher_country =
        filterReducer["countryOfPublication"];
    }
    if (filterKeys.includes("publisher")) {
      queryObj.fq__publisher_name = filterReducer["publisher"];
    }
    if (filterKeys.includes("inventor")) {
      queryObj.fq__inventor = filterReducer["inventor"];
    }
    if (filterKeys.includes("institutions")) {
      queryObj.fq__author_address = filterReducer["institutions"];
    }
    if (filterKeys.includes("journalRank")) {
      queryObj.fq__filter_metrix = filterReducer["journalRank"];
    }
    if (filterKeys.includes("sort")) {
      queryObj.sort = filterReducer["sort"];
    }
    if (filterKeys.includes("countryOfPublishingAuthor")) {
      queryObj.fq__author_country_name =
        filterReducer["countryOfPublishingAuthor"];
    }
    if (filterKeys.includes("conference_name")) {
      queryObj.conference_name = filterReducer.conference_name;
    }
    if (filterKeys.includes("university_name")) {
      queryObj.fq__university_name = filterReducer.university_name;
    }
    if (filterKeys.includes("resourcemaster_id")) {
      queryObj.resourcemaster_id = filterReducer.resourcemaster_id;
    }

    if (filterKeys.includes("sourceIndex")) {
      if (filterReducer["sourceIndex"])
        queryObj.fq__pubmed_article = filterReducer["sourceIndex"];
    }
    if (filterKeys.includes("yearFrom")) {
      if (filterReducer.yearFrom) {
        // dispatch(clearMonthFromFilter())
        queryObj.fq__yearfrom = filterReducer.yearFrom;
      }
    }
    if (filterKeys.includes("monthFrom")) {
      if (filterReducer.monthFrom) {
        queryObj.fq__dateofpublication = filterReducer.monthFrom;
      }
    }
    if (filterKeys.includes("dateFrom")) {
      if (filterReducer.dateFrom) {
        queryObj.fq__dateofpublication = filterReducer.dateFrom;
      }
    }
    if (filterKeys.includes("openAccess")) {
      if (filterReducer.openAccess) {
        queryObj.fq__fulltext = filterReducer.openAccess;
      }
    }
    if (filterKeys.includes("retractedArticle")) {
      if (filterReducer.retractedArticle) {
        queryObj.fq__is_retracted = true;
      }
    }
    if (filterKeys.includes("datesetAvailability")) {
      if (filterReducer.datesetAvailability) {
        queryObj.fq__has_datasets = filterReducer.datesetAvailability;
      }
    }
    if (filterReducer.earlyOnline) {
      queryObj.fq__early_online = true;
    }

    // if the user only has the consortia access the consortia_filter query should append in all query
    const isOnlyConsortiaAccess = customerData?.product_type === "7";
    if (isOnlyConsortiaAccess) {
      queryObj.fq__acl_group = customerData.consortia_filter;
    }

    if (filterReducer.myLibraryCollection && customerData) {
      queryObj.fq__acl_group = customerData?.my_library_filter;
    }
    if (filterReducer.consortiaCollection && customerData) {
      queryObj.fq__acl_group = customerData.consortia_filter;
    }
    if (filterReducer.onosCollection) {
      queryObj.fq__is_onos_resource = true;
    }

    // To trigger the useeffect in basicsearchscreen.tsx
    if (filterReducer.myPersonalLibCollection) {
      queryObj.page = searchReducer.page;
    }

    //================ Count Query ===================================//
    let fullTextQuery = await queryConstructor({
      ...queryObj,
      ...dataType,
      // fq__fulltext: true,
      "fq__(fulltext": `true OR acl_group=(${
        informaticscustomerId ? informaticscustomerId : "0"
      }))`,
    });
    let allQuery = await queryConstructor({
      ...queryObj,
      ...dataType,
    });

    fullTextQuery += "&" + searchQuery;
    allQuery += "&" + searchQuery;

    if (customQuery) {
      fullTextQuery += "&" + searchQuery + "&" + customQuery;
      allQuery += "&" + searchQuery + "&" + customQuery;
    }
    if (filterReducer?.libraryOpac) {
      dispatch(updateSubjectFacets([]));
      dispatch(updateAuthorFacets([]));
      dispatch(updateAllFacets([]));
      let kohaQueryObj: any = {};

      Object.assign(kohaQueryObj, {
        search_keyword: searchReducer?.searchTerm,
      });
      Object.assign(kohaQueryObj, {
        customer_id: customerData?.informaticscustomer_id,
      });
      Object.assign(kohaQueryObj, { page_no: searchReducer.page });
      Object.assign(kohaQueryObj, { per_page: searchReducer.rows });
      Object.assign(kohaQueryObj, { year_limit: filterReducer.yearFrom });

      if (filterKeys.includes("author")) {
        kohaQueryObj.author_name = filterReducer["author"];
      }

      let kohaQuery = await queryConstructor(kohaQueryObj, "AND", true);

      const data: any = await getOpacData(kohaQuery);

      //dispatch(updateSearchResults(data?.data));
      dispatch(updateSubjectFacets(data?.data.jsonfacets));
      dispatch(updateAuthorFacets(data?.data.jsonfacets));
      dispatch(updateAllFacets(data?.data.jsonfacets));
      dispatch(updateOpacResults(data?.data));
      dispatch(updateLoadingState(false));
      return data?.data;
    } // if library opac is true return
    // To update the full text and all count
    dispatch(
      updateQuery({
        fullTextQuery: fullTextQuery,
        allTextQuery: allQuery,
      })
    );

    //================================================================//

    if (filterReducer?.dspace) {
      let timeout: any;
      const timeoutPromise = new Promise<never>(
        (_, reject) =>
          (timeout = setTimeout(() => {
            notify(
              "error",
              "We are currently unable to fetch results for your input due to a temporary issue with the API service.For further assistance, please contact your librarian"
            );
            reject(new Error("Request timed out"));
          }, 30000))
      );

      try {
        queryObj = {
          basic_search: formatString(searchReducer?.searchTerm),
          page_no: searchReducer.page,
          per_page: searchReducer.rows,
        };

        if (filterKeys.includes("subject")) {
          queryObj.fq__subjects_name_l3 = filterReducer["subject"];
        }
        if (filterKeys.includes("author")) {
          queryObj.fq__authors_tk = filterReducer["author"];
        }

        if (filterKeys.includes("yearFrom")) {
          if (filterReducer.yearFrom) {
            // dispatch(clearMonthFromFilter())
            queryObj.fq__yearfrom = filterReducer.yearFrom;
          }
        }

        if (filterKeys.includes("publisher")) {
          queryObj.fq__publisher_name = filterReducer["publisher"];
        }

        let filterQuery = await queryConstructor(queryObj);
        //const data = await dSpaceSearchQuery(filterQuery);
        // Race between search query and timeout
        const response = await Promise.race([
          dSpaceSearchQuery(filterQuery),
          timeoutPromise,
        ]);
        clearTimeout(timeout);
        dispatch(updateSubjectFacets(response?.jsonfacets));
        dispatch(updateAuthorFacets(response?.jsonfacets));
        dispatch(updateAllFacets(response?.jsonfacets));
        dispatch(updateSearchResults(response));
        //dispatch(updateSearchResults(data));
        dispatch(updateLoadingState(false));

        return response?.data;
      } catch (error) {
        console.log("error", error);
      }
    }

    let filterQuery = await queryConstructor(queryObj);
    if (!setSearchReducer.isSetSearchEnabled && searchReducer.fullText) {
      filterQuery += `&fq__(fulltext=true OR acl_group=(${
        informaticscustomerId ? informaticscustomerId : "0"
      }))`;
    }

    let query = searchQuery + "&" + filterQuery + "&" + dataTypeQuery + "&";
    if (customQuery) {
      query =
        searchQuery +
        "&" +
        filterQuery +
        "&" +
        dataTypeQuery +
        "&" +
        customQuery;
    }

    if (
      !filterReducer?.dspace &&
      logSearchHistory &&
      !setSearchReducer.setSearchQuery &&
      !filterReducer?.myPersonalLibCollection
    ) {
      // to log search history send true in third argument
      let x = query + "&logsearchhistory=true";
      let y = x.replace(
        `&fq__(fulltext=true OR acl_group=(${informaticscustomerId}))`,
        ""
      );
      y += "&datsearchtype_id=1";
      if (searchReducer.advanceSearchQuery) {
        y += "&datsearchtype_id=2";
      } else {
        y += "&datsearchtype_id=1";
      }
      // basicSearch(y);
      basicSearchQuery(y);
      //basicSearchForCounts(y)
    }

    if (
      logSearchHistory &&
      !setSearchReducer.setSearchQuery &&
      filterReducer?.myPersonalLibCollection
    ) {
      // to log search history send true in third argument
      let x = query + "&logsearchhistory=true";
      let y = x.replace(
        `&fq__(fulltext=true OR acl_group=(${informaticscustomerId}))`,
        ""
      );
      y += "&datsearchtype_id=1";
      if (searchReducer.advanceSearchQuery) {
        y += "&datsearchtype_id=2";
      } else {
        y += "&datsearchtype_id=1";
      }
      //getPersonalLibraryCollection(y);
      personalCoreCount(y);
    }

    if (
      filterReducer?.myPersonalLibCollection &&
      !setSearchReducer.setSearchQuery
    ) {
      dispatch(updateSubjectFilterLoader(true));
      dispatch(updateAuthorFiltersLoader(true));
      dispatch(updateOtherFiltersLoader(true));
      const data = await getPersonalLibraryCollectionSRP(query);
      !!data && dispatch(updateSearchResults(data));
      !!data && dispatch(updateLoadingState(false));
      dispatch(updateIsSettled(true));

      //Jsonfacets
      const subjectFacetData = await getPersonalLibraryCollectionSRP(
        query,
        staticSubjectFacetData
      );
      dispatch(updateSubjectFacets(subjectFacetData?.jsonfacets));
      dispatch(updateSubjectFilterLoader(false));
      const authorFacetData = await getPersonalLibraryCollectionSRP(
        query,
        staticAuthorFacetData
      );
      dispatch(updateAuthorFacets(authorFacetData?.jsonfacets));
      dispatch(updateAuthorFiltersLoader(false));
      const allFacetData = await getPersonalLibraryCollectionSRP(
        query,
        staticFacetData
      );
      dispatch(updateAllFacets(allFacetData?.jsonfacets));
      dispatch(updateOtherFiltersLoader(false));

      // !!data && dispatch(updateSearchResults(data));
      // !!data && dispatch(updateLoadingState(false));
      // dispatch(updateIsSettled(true));

      let dataArray = data?.docs;
      let formData = new FormData();

      let x = dataArray?.map((obj: any) => {
        let object: any = {
          customer_id: customerId,
          article_id: obj?.article_id,
          consortia_virtualid: consortia_virtualid,
          resource_type: obj?.resource_type,
          access_type: obj?.article_open_status || obj?.access_type_id || null,
          is_oa_article: obj?.fulltext || false,
        };

        if (obj?.articledoi) {
          object.article_doi = obj?.articledoi;
        }

        if (obj?.resourceissue_id) {
          object.resourceissue_id = obj?.resourceissue_id;
        }

        if (obj?.is_onos_resource) {
          object.is_onos_resource = true;
        }

        return object;
      });

      let formApiData = {
        data: x,
      };

      formData.append("detailed_json", JSON.stringify(formApiData));
      const response = await getBulkLinkRecords(formData);

      if (response?.message === "Ok") {
        dispatch(updateLinkToDisplay(response?.data));
      }

      return data;
    }
    let data;
    // If setSearch enabled call setSearch api otherwise articlecore api

    if (
      setSearchReducer.isSetSearchEnabled &&
      setSearchReducer.setSearchQuery
    ) {
      const queryObj: any = {
        set_number_in: `${setSearchReducer.setInNumber}`,
      };
      let setInNumber = await queryConstructor(queryObj);

      let arr = searchReducer.searchTerm;
      if (Array.isArray(arr) && arr.length) {
        arr.forEach((str) => {
          setInNumber += " AND " + str;
        });
      }
      let setQuery =
        filterQuery +
        "&op_mode=and&datsearchtype_id=1" +
        // dataTypeQuery +
        // "&" +
        // searchQuery +
         "&" +
        setInNumber;

      dispatch(updateSetSearchQuery(setQuery));
      if (logSearchHistory && filterReducer.myPersonalLibCollection) {
        data = await setSearch(setQuery, "myPersonal", "count", "false", true);
      } else if (logSearchHistory) {
        setSearch(setQuery, "all", "count", "false", true);
      }

      if (filterReducer.myPersonalLibCollection) {
        dispatch(updateSubjectFilterLoader(true));
        dispatch(updateAuthorFiltersLoader(true));
        dispatch(updateOtherFiltersLoader(true));
        data = await setSearch(setQuery, "myPersonal");
        dispatch(updateSearchResults(data));
        !!data && dispatch(updateLoadingState(false));
        //JsonFacets
        const subjectFacetData = await setSearch(
          setQuery,
          "myPersonal",
          "all",
          "true",
          false,
          "all",
          1
        );
        dispatch(updateSubjectFacets(subjectFacetData?.jsonfacets));
        dispatch(updateSubjectFilterLoader(false));
        const authorFacetData = await setSearch(
          setQuery,
          "myPersonal",
          "all",
          "true",
          false,
          "all",
          2
        );
        dispatch(updateAuthorFacets(authorFacetData?.jsonfacets));
        dispatch(updateAuthorFiltersLoader(false));
        const otherFacetData = await setSearch(
          setQuery,
          "myPersonal",
          "all",
          "true",
          false,
          "all",
          3
        );
        dispatch(updateAllFacets(otherFacetData?.jsonfacets));
        dispatch(updateOtherFiltersLoader(false));
      } else if (filterReducer.myLibraryCollection) {
        dispatch(updateSubjectFilterLoader(true));
        dispatch(updateAuthorFiltersLoader(true));
        dispatch(updateOtherFiltersLoader(true));
        data = await setSearch(setQuery, "myLibrary");
        dispatch(updateSearchResults(data));
        !!data && dispatch(updateLoadingState(false));
        //JsonFacets
        const subjectFacetData = await setSearch(
          setQuery,
          "myLibrary",
          "all",
          "true",
          false,
          "all",
          1
        );
        dispatch(updateSubjectFacets(subjectFacetData?.jsonfacets));
        dispatch(updateSubjectFilterLoader(false));
        const authorFacetData = await setSearch(
          setQuery,
          "myLibrary",
          "all",
          "true",
          false,
          "all",
          2
        );
        dispatch(updateAuthorFacets(authorFacetData?.jsonfacets));
        dispatch(updateAuthorFiltersLoader(false));
        const otherFacetData = await setSearch(
          setQuery,
          "myLibrary",
          "all",
          "true",
          false,
          "all",
          3
        );
        dispatch(updateAllFacets(otherFacetData?.jsonfacets));
        dispatch(updateOtherFiltersLoader(false));
      } else if (filterReducer.consortiaCollection) {
        dispatch(updateSubjectFilterLoader(true));
        dispatch(updateAuthorFiltersLoader(true));
        dispatch(updateOtherFiltersLoader(true));
        data = await setSearch(setQuery, "myConsortia");
        dispatch(updateSearchResults(data));
        !!data && dispatch(updateLoadingState(false));
        //JsonFacets
        const subjectFacetData = await setSearch(
          setQuery,
          "myConsortia",
          "all",
          "true",
          false,
          "all",
          1
        );
        dispatch(updateSubjectFacets(subjectFacetData?.jsonfacets));
        dispatch(updateSubjectFilterLoader(false));
        const authorFacetData = await setSearch(
          setQuery,
          "myConsortia",
          "all",
          "true",
          false,
          "all",
          2
        );
        dispatch(updateAuthorFacets(authorFacetData?.jsonfacets));
        dispatch(updateAuthorFiltersLoader(false));
        const otherFacetData = await setSearch(
          setQuery,
          "myConsortia",
          "all",
          "true",
          false,
          "all",
          3
        );
        dispatch(updateAllFacets(otherFacetData?.jsonfacets));
        dispatch(updateOtherFiltersLoader(false));
      } else if (
        searchReducer.fullText &&
        !filterReducer.myPersonalLibCollection
      ) {
        dispatch(updateSubjectFilterLoader(true));
        dispatch(updateAuthorFiltersLoader(true));
        dispatch(updateOtherFiltersLoader(true));
        data = await setSearch(setQuery, "fulltext");
        dispatch(updateSearchResults(data));
        !!data && dispatch(updateLoadingState(false));
        //JsonFacets
        const subjectFacetData = await setSearch(
          setQuery,
          "fulltext",
          "all",
          "true",
          false,
          "all",
          1
        );
        dispatch(updateSubjectFacets(subjectFacetData?.jsonfacets));
        dispatch(updateSubjectFilterLoader(false));

        const authorFacetData = await setSearch(
          setQuery,
          "fulltext",
          "all",
          "true",
          false,
          "all",
          2
        );

        dispatch(updateAuthorFacets(authorFacetData?.jsonfacets));
        dispatch(updateAuthorFiltersLoader(false));
        const otherFacetData = await setSearch(
          setQuery,
          "fulltext",
          "all",
          "true",
          false,
          "all",
          3
        );
        dispatch(updateAllFacets(otherFacetData?.jsonfacets));
        dispatch(updateOtherFiltersLoader(false));
      } else if (
        !searchReducer.fullText &&
        !filterReducer.myPersonalLibCollection
      ) {
        dispatch(updateSubjectFilterLoader(true));
        dispatch(updateAuthorFiltersLoader(true));
        dispatch(updateOtherFiltersLoader(true));
        data = await setSearch(setQuery);
        dispatch(updateSearchResults(data));
        !!data && dispatch(updateLoadingState(false));
        //JsonFacets
        const subjectFacetData = await setSearch(
          setQuery,
          "all",
          "all",
          "true",
          false,
          "all",
          1
        );
        dispatch(updateSubjectFacets(subjectFacetData?.jsonfacets));
        dispatch(updateSubjectFilterLoader(false));
        const authorFacetData = await setSearch(
          setQuery,
          "all",
          "all",
          "true",
          false,
          "all",
          2
        );
        dispatch(updateAuthorFacets(authorFacetData?.jsonfacets));
        dispatch(updateAuthorFiltersLoader(false));
        const otherFacetData = await setSearch(
          setQuery,
          "all",
          "all",
          "true",
          false,
          "all",
          3
        );
        dispatch(updateAllFacets(otherFacetData?.jsonfacets));
        dispatch(updateOtherFiltersLoader(false));
        
      }
    } else {
      // data = await basicSearch(query);
      data = await basicSearchQuery(query);
      dispatch(updateSubjectFilterLoader(true));
      dispatch(updateAuthorFiltersLoader(true));
      dispatch(updateOtherFiltersLoader(true));
      dispatch(updateSearchResults(data));
      dispatch(updateLoadingState(false));

      //Jsonfacets
      const subjectFacetData = await subjectJsonFacetQuery(query);
      dispatch(updateSubjectFacets(subjectFacetData?.jsonfacets));
      dispatch(updateSubjectFilterLoader(false));
      const authorFacetData = await authorJsonFacetQuery(query);
      dispatch(updateAuthorFacets(authorFacetData?.jsonfacets));
      dispatch(updateAuthorFiltersLoader(false));
      const allFacetData = await jsonFacetQuery(query);
      dispatch(updateAllFacets(allFacetData?.jsonfacets));
      dispatch(updateOtherFiltersLoader(false));
    }
     dispatch(updateSearchResults(data));
    !!data && dispatch(updateLoadingState(false));

    // Getting the logic for the access typebutton and find in library

    let dataArray = data?.docs;
    let formData = new FormData();

    let x = dataArray?.map((obj: any) => {
      let object: any = {
        // customer_id: customerId,
        customer_id: informaticscustomerId,
        article_id: obj?.article_id,
        consortia_virtualid: consortia_virtualid,
        resource_type: obj?.resource_type,
        access_type: obj?.article_open_status || obj?.access_type_id || null,
        is_oa_article: obj?.fulltext || false,
      };

      if (obj?.articledoi) {
        object.article_doi = obj?.articledoi;
      }

      if (obj?.resourceissue_id) {
        object.resourceissue_id = obj?.resourceissue_id;
      }

      if (obj?.is_onos_resource) {
        object.is_onos_resource = true;
      }

      return object;
    });

    let formApiData = {
      data: x,
    };

    formData.append("detailed_json", JSON.stringify(formApiData));
    if (!filterReducer?.dspace) {
      const response = await getBulkLinkRecords(formData);

      if (response?.message === "Ok") {
        dispatch(updateLinkToDisplay(response?.data));
      }
    }

    // Checking the article is already added ot folder
    const articleData = new FormData();

    const articleIds =
      Array.isArray(dataArray) && dataArray?.map((x: any) => x?.article_id);
    articleData.append("profile_id", user_id);
    articleData.append("article_ids", articleIds.toString());
    if (!filterReducer?.dspace) {
      const checkArticleExistance = await checkMultipleArticleExistence(
        articleData
      );

      const filter_is_saved =
        Array.isArray(checkArticleExistance) &&
        checkArticleExistance.filter(
          (x: SaverArticlesType) => x.is_saved !== false
        );
      dispatch(updateSavedArticles(filter_is_saved));
    }

    // get rss/email alert
    const resourcemaster_ids =
      Array.isArray(dataArray) &&
      dataArray
        ?.map((x: any) => x?.resourcemaster_id && x?.resourcemaster_id)
        ?.filter((y: any) => y !== undefined);
    if (Array.isArray(resourcemaster_ids)) {
      if (!filterReducer?.dspace) {
        const alertSettingData = await getAlertSettings(
          user_id,
          resourcemaster_ids
        );
        let alertDataRes = alertSettingData.data.data[0][0];
        dispatch(updateAlertSettingData(alertDataRes));
      }
    }
    dispatch(updateLoadingState(false));
    return data;
  };
