import { Control, Controller, UseFieldArrayRemove } from "react-hook-form";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import ListItemText from "@mui/material/ListItemText";
import Text from "@mui/material/Typography";
import { Stack } from "@mui/system";
import {
  SelectField,
  TextField as CustomTextField,
} from "../../components/formInputs";
import PlusIcon from "@mui/icons-material/AddCircleOutline";
import TrashIcon from "../../assets/img/trash-icon.png";
import { SelectChangeEvent } from "@mui/material/Select";
import { AppendType, QBFormValues } from "../../hooks/useQBFormField";
import { useEffect, useRef, useState } from "react";
import CustomInputSearch from "./CustomInputSearchHistory";
import { Autocomplete, Paper, TextField } from "@mui/material";
import {
  getAuthorSuggestionList,
  getAuthorSuggestionListFirstName,
} from "../../api/authorFinderApi";
import { updateDataType } from "../../store/slice/searchSlice";
import { temporaryQueryConstructor } from "./TemporaryQueryConstructor";
import { useAppSelector } from "../../store/hooks";
import { useDispatch } from "react-redux";
import { updateAuthorSubjectType } from "../../store/slice/authorFinderSlice";
import { useGetAutoComplete } from "../../api/Search/Autocomplete.api";

const conditonData: string[] = ["AND", "OR", "NOT"];
const criteriaData: string[] = [
  "Title/Abstract/Keyword",
  "Title",
  // "Keyword only",
  "Title/Keyword",
  "Publication Year",
  "Author",
  "Author Affiliation",
  "ISSN",
  "ISBN",
  "DOI",
  // "Journal",
];

interface QueryBuilderProps {
  setJournalResult: any;
  setJournal: any;
  index: number;
  qbField: any;
  append: AppendType;
  remove: UseFieldArrayRemove;
  control: Control<QBFormValues, any> | any;
  fields: any;
  setIndexOf: any;
  pageIndex: number;
  handleSubmit: any;
  selectedTags: any;
  selectedJournal?: string[];
  setCheckDoi: React.Dispatch<React.SetStateAction<boolean>>;
  checkDoi: boolean;
}

const QueryBuilder = ({
  setJournalResult,
  setJournal,
  index,
  qbField,
  append,
  remove,
  control,
  fields,
  setIndexOf,
  pageIndex,
  handleSubmit,
  selectedTags,
  setCheckDoi,
  checkDoi,
}: QueryBuilderProps) => {
  const [isPublication, setIsPublication] = useState(false);
  const [isAuthor, setIsAuthor] = useState(false);
  const [isSearch, setIsSearch] = useState(true);
  const [authorFName, setAuthorFName] = useState<any[]>([]);
  const [authorLName, setAuthorLName] = useState<any[]>([]);
  const [sTerm, setSTerm] = useState<any[]>([]);
  const [lastName, setLastName] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const dispatch = useDispatch();

  const [valueLastName, setValueLastName] = useState<any>("");
  const [valueFirstName, setValueFirstName] = useState<any>("");
  const [valueSearchTerm, setValueSearchTerm] = useState<any>("");
  //abort controllers
  const firstNameAbortControllerRef = useRef<AbortController | null>(null);
  const lastNameAbortControllerRef = useRef<AbortController | null>(null);
  const searchReducer = useAppSelector((state) => state.searchReducer);
  const customerData = useAppSelector(
    (state) => state.customer.customerDetails
  );

  let filteredSubjectIds: any[] = [];
  filteredSubjectIds =
    customerData?.subject?.map(
      ({ datsubjectmasterlevel2_id }) => datsubjectmasterlevel2_id
    ) || [];

  const handleSuggestionLastName = (event: any, newValueLName: any) => {
    let dataTypeValue: any = [];

    dispatch(updateDataType(selectedTags));

    if (searchReducer.dataType.includes(0)) {
      dataTypeValue = "( 1 OR 2 OR 3 OR 4 OR 8 OR 24 )";
    } else {
      dataTypeValue = searchReducer.dataType;
    }

    // dispatch(updateAuthorRow(15));
    // dispatch(updateAuthorPage(1));

    let encodedSubjects: string = "";
    // if (selectedSubjects.length === 0) {
    console.log(filteredSubjectIds, "testsss");
    encodedSubjects = `(${temporaryQueryConstructor(filteredSubjectIds)})`;
    // } else {
    //   encodedSubjects = `(${temporaryQueryConstructor(selectedSubjects)})`;
    // }

    let encodedTags = "";
    let tags = [...selectedTags];
    if (tags.includes(1)) tags.push(2);
    if (tags.includes(1) && !tags.includes(5)) tags.push(5);
    if (tags.includes(1) && !tags.includes(24)) tags.push(24);
    if (!tags.includes(1) && tags.includes(2))
      tags = tags.filter((tag) => tag !== 2);
    if (!tags.includes(1) && tags.includes(5))
      tags = tags.filter((tag) => tag !== 5);
    if (!tags.includes(1) && tags.includes(24))
      tags = tags.filter((tag) => tag !== 24);

    // if (tags.length == 8 && tags.includes(0)) {
    if (tags.length == 8 && tags.includes(0)) {
      encodedTags = "(1 OR 4 OR 2 OR 3 OR 8 OR 24 OR 5)";
    } else {
      encodedTags = `(${temporaryQueryConstructor(tags)})`;
    }

    if (tags.length === 0) encodedTags = "(1 OR 4 OR 2 OR 3 OR 8 OR 24 OR 5)";

    dispatch(updateAuthorSubjectType(encodedSubjects));
    dispatch(updateDataType(encodedTags));
    if (newValueLName.length < 1) {
      setAuthorLName([]);
      setValueLastName("");
      return;
    }

    setValueLastName(newValueLName);

    const loadUsersLastName = async () => {
      if (lastNameAbortControllerRef.current) {
        lastNameAbortControllerRef.current.abort();
      }

      const lastNameControllerRef = new AbortController();
      lastNameAbortControllerRef.current = lastNameControllerRef;

      const facet_prefix = newValueLName;
      const apiResponse = await getAuthorSuggestionList(
        facet_prefix,
        encodedSubjects,
        dataTypeValue,
        lastNameControllerRef.signal
      );
      let list: any = [];
      apiResponse?.data?.data?.grouped?.author_lastname?.groups?.map(
        // (ele: any) => list.push(Object.keys(ele)[0])
        (ele: any) => list.push(ele?.groupValue)
      );
      setAuthorLName(list);
    };
    loadUsersLastName();
  };

  const handleSuggestionFirstName = (event: any, newValueFName: any) => {
    let dataTypeValue: any = [];

    dispatch(updateDataType(selectedTags));

    if (searchReducer.dataType.includes(0)) {
      dataTypeValue = "( 1 OR 2 OR 3 OR 4 OR 8 OR 24 )";
    } else {
      dataTypeValue = searchReducer.dataType;
    }

    // dispatch(updateAuthorRow(15));
    // dispatch(updateAuthorPage(1));

    let encodedSubjects: string = "";
    encodedSubjects = `(${temporaryQueryConstructor(filteredSubjectIds)})`;

    let encodedTags = "";
    let tags = [...selectedTags];
    if (tags.includes(1)) tags.push(2);
    if (tags.includes(1) && !tags.includes(5)) tags.push(5);
    if (tags.includes(1) && !tags.includes(24)) tags.push(24);
    if (!tags.includes(1) && tags.includes(2))
      tags = tags.filter((tag) => tag !== 2);
    if (!tags.includes(1) && tags.includes(5))
      tags = tags.filter((tag) => tag !== 5);
    if (!tags.includes(1) && tags.includes(24))
      tags = tags.filter((tag) => tag !== 24);

    // if (tags.length == 8 && tags.includes(0)) {
    if (tags.length == 8 && tags.includes(0)) {
      encodedTags = "(1 OR 4 OR 2 OR 3 OR 8 OR 24 OR 5)";
    } else {
      encodedTags = `(${temporaryQueryConstructor(tags)})`;
    }

    if (tags.length === 0) encodedTags = "(1 OR 4 OR 2 OR 3 OR 8 OR 24 OR 5)";

    dispatch(updateAuthorSubjectType(encodedSubjects));
    dispatch(updateDataType(encodedTags));
    if (newValueFName.length < 1) {
      setValueFirstName("");
      setAuthorFName([]);
      return;
    }

    setValueFirstName(newValueFName);

    const loadUsersFirstName = async () => {
      if (firstNameAbortControllerRef.current) {
        firstNameAbortControllerRef.current.abort();
      }

      const firstNameAbortController = new AbortController();
      firstNameAbortControllerRef.current = firstNameAbortController;

      const facet_prefix = newValueFName;
      const apiResponse = await getAuthorSuggestionListFirstName(
        facet_prefix,
        encodedSubjects,
        dataTypeValue,
        firstNameAbortController.signal
      );

      let list: any = [];
      apiResponse?.data?.data?.grouped?.author_firstname?.groups?.map(
        // (ele: any) => list.push(Object.keys(ele)[0])
        (ele: any) => ele?.groupValue !== null && list.push(ele?.groupValue)
      );
      setAuthorFName(list);
    };

    loadUsersFirstName();
  };
  const { data: autoComplete } = useGetAutoComplete(searchTerm);
  const handleSuggestionSearchTerm = async (event: any, newSearchTerm: any) => {
    if (newSearchTerm.length <= 3) {
      setSTerm([]);
      return;
    }
    setSearchTerm(newSearchTerm);
  };

  useEffect(() => {
    if (autoComplete) {
      const arr = autoComplete?.map((x) => Object.keys(x)[0]);
      setSTerm(() => arr);
    }
  }, [autoComplete]);

  const addField = () => {
    if (!checkDoi) {
      append({
        qbIndex: index + 1,
        conditionField: "OR",
        criteriaField: "Title/Abstract/Keyword",
        searchTerm: "",
        start: false,
        contain: true,
        journalSelected: [],
        pageIndex: pageIndex,
        selectedJournal: [],
      });
    }
  };

  // Adjust criteriaData based on selectedTags
  if (selectedTags.includes(1)) {
    if (!criteriaData.includes("Journal")) {
      criteriaData.push("Journal");
    }
  } else {
    const indexToRemove = criteriaData.indexOf("Journal");
    if (indexToRemove > -1) {
      criteriaData.splice(indexToRemove, 1);
    }
  }

  const resetAutoCompleteFields = () => {
    setAuthorFName([]);
    setAuthorLName([]);
    setSTerm([]);
    setLastName("");
    setFirstName("");
    setSearchTerm("");
    setValueLastName("");
    setValueFirstName("");
    setValueSearchTerm("");
  };

  const deleteField = (currentIndex: number) => remove(currentIndex);
  const criteriaChange = ({
    target,
  }: SelectChangeEvent<HTMLInputElement> | any) => {
    resetAutoCompleteFields();
    if (
      target.value === "Title/Abstract/Keyword" ||
      target.value === "Title" ||
      target.value === "Title/Keyword"
    ) {
      setIsSearch(true);
    } else {
      setIsSearch(false);
    }
    if (target.value === "DOI") {
      setCheckDoi(true);
    } else {
      setCheckDoi(false);
    }
    if (target.value === "Journal") {
      setIndexOf(index);
      setJournal(true);
      setJournalResult();
    }
    target.value === "Author" ? setIsAuthor(true) : setIsAuthor(false);
    target.value === "Publication Year"
      ? setIsPublication(true)
      : setIsPublication(false);
  };

  useEffect(() => {
    console.log("tests", authorLName);
  }, [authorLName]);

  const lastNameControllerRef: any = useRef(null);
  const firstNameControllerRef: any = useRef(null);
  const searchTermControllerRef: any = useRef(null);

  const conditionChange = (
    e: SelectChangeEvent<HTMLInputElement>,
    name: string
  ) => console.log(e.target.value, fields);
  return (
    <Stack direction={"row"} alignItems={"flex-start"} sx={{ pl: "66px" }}>
      <Stack direction={"column"} marginTop={1} sx={{ gap: 2 }}>
        <Stack
          direction={"row"}
          alignItems={"end"}
          marginTop={1}
          sx={{
            gap: 2,
            flexDirection: {
              xs: "column",
              sm: "column",
              md: "row",
              lg: "row",
            },
          }}
        >
          {/* Criteria */}
          <Box sx={{ width: { xs: "230px", sm: "300px", lg: "300px" } }}>
            <Text fontWeight="500">Criteria</Text>

            <SelectField
              control={control}
              name={`myFields.${index}.criteriaField`}
              fullWidth
              handleChange={criteriaChange}
            >
              {criteriaData?.map((name) => (
                <MenuItem
                  key={name}
                  value={name}
                  disabled={checkDoi ? index > 0 : index > 0 && name === "DOI"}
                >
                  <ListItemText
                    primary={name}
                    sx={{ margin: 0 }}
                    onClick={() => {
                      criteriaChange({ target: { value: name } });
                    }}
                  />
                </MenuItem>
              ))}
            </SelectField>
          </Box>

          {/* From To fields */}
          {isPublication && (
            <>
              <Box>
                <Text>From</Text>
                <CustomTextField
                  control={control}
                  name={`myFields.${index}.from`}
                  placeholder="From"
                  inputProps={{ maxLength: 4 }}
                  rules={{ required: "required field" }}
                  onKeyPress={(e) => {
                    if (selectedTags?.length !== 0) {
                      if (e.key === "Enter") {
                        handleSubmit();
                      }
                    }
                  }}
                />
              </Box>

              <Box>
                <Text>To</Text>
                <CustomTextField
                  control={control}
                  name={`myFields.${index}.to`}
                  placeholder="To"
                  inputProps={{ maxLength: 4 }}
                  rules={{ required: "required field" }}
                  onKeyPress={(e) => {
                    if (selectedTags?.length !== 0) {
                      if (e.key === "Enter") {
                        handleSubmit();
                      }
                    }
                  }}
                />
              </Box>
            </>
          )}
          {isAuthor && (
            <>
              <Box>
                <Text>Last Name</Text>
                {/* <CustomTextField
                  control={control}
                  name={`myFields.${index}.author_lastname`}
                  placeholder="Last Name"
                  onKeyPress={(e) => {
                    if (selectedTags?.length !== 0) {
                      if (e.key === "Enter") {
                        handleSubmit();
                      }
                    }
                  }}
                /> */}

                <Autocomplete
                  sx={{
                    width: { xs: "230px", sm: "300px", lg: "300px" },
                    backgroundColor: "#FFF",
                  }}
                  value={valueLastName}
                  onInputChange={handleSuggestionLastName}
                  freeSolo
                  id="free-solo-2-demo"
                  disableClearable
                  options={authorLName}
                  onChange={(event1, newValue1) => {
                    setLastName(newValue1);
                    // Call the controller's onChange with a synthetic event so the control updates
                    if (lastNameControllerRef.current) {
                      lastNameControllerRef.current({
                        target: { value: newValue1 },
                      });
                    }
                  }}
                  PaperComponent={({ children }) => (
                    <Paper
                      className="author-name-xya"
                      elevation={8}
                      style={{
                        position: "absolute",
                        top: "100%",
                        left: 0,
                        right: 0,
                        minHeight: authorLName.length === 0 ? "0px" : "150px",
                        maxHeight: "calc(100vh - 200px)",
                        overflowY: "auto",
                      }}
                    >
                      {children}
                    </Paper>
                  )}
                  renderInput={(params) => (
                    <Controller
                      name={`myFields.${index}.author_lastname`}
                      control={control}
                      // rules={rules}
                      render={({
                        field: { onChange, value },
                        formState,
                        fieldState: { error },
                      }) => {
                        // assign the Controller's onChange to the ref so that it can be called from Autocomplete
                        lastNameControllerRef.current = onChange;
                        return (
                          <TextField
                            value={lastName}
                            {...params}
                            placeholder="Author Last Name"
                            type="text"
                            fullWidth
                            variant="outlined"
                            sx={{
                              width: { xs: "230px", sm: "300px", lg: "300px" },
                              backgroundColor: "#FFF",
                            }}
                            InputProps={{
                              ...params.InputProps,
                              type: "search",
                            }}
                            onChange={(e: any) => {
                              onChange(e);
                              setLastName(e.target.value);
                            }}
                            onKeyPress={(e) => {
                              if (
                                selectedTags?.length !== 0 &&
                                e.key === "Enter"
                              ) {
                                handleSubmit();
                              }
                            }}
                          />
                        );
                      }}
                    />
                  )}
                />
              </Box>

              <Box>
                <Text>First Name</Text>
                <Autocomplete
                  sx={{
                    width: { xs: "230px", sm: "300px", lg: "300px" },
                    backgroundColor: "#FFF",
                  }}
                  value={valueFirstName}
                  onInputChange={handleSuggestionFirstName}
                  freeSolo
                  id="free-solo-2-demo"
                  disableClearable
                  options={authorFName}
                  onChange={(event1, newValue1) => {
                    setFirstName(newValue1);
                    // Call the controller's onChange with a synthetic event so the control updates
                    if (firstNameControllerRef.current) {
                      firstNameControllerRef.current({
                        target: { value: newValue1 },
                      });
                    }
                  }}
                  PaperComponent={({ children }) => (
                    <Paper
                      className="author-name-xya"
                      elevation={8}
                      style={{
                        position: "absolute",
                        top: "100%",
                        left: 0,
                        right: 0,
                        minHeight: authorFName.length === 0 ? "0px" : "150px",
                        maxHeight: "calc(100vh - 200px)",
                        overflowY: "auto",
                      }}
                    >
                      {children}
                    </Paper>
                  )}
                  renderInput={(params) => (
                    <Controller
                      name={`myFields.${index}.author_firstname_fz`}
                      control={control}
                      // rules={rules}
                      render={({
                        field: { onChange, value },
                        formState,
                        fieldState: { error },
                      }) => {
                        // assign the Controller's onChange to the ref so that it can be called from Autocomplete
                        firstNameControllerRef.current = onChange;
                        return (
                          <TextField
                            value={firstName}
                            {...params}
                            placeholder="First Name"
                            type="text"
                            fullWidth
                            variant="outlined"
                            sx={{
                              width: { xs: "230px", sm: "300px", lg: "300px" },
                              backgroundColor: "#FFF",
                            }}
                            InputProps={{
                              ...params.InputProps,
                              type: "search",
                            }}
                            onChange={(e: any) => {
                              onChange(e);
                              setFirstName(e.target.value);
                            }}
                            onKeyPress={(e) => {
                              if (
                                selectedTags?.length !== 0 &&
                                e.key === "Enter"
                              ) {
                                handleSubmit();
                              }
                            }}
                          />
                        );
                      }}
                    />
                  )}
                />
              </Box>
            </>
          )}
          {/* Search Term */}
          {isSearch && (
            <Box
              width={600}
              sx={{
                width: { xs: "230px", sm: "300px", md: "300px", lg: "400px" },
              }}
            >
              <Text fontWeight="500" fontFamily={"Helvetica Neue"}>
                Search Term
              </Text>

              <Autocomplete
                sx={{
                  width: { xs: "230px", sm: "300px", lg: "300px" },
                  backgroundColor: "#FFF",
                }}
                value={valueSearchTerm}
                onInputChange={handleSuggestionSearchTerm}
                freeSolo
                id="free-solo-2-demo"
                disableClearable
                options={sTerm}
                onChange={(event1, newValue1) => {
                  setSearchTerm(newValue1);
                  // Call the controller's onChange with a synthetic event so the control updates
                  if (searchTermControllerRef.current) {
                    searchTermControllerRef.current({
                      target: { value: newValue1 },
                    });
                  }
                }}
                PaperComponent={({ children }) => (
                  <Paper
                    className="author-name-xya"
                    elevation={8}
                    style={{
                      position: "absolute",
                      top: "100%",
                      left: 0,
                      right: 0,
                      minHeight: sTerm.length === 0 ? "0px" : "150px",
                      // maxHeight: "calc(100vh - 200px)",
                      overflowY: "auto",
                    }}
                  >
                    {children}
                  </Paper>
                )}
                renderInput={(params) => (
                  <Controller
                    name={`myFields.${index}.searchTerm`}
                    control={control}
                    // rules={rules}
                    render={({
                      field: { onChange, value },
                      formState,
                      fieldState: { error },
                    }) => {
                      // assign the Controller's onChange to the ref so that it can be called from Autocomplete
                      searchTermControllerRef.current = onChange;
                      return (
                        <TextField
                          value={searchTerm}
                          {...params}
                          placeholder="Search Term"
                          type="text"
                          fullWidth
                          variant="outlined"
                          sx={{
                            width: { xs: "230px", sm: "300px", lg: "300px" },
                            backgroundColor: "#FFF",
                          }}
                          InputProps={{
                            ...params.InputProps,
                            type: "search",
                          }}
                          onChange={(e: any) => {
                            onChange(e);
                            setFirstName(e.target.value);
                          }}
                          onKeyPress={(e) => {
                            if (
                              selectedTags?.length !== 0 &&
                              e.key === "Enter"
                            ) {
                              handleSubmit();
                            }
                          }}
                        />
                      );
                    }}
                  />
                )}
              />
            </Box>
          )}

          {!isPublication && !isAuthor && !isSearch ? (
            <Box
              width={600}
              sx={{
                width: { xs: "230px", sm: "300px", md: "300px", lg: "400px" },
              }}
            >
              <Text fontWeight="500" fontFamily={"Helvetica Neue"}>
                Search Term
              </Text>
              <CustomInputSearch
                control={control}
                name={`myFields.${index}.searchTerm`}
                rules={
                  (index + 1) % 2 !== 0 && {
                    required: "Please enter a Search Term",
                  }
                }
                disabled={index > 0 && checkDoi}
                fullWidth
                // placeholder="$$ saved search and/or search term"
                onKeyPress={(e) => {
                  if (selectedTags?.length !== 0) {
                    if (e.key === "Enter") {
                      handleSubmit();
                    }
                  }
                }}
                // inputValue={
                //   fields[index]?.selectedJournal.length > 0
                //     ? fields[index]?.selectedJournal
                //     : ""
                // }
              />
            </Box>
          ) : (
            ""
          )}

          {/* Trash icon */}
          {index >= 2 && (
            <IconButton size="large" onClick={() => deleteField(index)}>
              <img src={TrashIcon} alt="j-gate search builder" />
            </IconButton>
          )}

          {/* Add icon */}
          {index === qbField.length - 1 && (
            <IconButton
              size="large"
              sx={{ paddingInline: "10px" }}
              onClick={addField}
            >
              <PlusIcon color="primary" />
            </IconButton>
          )}
        </Stack>

        {/* Condition */}
        {(index === 0 ||
          (qbField.length > 2 && index !== qbField.length - 1)) && ( // if its the first field or if there are more than 2 fields and its not the last field
          <Box
            sx={{
              width: { xs: "130px", sm: "150px", lg: "150px" },
              marginY: 1,
            }}
          >
            <SelectField
              control={control}
              name={`myFields.${index}.conditionField`}
              fullWidth
              handleChange={conditionChange}
            >
              {conditonData?.map((name) => (
                <MenuItem key={name} value={name}>
                  <ListItemText primary={name} sx={{ margin: 0 }} />
                </MenuItem>
              ))}
            </SelectField>
          </Box>
        )}
      </Stack>
    </Stack>
  );
};

export default QueryBuilder;
