import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Colors } from "../../utils/constants";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setModal } from "../../store/slice/modalSlice";
import { useEffect, useState, useCallback } from "react";
import { FacetsJSON } from "../../types/FacetsJSON.types";
import { usagelogApi } from "../../api/usageReportApi";
import {
  Box,
  IconButton,
  Menu,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import piechart from "../../assets/img/pie-chart.png";
import piechart1 from "../../assets/img/pie-chart1.png";
import piechart2 from "../../assets/img/pie-chart (2).png";

const analyticsDropDownData = [
  "Journal",
  // "Country of Publishing Author",
  "Author Country",
  // "Country of Publication",
  "Publication Country",
  "Institution",
  "Publishers",
  "Subject",
  "Year of Publication",
  "Researcher",
  "Speaker",
  "Research Guides",
];

const AnalyticsDropdown = ({
  facets,
  disable,
  CoAuthorList,
  isAuthorFinder,
}: {
  facets: FacetsJSON;
  disable?: boolean;
  CoAuthorList?: any;
  isAuthorFinder: boolean;
}) => {
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const profileData = useAppSelector((state) => state.login);

  const profileData1 = useAppSelector(
    (state) => state.customer.customerDetails
  );

  const subjectFacet: any = useAppSelector(
    (state) => state.searchReducer?.subjectFacets
  );
  const authorFacets: any = useAppSelector(
    (state) => state.searchReducer?.authorFacets
  );
  const otherFacets: any = useAppSelector(
    (state) => state.searchReducer?.allFacets
  );

  const selectedFilters = useAppSelector((state) => state.filterReducer);

  const is_ONOSCustomer = useAppSelector(
    (state: any) => state.customer.customerDetails?.is_enable_onos
  );

  const contextId =
    selectedFilters.collectionValue === "J-GateCollection"
      ? 1
      : selectedFilters.collectionValue === "ConsortiaSubscriptions"
      ? 2
      : selectedFilters.collectionValue === "MyLibraryCollection"
      ? 3
      : selectedFilters.collectionValue === "MyPersonalLibraryCollection"
      ? 4
      : selectedFilters.collectionValue === "ONOSCollections"
      ? 5
      : selectedFilters.collectionValue === "Library OPAC"
      ? 6
      : selectedFilters.collectionValue === "Open Access Collections"
      ? 7
      : null;

  const logBasicSearchUsageData = (usageActionID: any) => {
    const userId = sessionStorage.getItem("user_id");
    const informaticscustomer_Id = sessionStorage.getItem(
      "informaticscustomer_id"
    );
    const user_ipv4_address =
      sessionStorage.getItem("user_ipv4_address") || null;

    const userMasterId = profileData.user_id
      ? profileData.user_id
      : userId
      ? userId
      : null;
    const informaticscustomerId = profileData.informaticscustomer_id
      ? profileData.informaticscustomer_id
      : informaticscustomer_Id
      ? informaticscustomer_Id
      : null;
    const consortiamasterId =
      profileData1 && profileData1.consortiamaster_id
        ? profileData1.consortiamaster_id
        : null;
    const sessionKey = profileData.session_key ? profileData.session_key : null;
    const isONOS =
      is_ONOSCustomer && selectedFilters.collectionValue === "ONOSCollections"
        ? 1
        : 0;
    usagelogApi(
      userMasterId,
      informaticscustomerId,
      consortiamasterId,
      usageActionID,
      contextId,
      null,
      null,
      null,
      null,
      null,
      // profileData.ip_v4,
      user_ipv4_address,
      null,
      sessionKey,
      1,
      null,
      isONOS
    );
  };
  const handleOptionClick = (topic: string) => {
    console.log("topic", topic);
    if (topic === "Author" || topic === "Co-Author") {
      logBasicSearchUsageData(66);
    } else if (topic === "Author Country") {
      logBasicSearchUsageData(67);
    } else if (topic === "Publication Country") {
      logBasicSearchUsageData(68);
    } else if (topic === "Institution") {
      logBasicSearchUsageData(69);
    } else if (topic === "Publishers") {
      logBasicSearchUsageData(70);
    } else if (topic === "Subject") {
      logBasicSearchUsageData(71);
    } else if (topic === "Year of Publication") {
      logBasicSearchUsageData(72);
    } else if (topic === "Journal") {
      logBasicSearchUsageData(117);
    } else if (topic === "Researcher") {
      logBasicSearchUsageData(118);
    } else if (topic === "Research Guides") {
      logBasicSearchUsageData(119);
    } else if (topic === "Speaker") {
      logBasicSearchUsageData(120);
    }
    handleClose();
    dispatch(
      setModal({
        modalType: "ANALYTICS",
        modalProps: {
          show: true,
          title: topic,
          chartData: facets,
          isAuthorFinder: isAuthorFinder,
          CoAuthorList: CoAuthorList,
        },
      })
    );
  };

  const [finalList, setFinalList] = useState<any>([]);

  const handleArrayList = useCallback(() => {
    //author related facets
    const facetData_author = isAuthorFinder
      ? CoAuthorList
      : authorFacets?.authors_tk?.buckets?.length;
    const facetData_author_address =
      authorFacets?.author_address?.buckets?.length;
    const facetData_countryname =
      authorFacets?.author_country_name?.buckets?.length;
    const resercherFacet_data = authorFacets?.researcher_tk?.buckets?.length;
    const speakerrFacet_data = authorFacets?.speakers?.buckets?.length;
    const guideFacet_data = authorFacets?.guide_name_tk?.buckets?.length;

    //subject related facets
    const facetData_subject = subjectFacet?.subjects_name_l3?.buckets?.length;

    //Other facets
    const facetData_journal = otherFacets?.journal_name?.buckets?.length;
    const facetData_countryofPublication =
      otherFacets?.primary_publisher_country?.buckets?.length;
    const facetData_publishers = otherFacets?.publisher_name?.buckets?.length;
    const facetData_yop = otherFacets?.yearfrom?.buckets?.length;

    // const facetData_author = isAuthorFinder
    //   ? CoAuthorList
    //   : facets?.authors_tk?.buckets?.length;
    // const facetData_author_address = facets?.author_address?.buckets?.length;

    // const facetData_journal = facets?.journal_name?.buckets?.length;
    // const facetData_countryname = facets?.author_country_name?.buckets?.length;
    // const facetData_countryofPublication =
    //   facets?.primary_publisher_country?.buckets?.length;
    // const facetData_publishers = facets?.publisher_name?.buckets?.length;
    // const facetData_subject = facets?.subjects_name_l3?.buckets?.length;
    // const facetData_yop = facets?.yearfrom?.buckets?.length;
    // const resercherFacet_data = facets?.researcher_tk?.buckets?.length;
    // const speakerrFacet_data = facets?.speakers?.buckets?.length;
    // const guideFacet_data = facets?.guide_name_tk?.buckets?.length;

    const lengthArray: number[] = [
      facetData_author,
      facetData_journal,
      facetData_countryname,
      facetData_countryofPublication,
      facetData_author_address,
      facetData_publishers,
      facetData_subject,
      facetData_yop,
      resercherFacet_data,
      speakerrFacet_data,
      guideFacet_data,
    ];

    const tempArray = [
      isAuthorFinder ? "Co-Author" : "Author",
      ...analyticsDropDownData,
    ];
    const nameArr = lengthArray;

    type TestArr = {
      count: number;
      title: string;
    };
    const testArr: TestArr[] = nameArr?.map((x, i) => ({
      count: x,
      title: tempArray[i],
    }));

    const filterList = testArr.filter((val: any) => {
      return val.count !== 0;
    });

    setFinalList(filterList);
  }, [facets, isAuthorFinder, subjectFacet, otherFacets, authorFacets]);

  useEffect(() => {
    handleArrayList();
  }, [handleArrayList]);

  const CustomSignalIcon = styled("svg")({
    height: "22px",
    width: "24px",
    display: "block",
    marginRight: "-5px",
    marginBottom: "6px",
  });

  return (
    <div>
      <Button
        variant="outlined"
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        // startIcon={<SignalCellularAltIcon />}
        startIcon={
          // <Box
          //   component="img"
          //   src={piechart} // Replace with actual image path
          //   alt="Signal Icon"
          //   sx={{ width: 27.5, height: 27.5 }} // Adjust size as needed
          // />

          <Box
            component="img"
            src={piechart1} // Replace with actual image path
            alt="Signal Icon"
            sx={{ width: 27.5, height: 27.5 }} // Adjust size as needed
          />

          // <Box
          //   component="img"
          //   src={piechart2} // Replace with actual image path
          //   alt="Signal Icon"
          //   sx={{ width: 27.5, height: 27.5 }} // Adjust size as needed
          // />
        }
        sx={{
          fontSize: { xs: "0.5rem", sm: "1rem", md: "0.8rem" },
          color: Colors.black,
          // fontWeight: "bold",
          display: { xs: "none", sm: "flex", md: "flex", lg: "flex" },
        }}
        disabled={disable}
      >
        Analytics
      </Button>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        container={document.getElementById("") as HTMLElement}
      >
        {finalList?.map((data: any, index: number) => {
          return (
            <MenuItem
              key={index}
              sx={{ color: "#212121" }}
              disableRipple
              onClick={() => handleOptionClick(data.title)}
            >
              {data.title}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
};

export default AnalyticsDropdown;
